import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("cln.user")
    ? JSON.parse(localStorage.getItem("cln.user"))
    : null,
  dialogShow: "",
  payHtml: "",
  iyziData: [],
  barClose: false,
  sayfaAdi: "",
  webData: localStorage.getItem("cln.webData")
    ? JSON.parse(localStorage.getItem("cln.webData"))
    : [],
  isRotated: false,
  clnSepet: localStorage.getItem("clnSepet")
    ? JSON.parse(localStorage.getItem("clnSepet"))
    : [],
  adresState: "",
  coordinate: "",
  selectedMusteri: localStorage.getItem("cln.selectedMusteri")
    ? JSON.parse(localStorage.getItem("cln.selectedMusteri"))
    : {},
  reloadPage: false,
  reloadIslemPage: false,
  reloadBorcAlacakPage: false,
  editID: "",
};

const authSlice = createSlice({
  name: "clnAuth",
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
      localStorage.removeItem("cln.user");
      localStorage.removeItem("cln.webData");
      localStorage.removeItem("cln.selectedMusteri");
    },
    setCredentials: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("cln.user", JSON.stringify(action.payload));
      let remember = action.payload.remember;
      if (remember) {
        const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 1 day
        localStorage.setItem("expirationTime", expirationTime);
      }
    },
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("cln.user");
      localStorage.removeItem("cln.webData");
      localStorage.removeItem("cln.selectedMusteri");
    },
    dialogClose: (state, action) => {
      state.dialogShow = action.payload;
    },
    payTrPayPage: (state, action) => {
      state.payHtml = action.payload;
    },
    iyziPayData: (state, action) => {
      state.iyziData = action.payload;
    },
    setIsRotated: (state, action) => {
      state.isRotated = action.payload;
    },
    sideBarClose: (state, action) => {
      state.barClose = action.payload;
    },
    webDataSet: (state, action) => {
      state.webData = action.payload;
      localStorage.setItem("cln.webData", JSON.stringify(action.payload));
    },
    removeUser: (state) => {
      state.user = {};
    },
    setAdresChange: (state, action) => {
      state.adresState = action.payload;
    },
    setCoordinateChange: (state, action) => {
      state.coordinate = action.payload;
    },
    setSelectedMusteri: (state, action) => {
      state.selectedMusteri = action.payload;
      localStorage.setItem(
        "cln.selectedMusteri",
        JSON.stringify(action.payload)
      );
    },
    delSelectedMusteri: (state) => {
      state.selectedMusteri = {};
      localStorage.removeItem("cln.selectedMusteri");
    },
    setReloadPage: (state, action) => {
      state.reloadPage = action.payload;
    },
    setAllReloadPage: (state, action) => {
      state.reloadPage = action.payload;
      state.reloadIslemPage = action.payload;
      state.reloadBorcAlacakPage = action.payload;
    },
    setReloadIslemPage: (state, action) => {
      state.reloadIslemPage = action.payload;
    },
    setReloadBorcAlacakPage: (state, action) => {
      state.reloadBorcAlacakPage = action.payload;
    },
    setEditIslemID: (state, action) => {
      state.editID = action.payload;
    },
    sepetGuncelle: (state, action) => {
      const existingProductIndex = state.clnSepet.findIndex(
        (item) => item.id === action.payload.id
      );
      if (existingProductIndex >= 0) {
        state.clnSepet[existingProductIndex].quantity += 1;
      } else {
        state.clnSepet.push({ ...action.payload, quantity: 1 });
      }
      localStorage.setItem("clnSepet", JSON.stringify(state.clnSepet));
    },
    sepettenSil: (state, action) => {
      state.clnSepet = state.clnSepet.filter(
        (item) => item.id !== action.payload
      );
      localStorage.setItem("clnSepet", JSON.stringify(state.clnSepet));
    },
    miktarAzalt: (state, action) => {
      const existingProductIndex = state.clnSepet.findIndex(
        (item) => item.id === action.payload
      );
      if (existingProductIndex >= 0) {
        if (state.clnSepet[existingProductIndex].quantity > 1) {
          state.clnSepet[existingProductIndex].quantity -= 1;
        } else {
          state.clnSepet = state.clnSepet.filter(
            (item) => item.id !== action.payload
          );
        }
      }
      localStorage.setItem("clnSepet", JSON.stringify(state.clnSepet));
    },
    sepetBosalt: (state) => {
      state.clnSepet = [];
      localStorage.removeItem("clnSepet", JSON.stringify(state.clnSepet));
    },
  },
});

export const {
  reset,
  setCredentials,
  logout,
  dialogClose,
  payTrPayPage,
  iyziPayData,
  setIsRotated,
  sideBarClose,
  webDataSet,
  removeUser,
  setAdresChange,
  setCoordinateChange,
  setSelectedMusteri,
  delSelectedMusteri,
  setReloadPage,
  setAllReloadPage,
  setReloadIslemPage,
  setReloadBorcAlacakPage,
  setEditIslemID,
  sepetGuncelle,
  sepettenSil,
  miktarAzalt,
  sepetBosalt
} = authSlice.actions;

export default authSlice.reducer;
