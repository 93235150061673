import React, { useEffect, useState } from "react";
import OutletHeader from "./OutletHeader";
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  sepettenSil,
  miktarAzalt,
  sepetGuncelle,
  sepetBosalt,
} from "../slice/auth/authSlice";
import { FaCheck } from "react-icons/fa";
import { SERVERPICT_URL } from "../slice/constants";
import { useAddSepetMutation } from "../slice/redux/sabitSlice";
import Spinner from "./Spinner";
import { toast } from "react-toastify";

const SepetPage = () => {
  const dispatch = useDispatch();
  const { clnSepet } = useSelector((state) => state.clnAuth);
  const [addSepet, { data: addInfo, isLoading }] = useAddSepetMutation();
  const [genelToplam, setGenelToplam] = useState(0);
  const [musteriData, setMusteriData] = useState({
    adiSoyadi: "",
    telefon: "",
    adres: "",
  });
  const { adiSoyadi, telefon, adres } = musteriData;

  const onChange = (e) => {
    setMusteriData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRemoveFromCart = (id) => {
    dispatch(sepettenSil(id));
    calculateTotal(); // Toplamı güncelle
  };

  const handleDecreaseQuantity = (id) => {
    dispatch(miktarAzalt(id));
    calculateTotal(); // Toplamı güncelle
  };

  const handleIncreaseQuantity = (product) => {
    dispatch(sepetGuncelle(product));
    calculateTotal(); // Toplamı güncelle
  };

  const calculateTotal = () => {
    setGenelToplam(
      clnSepet.reduce((total, item) => total + item.fiyat * item.quantity, 0)
    );
  };

  const sepetOnay = async () => {
    let data = { clnSepet, genelToplam, musteriData };
    try {
      await addSepet(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      dispatch(sepetBosalt());
    }
  }, [addInfo, dispatch]);

  useEffect(() => {
    calculateTotal(); // Sepet değiştiğinde toplamı hesapla
  }, [clnSepet]);

  return (
    <>
      <TopBar />
      <Header />
      <OutletHeader baslik={"Alışveriş Sepetiniz"} />
      {isLoading && <Spinner />}
      <div className="container d-flex flex-row gap-1">
        <div className="container mt-5">
          <h2>Alışveriş Sepetim</h2>
          {clnSepet.length === 0 ? (
            <p>Sepetinizde ürün bulunmamaktadır.</p>
          ) : (
            <>
              <table className="table sepet-table">
                <thead>
                  <tr>
                    <th>Resim</th>
                    <th>Ürün</th>
                    <th>Fiyat</th>
                    <th>Miktar</th>
                    <th className="mobil_hidden">Toplam</th>
                    <th>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {clnSepet.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img
                          src={`${SERVERPICT_URL}${item.pictureUrl}`}
                          alt={item.aciklama}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      </td>
                      <td>{item.aciklama}</td>
                      <td>{item.fiyat} ₺</td>
                      <td>{item.quantity}</td>
                      <td className="mobil_hidden">{item.fiyat * item.quantity} ₺</td>
                      <td className="d-flex flex-row gap-2">
                        <button
                          className="btn btn-outline-secondary me-2"
                          onClick={() => handleDecreaseQuantity(item.id)}
                        >
                          -
                        </button>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => handleIncreaseQuantity(item)}
                        >
                          +
                        </button>
                        <button
                          className="btn btn-outline-danger me-2"
                          onClick={() => handleRemoveFromCart(item.id)}
                        >
                          Sil
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="row mt-4">
                <div className="col-md-8">
                  <div className="d-flex flex-row gap-1 mb-2">
                    <div className="col-8">
                      <input
                        type="text"
                        className="form-control"
                        name="adiSoyadi"
                        value={adiSoyadi}
                        onChange={onChange}
                        placeholder="Adınız Soyadınız"
                      />
                    </div>
                    <div className="col-4">
                      <input
                        type="tel"
                        className="form-control"
                        id="telefon"
                        name="telefon"
                        value={telefon}
                        onChange={onChange}
                        placeholder="Telefon"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="adres"
                    name="adres"
                    value={adres}
                    onChange={onChange}
                    placeholder="Adres"
                  />
                  <div className="mt-2">
                    <span>
                      <i>
                        Not: Siparişiniz telefonla onaylanacaktır. Lütfen doğru
                        telefon numarası giriniz..
                      </i>
                    </span>
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-column gap-2">
                  <h4>Genel Toplam: {genelToplam} ₺</h4>
                  <button
                    className="btn btn-outline-success w-100"
                    disabled={!adiSoyadi || !telefon || !adres}
                    onClick={sepetOnay}
                  >
                    <FaCheck /> Sepetinizi Onaylayın
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SepetPage;
