import React, { useEffect, useState } from "react";
import { FaBackspace, FaSyncAlt } from "react-icons/fa";
import {
  useGetPricesQuery,
  useUpdatePriceMutation,
} from "../../slice/redux/priceSlice";
import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";

const YikamaScreen = () => {
  const { data: plans, isLoading, refetch } = useGetPricesQuery();
  const [updatePrice, { data: upInfo, isLoading: upLoading }] =
    useUpdatePriceMutation();

  const [formData, setFormData] = useState({
    id: "",
    baslik: "",
    fiyat: "",
    detayBir: "",
    birFlag: false,
    detayIki: "",
    ikiFlag: false,
    detayUc: "",
    ucFlag: false,
    detayDort: "",
    dortFlag: false,
    detayBes: "",
    besFlag: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = async () => {
    try {
      await updatePrice(formData).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [upInfo, refetch]);

  const handleEdit = (plan) => {
    setFormData({
      id: plan.id,
      baslik: plan.baslik,
      fiyat: plan.fiyat,
      detayBir: plan.detayBir,
      birFlag: plan.birFlag === "1",
      detayIki: plan.detayIki,
      ikiFlag: plan.ikiFlag === "1",
      detayUc: plan.detayUc,
      ucFlag: plan.ucFlag === "1",
      detayDort: plan.detayDort,
      dortFlag: plan.dortFlag === "1",
      detayBes: plan.detayBes,
      besFlag: plan.besFlag === "1",
    });
  };

  const resetForm = () => {
    setFormData({
      id: "",
      baslik: "",
      fiyat: "",
      detayBir: "",
      birFlag: false,
      detayIki: "",
      ikiFlag: false,
      detayUc: "",
      ucFlag: false,
      detayDort: "",
      dortFlag: false,
      detayBes: "",
      besFlag: false,
    });
  };

  return (
    <section className="yikamainfo">
      {(isLoading || upLoading) && <Spinner />}
      <h3 className="mb-2 text-center">Yıkama Planları Yönetimi</h3>
      <hr className="my-1" />
      <form>
        <div className="mb-1 d-flex flex-row gap-1">
          <label className="form-label col-lg-1 col-md-2 col-3">Plan Adı</label>
          <div className="col-lg-6 col-md-6 col-8">
            <input
              type="text"
              className="form-control"
              name="baslik"
              value={formData.baslik}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mb-1 d-flex flex-row gap-1">
          <label className="form-label col-lg-1 col-md-2 col-3">Fiyat</label>
          <div className="col-lg-1 col-md-2 col-4">
            <input
              type="number"
              className="form-control"
              name="fiyat"
              value={formData.fiyat}
              onChange={handleInputChange}
              step="0.01"
              required
            />
          </div>
        </div>
        {["Bir", "Iki", "Uc", "Dort", "Bes"].map((detay, index) => (
          <div className="mb-1 d-flex flex-row gap-1" key={index}>
            <label className="form-label col-lg-1 col-md-2 col-3">{`Detay ${detay}`}</label>
            <div className="col-lg-6 col-md-6 col-4">
              <input
                type="text"
                className="form-control"
                name={`detay${detay}`}
                value={formData[`detay${detay}`]}
                onChange={handleInputChange}
                required
              />
            </div>
            <label className="form-label col-lg-1 col-md-2 col-2">
              {"  "}{" "}
              {formData[`${detay.toLowerCase()}Flag`] ? "Aktif" : "Pasif"}
            </label>
            <div className="col-lg-1 col-md-1 col-1">
              <input
                type="checkbox"
                className="form-check-input"
                name={`${detay.toLowerCase()}Flag`}
                checked={formData[`${detay.toLowerCase()}Flag`]}
                onChange={handleInputChange}
              />
            </div>
          </div>
        ))}
      </form>
      <div className="mb-1 d-flex flex-row gap-1">
        <span className="col-1 mobil_hidden"></span>
        <button
          type="button"
          className="btn btn-primary col-lg-2 col-md-2 col-5"
          disabled={formData && !formData.id}
          onClick={handleSubmit}
        >
          <FaSyncAlt /> Güncelle
        </button>
        <button
          type="submit"
          className="btn btn-secondary col-lg-2 col-md-2 col-5"
          onClick={() => resetForm()}
        >
          <FaBackspace /> Vazgeç
        </button>
      </div>
      <h3 className="mt-2 text-center">Mevcut Planlar</h3>
      <hr className="my-1" />
      <div className="responsive-table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style={{ width: "7%" }}>İşlemler</th>
              <th scope="col" style={{ width: "20%" }}>Plan Adı</th>
              <th scope="col" style={{ width: "5%" }}>Fiyat</th>
              <th scope="col" style={{ width: "13%" }}>Detay 1</th>
              <th scope="col" style={{ width: "13%" }}>Detay 2</th>
              <th scope="col" style={{ width: "13%" }}>Detay 3</th>
              <th scope="col" style={{ width: "13%" }}>Detay 4</th>
              <th scope="col" style={{ width: "13%" }}>Detay 5</th>
            </tr>
          </thead>
          {plans && plans.length > 0 && (
            <tbody>
              {plans.map((plan) => (
                <tr key={plan.id}>
                  <td data-label="İşlemler" className="d-flex flex-row gap-1 mobil_right">
                    <button
                      className="btn btn-warning me-2"
                      onClick={() => handleEdit(plan)}
                    >
                      Düzenle
                    </button>
                  </td>
                  <td data-label="Plan Adı">{plan.baslik}</td>
                  <td data-label="Fiyat">{plan.fiyat}</td>
                  <td data-label="Detay 1">
                    <span>{plan.detayBir}</span>
                    <input
                      type="checkbox"
                      checked={plan.birFlag === "1"}
                      readOnly
                    />
                  </td>
                  <td data-label="Detay 2">
                    <span>{plan.detayIki}</span>
                    <input
                      type="checkbox"
                      checked={plan.ikiFlag === "1"}
                      readOnly
                    />
                  </td>
                  <td data-label="Detay 3">
                    <span>{plan.detayUc}</span>
                    <input
                      type="checkbox"
                      checked={plan.ucFlag === "1"}
                      readOnly
                    />
                  </td>
                  <td data-label="Detay 4">
                    <span>{plan.detayDort}</span>
                    <input
                      type="checkbox"
                      checked={plan.dortFlag === "1"}
                      readOnly
                    />
                  </td>
                  <td data-label="Detay 5">
                    <span>{plan.detayBes}</span>
                    <input
                      type="checkbox"
                      checked={plan.besFlag === "1"}
                      readOnly
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </section>
  );
};

export default YikamaScreen;
