export const SERVER_URL = "https://aabimhaliyikama.com.tr:5000/api"
export const SERVERPICT_URL = "https://aabimhaliyikama.com.tr:5000/getresim"
// export const SERVER_URL = "http://localhost:5000/api"
// export const SERVERPICT_URL = "http://localhost:5000/getresim"

export const AUTH_URL = '/users';
export const UPLOAD_URL = '/upload';
export const PAYTR_URL = '/paytr';
export const DOVIZ_URL = '/doviz';
export const IYZICO_URL = '/iyzipay';

export const BLOG_URL = '/blog';
export const BLOGDETAIL_URL = '/blogdetail';
export const CAROUSEL_URL = '/carousel';
export const HABER_URL = '/haber';
export const HIZMET_URL = '/hizmet'
export const ISLEM_URL = '/islem';
export const MUSTERI_URL = '/musteri';
export const PRICE_URL = '/price';
export const SABIT_URL = '/sabit';
export const STOK_URL = '/stok';
export const TAHSILAT_URL = '/tahsilat';
export const SIPARIS_URL = "/siparis"
export const DUYURU_URL = "/duyuru"
export const TEMIZLIK_URL = "/temizlik"
export const ORDER_URL = "/order"
export const KAMPANYA_URL = "/kampanya"
export const SITEMAP_URL = "/sitemap.xml"
