import { MUSTERI_URL, UPLOAD_URL } from "../constants";
import apiSlice from "../apiSlice";

export const musteriApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMusteris: builder.query({
      query: () => ({
        url: `${MUSTERI_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
    addMusteri: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
    updateMusteri: builder.mutation({
      query: (data) => ({
        url: `${MUSTERI_URL}/${data.musteriID}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
    deleteMusteriById: builder.mutation({
      query: (musteriID) => ({
        url: `${MUSTERI_URL}/${musteriID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
    getMusteriById: builder.query({
      query: (musteriID) => ({
        url: `${MUSTERI_URL}/${musteriID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
    uploadImagesByMusteriId: builder.mutation({
      query: ({formData, musteriID}) => ({
        url: `${UPLOAD_URL}/${musteriID}`,
        method: "PUT",
        body: formData,
      }),
    }),
    getBakiyeByMusteriId: builder.query({
      query: (musteriID) => ({
        url: `${MUSTERI_URL}/bakiye/${musteriID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteri"],
    }),
  }),
});

export const {
  useGetMusterisQuery,
  useAddMusteriMutation,
  useUpdateMusteriMutation,
  useDeleteMusteriByIdMutation,
  useGetMusteriByIdQuery,
  useUploadImagesByMusteriIdMutation,
  useGetBakiyeByMusteriIdQuery,
} = musteriApiSlice;
