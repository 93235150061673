import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useGetAdminDashGraphQuery } from "../../slice/redux/sabitSlice";
import Spinner from "../../components/Spinner";

const GrafikliIslemTahsilat = () => {
  const { data: graphTable, isLoading } = useGetAdminDashGraphQuery();
  const [islemData, setIslemData] = useState([]);
  const [tahsilatData, setTahsilatData] = useState([]);

  useEffect(() => {
    if (graphTable) {
      setIslemData(
        graphTable && graphTable.islemData && JSON.parse(graphTable.islemData)
      );
      setTahsilatData(
        graphTable &&
          graphTable.tahsilatData &&
          JSON.parse(graphTable.tahsilatData)
      );
    }
  }, [graphTable]);

  return (
    <div>
      {isLoading && <Spinner />}
      <h2 className="text-center">Alacak ve Tahsilat Karşılaştırması</h2>
      <div className="blog-detail gap-1">
        {islemData && islemData.length > 0 && (
           <LineChart
            width={800}
            height={400}
            data={islemData} // İşlem verileri
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="tarih" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Alacak_Grafigi" stroke="#8884d8" />
          </LineChart>
        )}
        {tahsilatData && tahsilatData.length > 0 && (
          <LineChart
            width={800}
            height={400}
            data={tahsilatData} // Tahsilat verileri
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="tarih" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Tahsilat_Grafigi" stroke="#82ca9d" />
          </LineChart>
        )}
      </div>
    </div>
  );
};

export default GrafikliIslemTahsilat;
