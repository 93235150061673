import React, { useEffect } from "react";
import UserSideBar from "./UserSideBar";
import UserHeader from "./UserHeader";
import { Outlet, useLocation } from "react-router-dom";
import MusteriDashboard from "./MusteriDashboard";
import { useDispatch } from "react-redux";
import { sideBarClose } from "../../slice/auth/authSlice";

function UserPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDefaultComponentNeeded = location.pathname === "/app" && !location.state;
 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
      dispatch(sideBarClose(true));
      } else {
      dispatch(sideBarClose(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
      <div className="webbody">
        <UserSideBar />
        <div className="content">
          <main>
            <UserHeader />
            {isDefaultComponentNeeded ? <MusteriDashboard /> : <Outlet />}
          </main>
        </div> 
      </div>
  );
}

export default UserPage;
