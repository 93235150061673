import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import Header from "./Header";
import About from "./About";
import Services from "./Services";
import Facts from "./Facts";
import Prices from "./Prices";
import Location from "./Location";
import Team from "./Team";
import Blog from "./Blog";
import Footer from "./Footer";
import Carouseel from "./Carousel";
import ProductPage from "./ProductPage";
import { useSelector } from "react-redux";
import WhatsappButton from "./WhatsappButton";
import { useGetDuyuruVarmiQuery } from "../slice/redux/sabitSlice";
import Spinner from "./Spinner";
import { Dialog } from "primereact/dialog";
import { SERVERPICT_URL } from "../slice/constants";
import KampanyaPage from "./KampanyaPage";
import OrderPage from "./OrderPage";
import Meta from "./Meta";

function Main() {
  const { webData } = useSelector((state) => state.clnAuth);
  const { data: popupTable, isLoading } = useGetDuyuruVarmiQuery();
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(null)

  useEffect(() => {
    if(!show && popupTable && popupTable.duyuruID) {
      setVisible(true)
      setShow(false)
    }
  },[show, popupTable])

  useEffect(() => {
    let sabitBilgi = webData && JSON.parse(webData.sabit);
    document.title = sabitBilgi && sabitBilgi.firmaAdi;
  }, [webData]);

  useEffect(() => {
    const loader = () => {
      setTimeout(() => {
        const loaderElement = document.getElementById("loader");
        if (loaderElement) {
          loaderElement.classList.remove("show");
        }
      }, 1000);
    };
    loader();
  }, []);

  return (
    <div className="App">
      {isLoading && <Spinner />}
      <TopBar />
      <Header />
      <Meta />
      <Carouseel />
      <KampanyaPage />
      <About />
      <Services />
      <Facts />
      <ProductPage />
      <Team />
      <Prices />
      {/* <Testimonial /> */}
      <Blog />
      <OrderPage />
      <Location />
      <Footer />
      <WhatsappButton />
      <div id="loader" className="show">
        <div className="loader"></div>
      </div>
      {/* Burada Eğer duyuru var ise <Dialog /> içinde gösterilecek. */}
      {popupTable && popupTable.duyuruID ? (
        <Dialog
          header="Duyuru Panosu"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          <p className="m-0">
          <div dangerouslySetInnerHTML={{ __html: popupTable.htmlMetin }} />
          <img
            src={`${SERVERPICT_URL}${popupTable.resimUrl}`}
            alt="Duyuru Resmi"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          </p>
        </Dialog>
      ) : null}
    </div>
  );
}

export default Main;
