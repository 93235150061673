import React, { useState, useEffect, useCallback } from "react";
import {
  useAddBlogMutation,
  useDeleteBlogByIdMutation,
  useGetAllBlogsQuery,
  useUpdateBlogByIdMutation,
  useUploadImagesByBlogIdMutation,
} from "../../slice/redux/blogSlice";
import Spinner from "../../components/Spinner";
import { truncateWord } from "../Utility";
import { Paginator } from "primereact/paginator";
import { FaBackspace, FaSave, FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import { primeReactStyle } from "../PrimeReactUtil";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { SERVERPICT_URL } from "../../slice/constants";

const BlogScreen = () => {
  const { data: blogTable, isLoading, refetch } = useGetAllBlogsQuery();
  const [deleteBlogById, { data: delInfo, isLoading: delLoading }] =
    useDeleteBlogByIdMutation();
  const [addBlog, { data: addInfo, isLoading: addLoading }] =
    useAddBlogMutation();
  const [updateBlogById, { data: upInfo, isLoading: upLoading }] =
    useUpdateBlogByIdMutation();
  const [uploadImagesByBlogId, { data: upPicInfo, isLoading: upPicLoading }] =
    useUploadImagesByBlogIdMutation();

  const [silVisible, setSilVisible] = useState(false);
  const [silID, setSilID] = useState("");

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);

  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const folder = "blog";

  const [formData, setFormData] = useState({
    blogID: "",
    tarih: "",
    blogBaslik: "",
    aciklama: "",
    email: "",
    pictureUrl: "",
  });

  const { blogID, tarih, blogBaslik, aciklama, email, pictureUrl } = formData;

  useEffect(() => {
    if (addLoading || upLoading || upPicLoading || delLoading) {
      toast.info("Processing...", { autoClose: 2000 });
    }
  }, [addLoading, upLoading, upPicLoading, delLoading]);

  useEffect(() => {
    if (addInfo?.message) {
      toast.success(addInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [addInfo, refetch]);

  useEffect(() => {
    if (upInfo?.message) {
      toast.success(upInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [upInfo, refetch]);

  useEffect(() => {
    if (upPicInfo?.message) {
      toast.success(upPicInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [upPicInfo, refetch]);

  useEffect(() => {
    if (delInfo?.message) {
      toast.success(delInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [delInfo, refetch]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setSelectedFile(file);
    }
  }, []);

  const handleSave = async () => {
    const form = new FormData();
    form.append("tarih", tarih);
    form.append("blogBaslik", blogBaslik);
    form.append("aciklama", aciklama);
    form.append("email", email);
    form.append("folder", folder);
    if (selectedFile) {
      form.append("image", selectedFile);
    }
    try {
      await addBlog(form).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    clearForm();
  };

  const handleUpdateWithPict = async () => {
    const form = new FormData();
    form.append("blogID", blogID);
    form.append("tarih", tarih);
    form.append("blogBaslik", blogBaslik);
    form.append("aciklama", aciklama);
    form.append("email", email);
    form.append("folder", folder);
    if (selectedFile) {
      form.append("image", selectedFile);
    }
    try {
      await uploadImagesByBlogId(form).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    clearForm();
  };

  const handleUpdate = async (e) => {
    let data = { blogID, tarih, blogBaslik, aciklama, email };
    try {
      await updateBlogById(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    clearForm();
  };

  const handleEdit = (blog) => {
    setFormData(blog);
    setImagePreview(blog.image);
  };

  const handleDelete = async () => {
    try {
      await deleteBlogById(silID).unwrap();
      setSilVisible(false);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    clearForm();
  };

  const clearForm = useCallback(() => {
    setFormData({
      id: null,
      tarih: "",
      blogBaslik: "",
      aciklama: "",
      email: "",
    });
    setImagePreview(null);
    setSelectedFile(null);
  }, []);

  const handlePageChange = useCallback((event) => {
    setFirst(event.first);
    setRows(event.rows);
  }, []);

  const silSorModul = (blogID) => {
    setSilID(blogID);
    setSilVisible(true);
  };

  return (
    <section className="bloginfo">
      {(isLoading || delLoading || addLoading || upLoading || upPicLoading) && (
        <Spinner />
      )}
      <h3 className="mb-2 text-center">Blog Yönetimi</h3>
      <hr className="my-2" />
      <div>
        <div className="mb-1 blog-detail gap-1">
          <label className="form-label col-lg-1 col-md-1 col-11">Blog Tarihi</label>
          <div className="col-lg-2 col-md-1 col-11">
            <input
              type="date"
              className="form-control"
              name="tarih"
              value={tarih}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mb-1 blog-detail gap-1">
          <label className="form-label col-md-1 col-11">Blog Başlık</label>
          <div className="col-lg-6 col-md-6 col-11">
            <input
              type="text"
              className="form-control"
              name="blogBaslik"
              value={blogBaslik}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mb-1 blog-detail gap-1">
          <label className="form-label col-md-1 col-11">Açıklama</label>
          <div className="blog-detail gap-2 col-11">
            <div className="col-lg-9 col-md-9 col-12">
              <textarea
                className="form-control"
                name="aciklama"
                rows={6}
                value={aciklama}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-lg-3 col-md-3 col-12 d-flex flex-column">
              <div className="col-lg-10 col-md-10 col-12">
                <input
                  className="form-control"
                  type="file"
                  onChange={handleImageChange}
                  placeholder="Resim Seçiniz"
                />
              </div>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Blog Görseli"
                  style={{
                    marginTop: "5px",
                    maxHeight: "110px",
                    maxWidth: "120px",
                    height: "auto",
                    width: "auto",
                  }}
                />
              ) : (
                <img
                  src={`${SERVERPICT_URL}${pictureUrl}`}
                  alt="Blog Görseli"
                  style={{
                    marginTop: "5px",
                    maxHeight: "110px",
                    maxWidth: "120px",
                    height: "auto",
                    width: "auto",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mb-1 blog-detail gap-1">
          <label className="form-label col-md-1 col-11">Email</label>
          <div className="col-lg-5 col-md-5 col-11">
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={handleInputChange}
              required
              disabled={blogID}
            />
          </div>
        </div>
        <div className="mb-1 blog-detail gap-1">
          <span className="col-1"></span>
          <div className="blog-detail gap-1 col-11">
            {blogID ? (
              <>
                {selectedFile ? (
                  <button
                    className="btn btn-primary col-6 w-100"
                    onClick={handleUpdateWithPict}
                  >
                    <FaSyncAlt />
                     Güncelle
                  </button>
                ) : (
                  <button
                    className="btn btn-primary col-6 w-100"
                    onClick={handleUpdate}
                  >
                    <FaSyncAlt />
                     Resimsiz Güncelle
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn btn-primary col-6 w-100"
                onClick={handleSave}
              >
                <FaSave />
                 Ekle
              </button>
            )}
            <button
              type="button"
              className="btn btn-outline-secondary col-6 w-100"
              onClick={clearForm}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </div>
      </div>

      <h3 className="mt-2 text-center">Mevcut Bloglar</h3>
      <hr className="my-2" />
      <div className="responsive-table">
      <table>
        <thead>
          <tr>
            <th scope="col" style={{ width: "12%" }}>İşlemler</th>
            <th scope="col" style={{ width: "8%" }}>Tarih</th>
            <th scope="col" style={{ width: "17%" }}>Başlık</th>
            <th scope="col" style={{ width: "48%" }}>Açıklama</th>
            <th scope="col" style={{ width: "15%" }}>Email</th>
          </tr>
        </thead>
        {blogTable && blogTable.length > 0 && (
          <tbody>
            {blogTable.slice(first, first + rows).map((blog) => (
              <tr key={blog.blogID}>
                <td data-label="İşlemler" className="d-flex flex-row mobil_right">
                  <button
                    className="btn btn-warning me-2"
                    onClick={() => handleEdit(blog)}
                  >
                    Düzenle
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => silSorModul(blog.blogID)}
                  >
                    Sil
                  </button>
                </td>
                <td data-label="Tarih">{blog.tarih}</td>
                <td data-label="Başlık">{truncateWord(blog.blogBaslik, 24)}</td>
                <td data-label="Açıklama">{truncateWord(blog.aciklama, 90)}</td>
                <td data-label="Email">{blog.email}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={blogTable && blogTable.length}
        onPageChange={handlePageChange}
      />
      <Dialog
        header="Kayıt Silme Onayı"
        visible={silVisible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setSilVisible(false)}
       >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu <strong>Blog Kaydı</strong>{" "}
            Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDelete}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setSilVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </section>
  );
};

export default BlogScreen;
