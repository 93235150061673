import React, { useState, useEffect, useCallback } from "react";
import {
  useGetTemizliksQuery,
  useDeleteTemizlikByIdMutation,
} from "../../slice/redux/temizlikSlice";
import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";
import { FaTrashAlt, FaBackspace } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import { truncateWord } from "../Utility";

const RezerveTemizlikPage = () => {
  const { data: temizlikTable, isLoading, refetch } = useGetTemizliksQuery();
  const [deleteTemizlikById, { data: delInfo, isLoading: delLoading }] = useDeleteTemizlikByIdMutation();
  const [silVisible, setSilVisible] = useState(false);
  const [silID, setSilID] = useState("");

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(12);

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [delInfo, refetch]);

  const resetForm = () => {
    setSilID("");
  };

  const handlePageChange = useCallback((event) => {
    setFirst(event.first);
    setRows(event.rows);
  }, []);

  const silSorModul = (id) => {
    setSilID(id);
    setSilVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteTemizlikById(silID).unwrap();
      setSilVisible(false);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    resetForm();
  };

  return (
    <section className="temizlik-info">
      {(isLoading || delLoading) && <Spinner />}
      <h3 className="text-center">Temizlik Rezervasyonları</h3>
      <hr className="my-2" />
      <div className="responsive-table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ width: "5%" }}>ID</th>
              <th scope="col" style={{ width: "5%" }}>İşlemler</th>
              <th scope="col" style={{ width: "15%" }}>Ad Soyad</th>
              <th scope="col" style={{ width: "10%" }}>Telefon</th>
              <th scope="col" style={{ width: "25%" }}>Adres</th>
              <th scope="col" style={{ width: "30%" }}>Temizlik Türü ve Notu</th>
            </tr>
          </thead>
          {temizlikTable && temizlikTable.length > 0 && (
            <tbody>
              {temizlikTable.slice(first, first + rows).map((temizlik) => (
                <tr key={temizlik.temizlikID}>
                  <td data-label="ID">{temizlik.temizlikID}</td>
                  <td data-label="İşlemler" className="d-flex flex-row gap-1 mobil_right">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => silSorModul(temizlik.temizlikID)}
                    >
                      <FaTrashAlt /> Sil
                    </button>
                  </td>
                  <td data-label="Ad">{temizlik.musteriAdi} {" "}{temizlik.musteriSoyad}</td>
                  <td data-label="Soyad">{temizlik.musteriTel}</td>
                  <td data-label="Telefon">{temizlik.musteriAdres}</td>
                  <td onClick={() => toast("Müşteri Notu: " + temizlik.musteriNotu)} data-label="Adres">{temizlik.baslik} / {Number(temizlik.fiyat).toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY"
                  })} / {truncateWord(temizlik.musteriNotu, 30)}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={temizlikTable && temizlikTable.length}
        onPageChange={handlePageChange}
      />
      <Dialog
        header="Kayıt Silme Onayı"
        visible={silVisible}
        style={{ width: "50vw" }}
        onHide={() => setSilVisible(false)}
      >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> numaralı müşteri kaydı silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDelete}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setSilVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </section>
  );
};

export default RezerveTemizlikPage;
