import { TAHSILAT_URL, UPLOAD_URL } from "../constants";
import apiSlice from "../apiSlice";

export const tahsilatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTahsilatFilterById: builder.query({
      query: (musteriID) => ({
        url: `${TAHSILAT_URL}/filter/${musteriID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbltahsilat"],
    }),
    addTahsilat: builder.mutation({
      query: (data) => ({
        url: TAHSILAT_URL,
        method: "POST",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbltahsilat"],
    }),
    updateTahsilat: builder.mutation({
      query: (data) => ({
        url: `${TAHSILAT_URL}/${data.tahsilatID}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbltahsilat"],
    }),
    deleteTahsilatById: builder.mutation({
      query: (tahsilatID) => ({
        url: `${TAHSILAT_URL}/${tahsilatID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbltahsilat"],
    }),
    getTahsilatById: builder.query({
      query: (tahsilatID) => ({
        url: `${TAHSILAT_URL}/${tahsilatID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tbltahsilat"],
    }),
    uploadImagesByTahsilatId: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetTahsilatFilterByIdQuery,
  useAddTahsilatMutation,
  useUpdateTahsilatMutation,
  useDeleteTahsilatByIdMutation,
  useGetTahsilatByIdQuery,
  useUploadImagesByTahsilatIdMutation,
} = tahsilatApiSlice;
