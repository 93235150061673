import React from "react";
import OutletHeader from "./OutletHeader";
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import singlePict from "../assets/yikamaperiyot.jpg";

const YikamaPeriyot = () => {
  return (
    <>
      <TopBar />
      <Header />
      <OutletHeader baslik={"Halılar Hangi Aralıklarla Yıkanmalıdır?"} />
      <div className="container d-flex flex-row gap-1">
        <div className="col-8">
          <h4 className="mt-1">
            Halıların yıkanması kullanım yoğunluğuna göre 3-6 ayda bir
            yıkatılmalıdır. Günlük olarak halı bakımı yapılamıyor, kuvvetli bir
            makina ile süpürülemiyor ise daha kısa aralıklarla yıkatılması önerilir.
            AABİM Oto-Halı Yıkama olarak yılda en az 2 kez yıkatılmasını tavsiye
            ederiz.
          </h4>
          <h4 className="mt-4">
            Sık kullanılan halılar 3-6 ayda, az kullanılan halılar 9-12 ayda bir
            sağlıklı, temizlik, görünüm ve hijyen için uygun deterjanlarla
            mutlaka yıkanmalıdır. Evcil hayvan yaşayan evlerde mutlaka yılda 2
            kere halılar yıkanmalıdır. Temizleme bittikten sonra halılarınızın
            iyice kurumasını beklemelisiniz. Bu süre, evde yaşayan kişi sayısına
            ve ev ortamına göre değişiklik gösterebilir.
          </h4>
          <h4 className="mt-4">
            Halılarınız işletmemizde 4 mevsim yıkanmakta ve kısa zamanda teslim
            edilmektedir. Sonuç olarak kendimize sorduğumuz “kışın halı yıkanır
            mı?” sorusuna cevabımız “elbet yıkanır” olacaktır.
          </h4>
        </div>
        <div className="col-4">
          <img
            src={singlePict}
            alt={singlePict}
            style={{
              height: "420px",
              borderRadius: "10px",
              boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default YikamaPeriyot;
