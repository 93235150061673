import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "./Spinner";
import { SERVER_URL, SITEMAP_URL } from "../slice/constants";
import { useNavigate } from "react-router-dom";

const parseXMLToHTML = (xml) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "application/xml");
  const urls = Array.from(xmlDoc.getElementsByTagName("url"));
  return urls.map((url, index) => {
    const loc = url.getElementsByTagName("loc")[0].textContent;
    return (
      <li key={index}>
        <a href={loc}>{loc}</a>
      </li>
    );
  });
};

const SiteMapPage = () => {
  const navigate = useNavigate();
  const [siteMapXML, setSiteMapXML] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [parsedHTML, setParsedHTML] = useState(null);

  useEffect(() => {
    const fetchSiteMap = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}${SITEMAP_URL}`);
        setSiteMapXML(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchSiteMap();
  }, []);

  useEffect(() => {
    if (siteMapXML) {
      const htmlContent = parseXMLToHTML(siteMapXML);
      setParsedHTML(htmlContent);
    }
  }, [siteMapXML]);

  return (
    <div>
      <button
        className="btn btn-secondary mx-2 my-2 w-25"
        onClick={() => navigate("/")}
        style={{ padding: "5px" }}
      >
        Geri
      </button>
      {isLoading && <Spinner />}
      <h1>Site Map</h1>
      <ul>{parsedHTML}</ul>
      {isError && <p className="text-danger">Error loading site map.</p>}
    </div>
  );
};

export default SiteMapPage;
