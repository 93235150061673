import React, { useEffect, useState } from "react";
import {
  useGetCarouselsQuery,
  useUpdateCarouselMutation,
} from "../../slice/redux/carouselSlice"; 
import Spinner from "../../components/Spinner";
import { FaSyncAlt, FaTimesCircle } from "react-icons/fa"
import { toast } from "react-toastify";

const SliderScreen = () => {
  const { data: carouselTable, isLoading, refetch } = useGetCarouselsQuery();
  const [formData, setFormData] = useState({
    carouselID: null,
    baslik: "",
    aciklama: "",
  });
  const { carouselID, baslik, aciklama } = formData;
  const [updateCarousel, {data: upInfo, isLoading: upLoading}] = useUpdateCarouselMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await updateCarousel(formData).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
      setFormData({ carouselID: null, baslik: "", aciklama: "" });
    }
  }, [upInfo, refetch]);

  const handleEdit = (carousel) => {
    setFormData(carousel);
  };

  return (
    <section className="slider">
      {(isLoading || upLoading) && <Spinner />}
      <h2 className="text-center mb-4">Slayt Yönetimi</h2>
      <hr className="my-2"/>
      <div className="">
        <div className="mb-1 d-flex flex-row gap-1">
          <label className="form-label col-lg-1 col-md-1 col-3">Başlık</label>
          <div className="col-lg-6 col-md-6 col-8">
            <input
              type="text"
              className="form-control"
              name="baslik"
              value={baslik}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mb-1 d-flex flex-row gap-1">
          <label className="form-label col-lg-1 col-md-1 col-3">Açıklama</label>
          <div className="col-lg-6 col-md-6 col-8">
          <input
            className="form-control"
            name="aciklama"
            value={aciklama}
            onChange={handleInputChange}
            required
          />
        </div>
        </div>
        <div className="d-flex flex-row gap-1">
          <span className="col-1 mobil_hidden"></span>
          <div className="col-lg-1 col-md-1 col-5">
            <button className="btn btn-primary me-2 w-100" disabled={!carouselID} onClick={handleSubmit}>
             <FaSyncAlt /> Güncelle
            </button>
          </div>
          <div className="col-lg-1 col-md-1 col-5">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={() =>
                setFormData({
                  carouselID: null,
                  baslik: "",
                  aciklama: "",
                  resimUrl: "",
                })
              }
            >
             <FaTimesCircle /> Temizle
            </button>
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <h3 className="mb-3 text-center">Mevcut Slaytlar</h3>
      <div className="responsive-table">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col" style={{ width: "10%" }}>İşlem</th>
              <th scope="col" style={{ width: "30%" }}>Başlık</th>
              <th scope="col" style={{ width: "60%" }}>Açıklama</th>
            </tr>
          </thead>
          {carouselTable && carouselTable.length > 0 && (
            <tbody>
              {carouselTable.map((carousel) => (
                <tr key={carousel.carouselID}>
                  <td data-label="İşlem" className="d-flex flex-row gap-1 mobil_right">
                    <button
                      className="btn btn-warning me-2"
                      onClick={() => handleEdit(carousel)}
                    >
                      Düzenle
                    </button>
                  </td>
                  <td data-label="Başlık">{carousel.baslik}</td>
                  <td data-label="Açıklama">{carousel.aciklama}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </section>
  );
};

export default SliderScreen;
