import { KAMPANYA_URL } from "../constants";
import apiSlice from "../apiSlice";

export const kampanyaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKampanyas: builder.query({
      query: () => ({
        url: `${KAMPANYA_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblkampanya"],
    }),
    getKampanyaById: builder.query({
        query: (kampanyaID) => ({
          url: `${KAMPANYA_URL}/${kampanyaID}`,
        }),
        keepUnusedDataFor: 5,
        providesTags: ["tblkampanya"],
      }),
    addKampanya: builder.mutation({
      query: (formData) => ({
        url: `${KAMPANYA_URL}`,
        method: 'POST',
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblkampanya"],
    }),
    updateKampanyaById: builder.mutation({
      query: (data) => ({
        url: `${KAMPANYA_URL}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblkampanya"],
    }),
    deleteKampanyaById: builder.mutation({
      query: (kampanyaID) => ({
        url: `${KAMPANYA_URL}/${kampanyaID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblkampanya"],
    }),
  }),
});

export const {
  useGetKampanyasQuery,
  useAddKampanyaMutation,
  useUpdateKampanyaByIdMutation,
  useDeleteKampanyaByIdMutation,
  useGetKampanyaByIdQuery,
} = kampanyaApiSlice;

