import React from 'react'
import carWashOutLogo from "../assets/car-wash.png"
import ozoneLogo from "../assets/ozone.png"
import elektrikliSupurge from "../assets/power-washing.png"
import koltukSeti from "../assets/sofa.png"
import petekTemizlik from "../assets/radiator.png"
import kombi from "../assets/gas.png"
import detayTemizlik from "../assets/upholstery.png"
import buharliTemizlik from "../assets/steam-iron.png"

const Services = () => {
  return (
    <div className="service" id='service'>
            <div className="container">
                <div className="section-header text-center">
                    <p>NELER YAPIYORUZ?</p>
                    <h2>Yıkama ve Bakım Hizmetleri</h2>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <img src={carWashOutLogo} alt="" />
                            <h3>İç-Dış Yıkama</h3>
                            <p>Araç dış yıkama hizmetimizde, profesyonel ekipmanlarla yüzeydeki kir ve lekeleri temizleyip, döşeme, koltuk ve konsolu hijyenik hale getirerek aracınızı parlak bir görünüme kavuşturuyoruz.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                        <img src={ozoneLogo} alt="" />
                            <h3>Ozon Cihazıyla Temizlik</h3>
                            <p>Ozon cihazı ile yapılan temizlikle, aracınızın içindeki veya yaşadığınız diğer ortamlardaki bakteri ve kötü kokuları gidererek, taze ve hijyenik bir ortam sağlıyoruz.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                        <img src={elektrikliSupurge} alt="" />
                            <h3>Modern Temizlik Ekipmanı</h3>
                            <p>Halılarınızı derinlemesine ve hassas bir şekilde temizliyoruz. Kir ve lekeleri etkili bir şekilde çıkararak halılarınızı hijyenik ve parlak bir hale getirir. Güvenilir ve profesyonel temizlik hizmetimizle memnuniyetiniz garantidir.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                        <img src={koltukSeti} alt="" />
                            <h3>Koltuk Yıkama</h3>
                            <p>Kanepe ve koltuklarınızı, özel temizlik çözümlerimizle derinlemesine temizliyoruz. Profesyonel ekiplerimiz, döşemelerdeki kir ve lekeleri özenle çıkararak mobilyalarınıza tazelik ve hijyen kazandırır. Sağlıklı ve temiz yaşam alanları için bize güvenin.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                        <img src={buharliTemizlik} alt="" />
                            <h3>Buharlı Detaylı Temizlik</h3>
                            <p>Yüzeylerdeki inatçı kir ve mikropları yüksek sıcaklıkta buharla etkili şekilde temizliyoruz. Kimyasal kullanmadan hijyen sağlayarak, sağlığınızı korur ve çevre dostu bir temizlik sunarız. Güvenli ve derinlemesine temizlik için bizi tercih edin.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                        <img src={kombi} alt="" />
                            <h3>Kombi Bakımı</h3>
                            <p>Kombi bakımı hizmetimizle, cihazınızın verimli ve güvenli çalışmasını sağlıyoruz. Uzman ekibimiz, kombinizin tüm parçalarını kontrol eder, temizler ve gerekli ayarları yapar. Düzenli bakım, enerji tasarrufu sağlar ve arızaların önüne geçer.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                        <img src={petekTemizlik} alt="" />
                            <h3>Petek (Radyatör) Temizliği</h3>
                            <p>Kombi radyatör petekleri, evinizi ısıtmak için önemli bir rol oynar. Uzman ekiplerimiz, peteklerin temizliğini yaparak ısı verimliliğini artırır ve daha sağlıklı bir ısınma sağlar. Böylece konforlu ve verimli bir ısınma deneyimi sunarız.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                        <img src={detayTemizlik} alt="" />
                            <h3>Detaylı Temizlik</h3>
                            <p>Genel detaylı temizlik hizmetimizde, yaşam alanınızın her köşesini titizlikle temizleyerek hijyenik bir yaşam alanı sunuyoruz. Profesyonel ekibimiz, en zorlu lekeleri ve kirleri etkili bir şekilde temizler, size ferah ve temiz bir ev ortamı sağlar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Services