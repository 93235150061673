import { CAROUSEL_URL } from "../constants";
import apiSlice from "../apiSlice";

export const carouselApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarousels: builder.query({
      query: () => ({
        url: `${CAROUSEL_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblcarousel"],
    }),
    updateCarousel: builder.mutation({
      query: (data) => ({
        url: `${CAROUSEL_URL}/${data.carouselID}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblcarousel"],
    }),
  }),
});

export const {
  useGetCarouselsQuery,
  useUpdateCarouselMutation,
} = carouselApiSlice;
