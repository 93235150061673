import React from "react";
import { useGetKampanyasQuery } from "../slice/redux/kampanyaSlice";
import Spinner from "./Spinner";
import { SERVERPICT_URL } from "../slice/constants";

const KampanyaPage = () => {
  const {
    data: kampanyaTable,
    isLoading,
    isError,
    message,
  } = useGetKampanyasQuery();

  const currentDate = new Date();

  // Filtrelenmiş kampanyalar
  const filteredKampanyas = kampanyaTable?.filter(kampanya => {
    const startDate = new Date(kampanya.startDate);
    const endDate = new Date(kampanya.endDate);
    return currentDate >= startDate && currentDate <= endDate;
  }) || [];

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && !isError && filteredKampanyas.length > 0 && (
        <div className="about kampanyapage">
          <div className="container mt-5">
            <h1 className="text-center">Kampanyalarımız</h1>
            <hr className="my-2" />
            {filteredKampanyas.map((kampanya) => (
              <div className="row justify-content-center" key={kampanya.kampanyaID}>
                <div className="col-md-10 text-center">
                  <h3 className="display-4 mb-4">{kampanya.title}</h3>
                  <img
                    src={`${SERVERPICT_URL}${kampanya.resimUrl}`}
                    alt="Kampanya Resmi"
                    className="img-fluid mb-4"
                  />
                  <p className="lead">{kampanya.textBilgi}</p>
                </div>
                <hr className="my-2" />
              </div>
            ))}
          </div>
        </div>
      )}
      {isError && <p className="text-danger">{message}</p>}
    </>
  );
};

export default KampanyaPage;
