import React from "react";
import aboutPict from "../assets/about.jpg";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src={aboutPict} alt="aabim oto-halı yıkama osmaniye kadirli" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-header text-left">
              <p>Nasıl Yapıyoruz ?</p>
              <h2>Halı Yıkama Detayları</h2>
              <h3>Değerli Müşterilerimiz;</h3>
            </div>
            <div className="about-content">
              <p style={{ textAlign: "justify"}}>
                Firmamız, halılarınızın en iyi şekilde temizlenmesi için özenle
                çalışmaktadır. Halı yıkama sürecimiz, her biri deneyimli ve
                profesyonel ekibimiz tarafından titizlikle gerçekleştirilir. İlk
                aşamada halılarınız türüne göre ayrılır ve toz alma işlemi
                uygulanır. Ardından, özel formüllü temizlik şampuanlarımızla
                derinlemesine yıkama yapılır. Yıkama işlemi sonrasında, güçlü
                durulama makinelerimiz ile tüm deterjan ve kir kalıntıları
                halılarınızdan arındırılır. Halılarınız, nem kontrolü sağlanan
                kurutma odalarımızda kurutulur ve son olarak kalite kontrol
                aşamasından geçirilir. Tüm bu aşamalardan sonra halılarınız,
                parlak ve hijyenik bir şekilde size teslim edilir. Müşteri
                memnuniyeti odaklı hizmet anlayışımızla, halılarınızı güvenle
                bize emanet edebilirsiniz.
              </p>
              <ul>
                <li>
                  <i className="far fa-check-circle"></i>Türe Göre Ayrıştırma
                </li>
                <li>
                  <i className="far fa-check-circle"></i>Toz Alma
                </li>
                <li>
                  <i className="far fa-check-circle"></i>Detaylı Temizlik ve Yıkama
                </li>
                <li>
                  <i className="far fa-check-circle"></i>Kurutma ve Paketleme
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
