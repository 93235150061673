import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaArrowUp, FaWhatsapp } from "react-icons/fa";

const formatPhoneNumber = (phone) => {
  let formatted = phone.replace(/\D/g, "");
  if (formatted.startsWith("0")) {
    formatted = formatted.substring(1);
  }
  if (!formatted.startsWith("90")) {
    formatted = "90" + formatted;
  }
  return formatted;
};

const WhatsAppButton = () => {
  const { webData } = useSelector((state) => state.clnAuth);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 80) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let footerBilgi = webData && JSON.parse(webData.sabit);
  const phoneNumber = footerBilgi && footerBilgi.ceptel;
  const whatsappLink = `https://wa.me/${formatPhoneNumber(phoneNumber)}`;

  const buttonStyle = {
    position: "fixed",
    bottom: "20px",
    right: screenWidth < 756 ? "55px" : "20px",
    backgroundColor: "#25D366",
    color: "white",
    padding: "10px 10px",
    border: "none",
    borderRadius: "50%",
    boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
    cursor: "pointer",
    zIndex: "1000",
    opacity: "0.8"
  };

  const arrowButtonStyle = {
    position: "fixed",
    bottom: "20px",
    right: screenWidth < 756 ? "100px" : "70px", // Geri dönme butonu için uygun konum ayarı
    backgroundColor: "red",
    color: "white",
    padding: "10px 10px",
    border: "none",
    borderRadius: "50%",
    boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
    cursor: "pointer",
    zIndex: "1000",
    opacity: "0.8"
  };

  return (
    <div>
      <button
        style={{ ...arrowButtonStyle, display: isVisible ? "block" : "none" }}
        onClick={scrollToTop}
      >
        <FaArrowUp size="24px" />
      </button>
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
        <button style={buttonStyle}>
          <FaWhatsapp size="24px" />
        </button>
      </a>
    </div>
  );
};

export default WhatsAppButton;
