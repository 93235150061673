import React from "react";
import { useSelector } from "react-redux";
import { FaMapMarkerAlt } from "react-icons/fa";

const Location = () => {
  const { webData } = useSelector((state) => state.clnAuth);
  let footerBilgi = webData && JSON.parse(webData.sabit);

  return (
    <div className="location" id="location">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="section-header text-left">
              <p>Yerimiz..</p>
              <h2>Araç Yıkama Noktası</h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="location-item">
                  <i className="fa fa-map-marker-alt"></i>
                  <div className="location-text">
                    <h3>Yıkama Noktası</h3>
                    <p>
                      {footerBilgi &&
                        footerBilgi.yikamaAdres &&
                        footerBilgi.yikamaAdres}
                    </p>
                    <p>
                      <strong>Call:</strong>
                      {footerBilgi &&
                        footerBilgi.yikamaTelefon &&
                        footerBilgi.yikamaTelefon}
                    </p>
                    <p>
                      <strong>Koordinat:</strong>
                      {footerBilgi &&
                        footerBilgi.coordinate &&
                        footerBilgi.coordinate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-primary w-25">
              <FaMapMarkerAlt /> Yol Tarifi
            </button>
          </div>
          <div className="col-6">
            <div className="section-header text-left">
              <p> </p>
              <h2>Halı Yıkama Noktası</h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="location-item">
                  <i className="fa fa-map-marker-alt"></i>
                  <div className="location-text">
                    <h3>Yıkama Noktası</h3>
                    <p>
                      {footerBilgi &&
                        footerBilgi.yikamaAdres &&
                        footerBilgi.yikamaAdres}
                    </p>
                    <p>
                      <strong>Call:</strong>
                      {footerBilgi &&
                        footerBilgi.yikamaTelefon &&
                        footerBilgi.yikamaTelefon}
                    </p>
                    <p>
                      <strong>Koordinat:</strong>
                      {footerBilgi &&
                        footerBilgi.coordinate &&
                        footerBilgi.coordinate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-primary w-25"><FaMapMarkerAlt /> Yol Tarifi</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
