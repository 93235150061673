import React from 'react'
import team1 from "../assets/team-1.jpg"
import team2 from "../assets/team-2.jpg"
import team3 from "../assets/team-3.jpg"
import team4 from "../assets/team-4.jpg"

const Team = () => {
  return (
    <div className="team" id='team'>
            <div className="container">
                <div className="section-header text-center">
                    <p>Biz Kimiz..</p>
                    <h2>Yaptığımız İşler Hakkında</h2>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="team-item">
                            <div className="team-img">
                                <img src={team1} alt="aabim oto-halı yıkama osmaniye kadirli"/>
                            </div>
                            <div className="team-text">
                                <h2>Halı Yıkama</h2>
                                <p>Otomatik Makine İle Tertemiz Halılar</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-item">
                            <div className="team-img">
                            <img src={team2} alt="aabim oto-halı yıkama osmaniye kadirli"/>
                            </div>
                            <div className="team-text">
                                <h2>Koltuk Temizliği</h2>
                                <p>Buharlı Makine ile Sağlıklı Temizlik</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-item">
                            <div className="team-img">
                            <img src={team3} alt="aabim oto-halı yıkama osmaniye kadirli"/>
                            </div>
                            <div className="team-text">
                                <h2>Kirli Yüzeyler</h2>
                                <p>Tertemiz Yaşam Alanı</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-item">
                            <div className="team-img">
                            <img src={team4} alt="aabim oto-halı yıkama osmaniye kadirli"/>
                            </div>
                            <div className="team-text">
                                <h2>Hijyenik Teslimat</h2>
                                <p>Bizimle Güvendesiniz</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Team