import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Aabim Halı Yıkama",
  description: "En Temiz Halı Yıkama Firması, Hijyenik Temizlik Firması, Hijyenik Firma, Kadirlinin En iyi temizlik firması, Osmaniyenin en iyi temizlik firması, En temiz firma, dürüst temizlik firması, dürüst firma, Aabim Halı Yıkama, Oto Yıkama, Kadirli, Osmaniye",
  keywords:
    "halı, yıkama, kadirli, osmaniye, oto, koltuk, yorgan, battaniye, kilim, yolluk, kombi, temizliği, petek, araç, dezenfeksiyonu, ozon, cihazıyla, oda, temizlik, malzemeleri, profesyonel, uygun, fiyatlı, en, iyi, hizmeti, ekonomik, detaylı, içi, deri, kumaş, evde, şirketi, temizleme, firması, jeneratörü, hijyen, satışı, kampanya, buharlı, indirim",
};

export default Meta;
