import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDeleteTahsilatByIdMutation, useGetTahsilatFilterByIdQuery } from "../../slice/redux/tahsilatSlice";
import Spinner from "../../components/Spinner";
import { setAllReloadPage } from "../../slice/auth/authSlice";
import TahsilatKayit from "./TahsilatKayit";
import { Paginator } from "primereact/paginator";
import { FaBackspace, FaTrashAlt } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { primeReactStyle } from "../PrimeReactUtil";
import { toast } from "react-toastify";

const TahsilatTable = () => {
  const dispatch = useDispatch();
  const { selectedMusteri, reloadPage } = useSelector((state) => state.clnAuth);
  let musteriID = selectedMusteri.id;
  const [tahsilatList, setTahsilatList] = useState([]);
  const {
    data: tahsilatTable,
    isLoading,
    refetch,
  } = useGetTahsilatFilterByIdQuery(musteriID);
  const [deleteTahsilatById, {data: delInfo, isLoading: delLoading}] = useDeleteTahsilatByIdMutation()

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(8);
  const [silID, setSilID] = useState("");
  const [visible, setVisible] = useState(null);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    if (tahsilatTable || !tahsilatTable) {
      setTahsilatList(tahsilatTable);
    }
  }, [tahsilatTable]);

  useEffect(() => {
    refetch();
  }, [musteriID, refetch]);

  useEffect(() => {
    if (selectedMusteri.id !== musteriID) {
      dispatch(setAllReloadPage(true));
    }
  }, [selectedMusteri, musteriID, dispatch]);

  useEffect(() => {
    if (reloadPage) {
      dispatch(setAllReloadPage(false));
      refetch();
    }
  }, [reloadPage, refetch, dispatch]);

  const silSorModul = (id) => {
    setSilID(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteTahsilatById(silID).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
      setVisible(false)
      dispatch(setAllReloadPage(true))
    }
  }, [delInfo, refetch, dispatch]);

  return (
    <section className="islem-kayit-info">
      {(isLoading || delLoading) && <Spinner />}
      <div className="d-flex flex-column gap-2">
        <TahsilatKayit />
        <div className="col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>İşlem</th>
                <th>Tarih</th>
                <th>Açıklama</th>
                <th>Tutar</th>
              </tr>
            </thead>
            {tahsilatList && tahsilatList.length > 0 && (
              <tbody>
                {tahsilatList
                  .slice(first, first + rows)
                  .map((tahsilat, index) => (
                    <tr key={index}>
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => silSorModul(tahsilat.id)}
                        >
                          <FaTrashAlt />
                        </button>
                      </td>
                      <td>{tahsilat.tarih}</td>
                      <td>{tahsilat.aciklama}</td>
                      <td>{tahsilat.tutar}</td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={tahsilatList && tahsilatList.length}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <Dialog
        header="Kayıt Silme Onayı"
        visible={visible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu <strong>Tahsilat Kaydı</strong>{" "}
            Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDelete}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </section>
  );
};

export default TahsilatTable;
