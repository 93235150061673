import React, { useState } from "react";
import { useGetIslemFilterByIdQuery } from "../../slice/redux/islemSlice";
import { useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";

const MusteriYikamaScreen = () => {
  const { user } = useSelector((state) => state.clnAuth);
  const musteriID = user.userID;
  const screenWidth = window.innerWidth
  const { data: islemTable } = useGetIslemFilterByIdQuery(musteriID);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const [selectedIslem, setSelectedIslem] = useState({
    islemID: "",
    tarih: "",
    aciklama: "",
    adet: "",
    metreKare: "",
    birimFiyat: "",
    genelToplam: "",
  });
  const { tarih, aciklama, adet, birimFiyat, genelToplam } =
    selectedIslem;

  const handleRowClick = (islem) => {
    setSelectedIslem(islem);
  };

  return (
    <section className="musteriislem">
      <div className="mb-4">
        <div className="d-flex flex-column">
          <div className="islem_aciklama gap-2">
            <div className="d-flex flex-row gap-1">
              <label className="form-label">Tarih:</label>
              <span>
                <strong>{tarih}</strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-1">
              <label className="form-label">Açıklama:</label>
              <span>
                <strong>{aciklama}</strong>
              </span>
            </div>
          </div>
          <div className="islem_aciklama gap-2">
            <div className="d-flex flex-row gap-1">
              <label className="form-label">
                Adet / Mt<sup>2</sup> :
              </label>
              <span>
                <strong>
                  {Number(adet).toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  })}
                </strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-1">
              <label className="form-label">Birim Fiyat:</label>
              <span>
                <strong>
                  {Number(birimFiyat).toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  })}
                </strong>
              </span>
            </div>
            <div className="d-flex flex-row gap-1">
              <label className="form-label">Genel Toplam:</label>
              <span>
                <strong>
                  {Number(genelToplam).toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  })}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-2" style={{ border: "2px solid #000" }} />
      <div className="text-center">
        <h4>İşlemler Tablosu</h4>
      </div>
      <table className="table table-striped table-bordered" style={{ width: `${screenWidth <756 ? "350px" : "auto"}`}}>
        <thead>
          <tr>
            <th className="mobil_hidden" style={{ width: "5%" }}>
              İşlem ID
            </th>
            <th style={{ width: "10%" }}>Tarih</th>
            <th className="mobil_hidden" style={{ width: "55%" }}>Açıklama</th>
            <th className="text_right mobil_hidden" style={{ width: "10%" }}>
              Miktar/mt<sup>2</sup>{" "}
            </th>
            <th className="text_right" style={{ width: "10%" }}>
              Birim Fiyat
            </th>
            <th className="text_right" style={{ width: "10%" }}>
              Genel Toplam
            </th>
          </tr>
        </thead>
        {islemTable && islemTable.length > 0 && (
          <tbody>
            {islemTable.slice(first, first + rows).map((islem) => (
              <tr
                className="cursor-pointer"
                key={islem.id}
                onClick={() => handleRowClick(islem)}
              >
                <td className="mobil_hidden">{islem.id}</td>
                <td>{islem.tarih}</td>
                <td className="mobil_hidden">{islem.aciklama}</td>
                <td className="text_right mobil_hidden">{islem.adet}</td>
                <td className="text_right">
                  {Number(islem.birimFiyat).toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  })}
                </td>
                <td className="text_right">
                  {Number(islem.genelToplam).toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={islemTable && islemTable.length}
        onPageChange={onPageChange}
      />
    </section>
  );
};

export default MusteriYikamaScreen;
