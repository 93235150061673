import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import {
  useGetAllSepetTeslimQuery,
  useUpdateTeslimIptalMutation,
} from "../../slice/redux/sabitSlice";
import { SERVERPICT_URL } from "../../slice/constants";
import Spinner from "../../components/Spinner";
import { Paginator } from "primereact/paginator";

const TeslimEdilenTable = () => {
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const { data: sepetTable, isLoading, refetch } = useGetAllSepetTeslimQuery();

  const [updateTeslimIptal, { data: upInfo, isLoading: upLoading }] =
    useUpdateTeslimIptalMutation();

  const [visible, setVisible] = useState(false);

  const handleDeliverClick = (order) => {
    setSelectedOrderId(order);
    setVisible(true);
  };

  const handleConfirmClick = async () => {
    if (selectedOrderId) {
      let siparisID = selectedOrderId.siparisID
      await updateTeslimIptal(siparisID);
      setSelectedOrderId(null);
      setVisible(false)
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [upInfo, refetch]);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const renderSepet = (sepet) => {
    try {
      const items = JSON.parse(sepet);
      return (
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              style={{ listStyle: "none", borderBottom: "1px solid lightgray" }}
              className="mb-1"
            >
              <img
                src={`${SERVERPICT_URL}${item.pictureUrl}`}
                alt={item.aciklama}
                width="50"
              />
              {item.aciklama} - {item.quantity} adet - {item.fiyat} ₺
            </li>
          ))}
        </ul>
      );
    } catch (error) {
      return <span>Geçersiz sepet verisi</span>;
    }
  };

  return (
    <>
      {(isLoading || upLoading) && <Spinner />}
      <div className="responsive-table">
      <table className="table">
      <caption>Teslimat Tablosu</caption>
        <thead>
          <tr>
            <th scope="col" style={{ width: "7%" }}>Tarih</th>
            <th scope="col" style={{ width: "13%" }}>Adı Soyadı</th>
            <th scope="col" style={{ width: "5%" }}>Telefon</th>
            <th scope="col" style={{ width: "20%" }}>Adres</th>
            <th scope="col" style={{ width: "5%" }}>Tutar</th>
            <th scope="col" style={{ width: "35%" }}>Sepet</th>
            <th scope="col" style={{ width: "10%" }}>İşlemler</th>
          </tr>
        </thead>
        {sepetTable && sepetTable.length > 0 && (
          <tbody>
            {sepetTable.slice(first, first + rows).map((order) => (
              <tr key={order.siparisID}>
                <td data-label="Tarih">{order.tarih}</td>
                <td data-label="Adı Soyadı">{order.adiSoyadi}</td>
                <td data-label="Telefon">{order.telefon}</td>
                <td data-label="Adres">{order.adres}</td>
                <td data-label="Tutar">{order.tutar}</td>
                <td data-label="Sepet">{renderSepet(order.sepet)}</td>
                <td data-label="İşlemler" className="d-flex flex-row gap-2 mobil_right">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeliverClick(order)}
                  >
                    Teslimat İptal
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={sepetTable && sepetTable.length}
        onPageChange={onPageChange}
      />

      <Dialog
        header="Teslimat İptali Sorgusu"
        visible={visible}
        style={{ width: `${window.innerWidth < 756 ? "75vw" : "50vw"}` }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">
          <div className="mb-3 text-center">
            <strong>{selectedOrderId && selectedOrderId.adiSoyadi}</strong> Adlı
            Müşterinin{" "}
            <strong>{selectedOrderId && selectedOrderId.tutar}</strong> tutarlık{" "}
            <strong>{selectedOrderId && selectedOrderId.siparisID}</strong> nolu
            siparişinin teslimatı iptal edilecektir
          </div>
          <p className="mb-2 text-uppercase text-center">
            <strong>Onaylıyor musunuz?</strong>
          </p>
          <button className="btn btn-danger w-100" onClick={handleConfirmClick}>
            Onayla
          </button>
        </p>
      </Dialog>
    </>
  );
};

export default TeslimEdilenTable;
