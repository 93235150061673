import React, { useEffect, useState } from "react";
import { primeReactStyle } from "../PrimeReactUtil";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector } from "react-redux";
import { useAddTahsilatMutation } from "../../slice/redux/tahsilatSlice";
import { setAllReloadPage } from "../../slice/auth/authSlice";
import { toast } from "react-toastify";
import moment from "moment";
import Spinner from "../../components/Spinner";

const TahsilatKayit = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const { selectedMusteri } = useSelector((state) => state.clnAuth);
  const [addTahsilat, { data: addInfo, isLoading }] = useAddTahsilatMutation();

  const [newTahsilat, setNewTahsilat] = useState({
    musteriID: selectedMusteri.id,
    tarih: "",
    aciklama: "",
    tutar: "",
  });
  const { tarih, aciklama, tutar } = newTahsilat;

  useEffect(() => {
    if (date) {
      if (tarih === "" || date !== tarih) {
        setNewTahsilat((prevState) => ({
          ...prevState,
          tarih: moment(date).format("YYYY.MM.DD"),
        }));
      }
    }
  }, [date, tarih]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTahsilat({ ...newTahsilat, [name]: value });
  };

  const tahsilatKaydet = async () => {
    let data = { musteriID: selectedMusteri.id, tarih, aciklama, tutar };
    try {
      await addTahsilat(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      dispatch(setAllReloadPage(true));
    }
  }, [addInfo, dispatch]);

  return (
    <div className="tahsilat-info">
      {isLoading && <Spinner />}
      <h3 className="text-center">
        <u>{selectedMusteri && selectedMusteri.adiSoyadi}</u> Tahsilatları
      </h3>
      <hr className="my-2" />
      <div className="row">
        <div className="col-lg-3 col-md-3 col-11" style={{ marginTop: "2px" }}>
          <label htmlFor="tarih" className="form-label col-ld-2 col-md-2 col-4">
            Tarih
          </label>
          <Calendar
            style={primeReactStyle.calenderStyle}
            inputStyle={primeReactStyle.calenderInputStyle}
            value={date}
            onChange={(e) => setDate(e.value)}
            dateFormat="yy/mm/dd"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-11">
          <label htmlFor="aciklama" className="form-label">
            Açıklama
          </label>
          <input
            type="text"
            className="form-control"
            name="aciklama"
            value={aciklama}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-lg-3 col-md-3 col-11">
          <label htmlFor="tutar" className="form-label">
            Tutar
          </label>
          <input
            type="text"
            className="form-control"
            name="tutar"
            value={tutar}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-md-12 mt-2">
          <button
            type="submit"
            className="btn btn-primary w-100"
            onClick={tahsilatKaydet}
          >
            Tahsilat Ekle
          </button>
        </div>
      </div>
    </div>
  );
};

export default TahsilatKayit;
