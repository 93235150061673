import React, { useEffect, useState } from "react";
import IslemTable from "./IslemTable"; // İşlem tablosu bileşeni
import TahsilatTable from "./TahsilatTable"; // Tahsilat tablosu bileşeni
import { useGetMusterisQuery } from "../../slice/redux/musteriSlice";
import { AutoComplete } from "primereact/autocomplete";
import Spinner from "../../components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { setReloadPage, setSelectedMusteri } from "../../slice/auth/authSlice";
import ToplamBorcTahsilat from "./ToplamBorcTahsilat";

const IslemScreen = () => {
  const dispatch = useDispatch();
  const { data: musteriTable, isLoading: musLoading } = useGetMusterisQuery();
  const { selectedMusteri } = useSelector((state) => state.clnAuth);
  const [musteris, setMusteris] = useState([]);
  const [selectMusteri, setSelectMusteri] = useState(null);
  const [filteredMusteris, setFilteredMusteris] = useState(null);
  const [customerChange, setCustomerChange] = useState(null);

  useEffect(() => {
    if (musteriTable) {
      setMusteris(musteriTable);
    }
  }, [musteriTable]);

  const searchMusteri = (event) => {
    setTimeout(() => {
      let _filterMusteri;
      if (!event.query.trim().length) {
        _filterMusteri = [...musteris];
      } else {
        _filterMusteri = musteris.filter((musteri) => {
          return musteri.adiSoyadi
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }
      setFilteredMusteris(_filterMusteri);
    }, 250);
  };

  useEffect(() => {
    if (
      selectedMusteri &&
      selectMusteri &&
      selectMusteri.adiSoyadi !== selectedMusteri.adiSoyadi
    ) {
      dispatch(setSelectedMusteri(selectMusteri));
      dispatch(setReloadPage(true));
      setCustomerChange(true);
    }
  }, [selectMusteri, selectedMusteri, dispatch]);

  return (
    <section className="cariislem">
      {musLoading && <Spinner />}
      <div className="row">
        <div className="col-md-8">
          <div className="blog-detail gap-2">
            <div className="col-lg-5 col-md-5 col-11">
              <AutoComplete
                field="adiSoyadi"
                className="w-100"
                value={selectMusteri}
                suggestions={filteredMusteris}
                completeMethod={searchMusteri}
                onChange={(e) => {
                  setCustomerChange(false);
                  setSelectMusteri(e.value);
                }}
                dropdown
                inputStyle={{
                  background: "white",
                  color: "black",
                  padding: "5px",
                }}
                style={{ height: "36px" }}
                placeholder="Müşteri Adı Soyadı Giriniz.."
              />
            </div>
            <div className="col-lg-7 col-md-7 col-11">
              <h3 className="text-center mobil-baslik">
                <u>{selectedMusteri && selectedMusteri.adiSoyadi}</u> İşlemleri
              </h3>
            </div>
          </div>
          <hr className="my-2" />
          <IslemTable />
        </div>
        <div className="col-md-4">
          <TahsilatTable />
        </div>
        <hr className="my-1" />
        <ToplamBorcTahsilat customerChange={customerChange} />
        <hr className="my-2" />
      </div>
    </section>
  );
};

export default IslemScreen;
