import { BLOG_URL, BLOGDETAIL_URL, UPLOAD_URL } from "../constants";
import apiSlice from "../apiSlice";

export const blogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: () => ({
        url: `${BLOG_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblog"],
    }),
    getAllBlogs: builder.query({
      query: () => ({
        url: `${BLOG_URL}/all`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblog"],
    }),
    addBlog: builder.mutation({
      query: (form) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: form,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblog"],
    }),
    updateBlogById: builder.mutation({
      query: (data) => ({
        url: `${BLOG_URL}/${data.blogID}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblog"],
    }),
    deleteBlogById: builder.mutation({
      query: (id) => ({
        url: `${BLOG_URL}/${id}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblog"],
    }),
    getBlogById: builder.query({
      query: (id) => ({
        url: `${BLOG_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblog"],
    }),
    getBlogDetails: builder.query({
      query: (blogID) => ({
        url: `${BLOGDETAIL_URL}/${blogID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblogdetail"],
    }),
    addBlogDetail: builder.mutation({
      query: (data) => ({
        url: `${BLOGDETAIL_URL}`,
        method: "POST",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblblogdetail"],
    }),
    uploadImagesByBlogId: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useGetAllBlogsQuery,
  useAddBlogMutation,
  useUpdateBlogByIdMutation,
  useDeleteBlogByIdMutation,
  useGetBlogByIdQuery,
  useGetBlogDetailsQuery,
  useAddBlogDetailMutation,
  useUploadImagesByBlogIdMutation,
} = blogApiSlice;
