import React from 'react'

const Facts = () => {
  return (
    <div className="facts" data-parallax="scroll" data-image-src="img/facts.jpg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="facts-item">
                            <i className="fa fa-map-marker-alt"></i>
                            <div className="facts-text">
                                <h3 data-toggle="counter-up">25</h3>
                                <p>Farklı Rota</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="facts-item">
                            <i className="fa fa-user"></i>
                            <div className="facts-text">
                                <h3 data-toggle="counter-up">350</h3>
                                <p>Kurumsal Müşteri</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="facts-item">
                            <i className="fa fa-users"></i>
                            <div className="facts-text">
                                <h3 data-toggle="counter-up">11500</h3>
                                <p>Mutlu Aileler</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="facts-item">
                            <i className="fa fa-check"></i>
                            <div className="facts-text">
                                <h3 data-toggle="counter-up">25060</h3>
                                <p>Teslim Edilmiş Hizmet</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Facts