import React, { useState, useCallback, useEffect } from "react";
import { Paginator } from "primereact/paginator";
import Spinner from "../../components/Spinner";
import { useDeleteOrderByIdMutation, useGetOrdersQuery, useUpdateOrderByIdMutation } from "../../slice/redux/orderSlice";
import { Dialog } from "primereact/dialog";
import {toast} from "react-toastify"

const TemizlikOrderPage = () => {
  const { data: orders, isLoading, isError, message, refetch } = useGetOrdersQuery();
  const [ updateOrderById, {data: upInfo, isLoading: upLoading}] = useUpdateOrderByIdMutation()
  const [ deleteOrderById, {data: delInfo, isLoading: delLoading}] = useDeleteOrderByIdMutation()
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [visible, setVisible] = useState(false);
  const [silID, setSilID] = useState("");
  const [kapaliDurum, setKapaliDurum] = useState(null)

  const handlePageChange = useCallback((event) => {
    setFirst(event.first);
    setRows(event.rows);
  }, []);

  const handleClosed = async (id) => {
    try {
        await updateOrderById(id).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch()
    }
  }, [upInfo, refetch]);

  const handleDeleteOnay = (id) => {
    setSilID(id)
    setVisible(true)
  };

  const handleSiparisSil = async() => {
    try {
        await deleteOrderById(silID).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
  }

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      setVisible(false)
      refetch()
    }
  }, [delInfo, refetch]);


  const durumDegistir = () => {
    if(!kapaliDurum) {
        setKapaliDurum("1")
    } else {
        setKapaliDurum(null)
    }
  }

  return (
    <div className="mx-2">
      {(isLoading || upLoading || delLoading) && <Spinner />}
      <div className="d-flex flex-row">
        <button className="btn btn-secondary col-lg-2 col-md-2 col-4" onClick={() => durumDegistir()}>
          {!kapaliDurum ? "Kapalı İşlemler" : "Açık İşlemler"}
        </button>
        <h3 className="text-center col-lg-10 col-md-10 col-8">
          Temizlik Siparişleri
        </h3>
      </div>
      <hr className="my-2" />
      <div className="responsive-table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ width: "5%" }} scope="col">
                ID
              </th>
              <th style={{ width: "15%" }} scope="col">
                İşlemler
              </th>
              <th style={{ width: "7%" }} scope="col">
                Tarih
              </th>
              <th style={{ width: "16%" }} scope="col">
                Adı Soyadı
              </th>
              <th style={{ width: "20%" }} scope="col">
                Adres
              </th>
              <th style={{ width: "8%" }} scope="col">
                Telefon
              </th>
              <th style={{ width: "25%" }} scope="col">
                Önemli Not
              </th>
            </tr>
          </thead>
          {orders && orders.length > 0 && (
            <tbody>
              {orders
              .filter((order) => order.kapaliFlag === kapaliDurum)
              .slice(first, first + rows).map((order) => (
                <tr key={order.id}>
                  <td data-label="ID">{order.id}</td>
                  <td data-label="İşlemler" className="d-flex gap-1">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => handleClosed(order.id)}
                      style={{ display: `${order.kapaliFlag ? "none" : "initial"}`}}
                    >
                      Tamamlandı
                    </button>
                    <button
                      className="btn btn-danger w-75"
                      onClick={() => handleDeleteOnay(order.id)}
                    >
                      Sil
                    </button>
                  </td>
                  <td data-label="Tarih">{order.tarih}</td>
                  <td data-label="Adı Soyadı">{order.adiSoyadi}</td>
                  <td data-label="Adres">{order.adres}</td>
                  <td data-label="Telefon">{order.telefon}</td>
                  <td data-label="Önemli Not">{order.onemliNot}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={orders && orders.length}
          onPageChange={handlePageChange}
        />
      </div>
      {isError && <p className="text-danger">{message}</p>}
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visible}
        style={{ width: `${window.innerWidth < 756 ? "75vw" : "30vw"}` }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">
          {silID && (
            <div className="mt-3 gap-2">
             <p>{silID} Nolu Temizlik Sipariş İşlemi Silinecektir.. Onaylıyor musunuz?</p>
            <div className="d-flex flex-row justify-content-between gap-2">
            <button className="btn btn-danger w-100" onClick={handleSiparisSil}>
                Onayla
              </button>
              <button className="btn btn-primary w-100" onClick={() => setVisible(false)}>
                Vazgeç
              </button>
            </div>
            </div>
          )}
        </p>
      </Dialog>
    </div>
  );
};

export default TemizlikOrderPage;
