import React, { useState, useEffect } from "react";
import { useGetSabitsQuery, useUpdateSabitMutation, useUploadImagesBySabitIdMutation } from "../../slice/redux/sabitSlice";
import Spinner from "../../components/Spinner";
import { SERVERPICT_URL } from "../../slice/constants";
import {toast} from "react-toastify"

const SetupScreen = () => {
  const { data: setupTable, isLoading, refetch } = useGetSabitsQuery();
  const [updateSabit ,{data: upInfo, isLoading: upLoading}] = useUpdateSabitMutation()
  const [uploadImagesBySabitId ,{data: upPicInfo, isLoading: upPicLoading}] = useUploadImagesBySabitIdMutation()
  const folder = "sabit";

  const [sabit, setSabit] = useState({
    firmaAdi: "",
    adres: "",
    telefon: "",
    ceptel: "",
    email: "",
    calismaSaatleri: "",
    facebookUrl: "",
    instagramUrl: "",
    youtubeUrl: "",
    twitterUrl: "",
    linkedinUrl: "",
    yikamaAdres: "",
    coordinate: "",
    yikamaTelefon: "",
    logoUrl: "",
  });
  const {
    firmaAdi,
    adres,
    telefon,
    ceptel,
    email,
    calismaSaatleri,
    facebookUrl,
    instagramUrl,
    youtubeUrl,
    twitterUrl,
    linkedinUrl,
    yikamaAdres,
    coordinate,
    yikamaTelefon,
    logoUrl,
  } = sabit;

  useEffect(() => {
    if (setupTable) {
      setSabit((prevState) => ({
        ...prevState,
        firmaAdi: setupTable.firmaAdi,
        adres: setupTable.adres,
        telefon: setupTable.telefon,
        ceptel: setupTable.ceptel,
        email: setupTable.email,
        calismaSaatleri: setupTable.calismaSaatleri,
        facebookUrl: setupTable.facebookUrl,
        instagramUrl: setupTable.instagramUrl,
        youtubeUrl: setupTable.youtubeUrl,
        twitterUrl: setupTable.twitterUrl,
        linkedinUrl: setupTable.linkedinUrl,
        yikamaAdres: setupTable.yikamaAdres,
        coordinate: setupTable.coordinate,
        yikamaTelefon: setupTable.yikamaTelefon,
        logoUrl: setupTable.logoUrl,
      }));
    }
  },[setupTable]);

  const [selectedLogo, setSelectedLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSabit({ ...sabit, [name]: value });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogo(file);
    setLogoPreview(URL.createObjectURL(file));
  };

  const setupKaydet = async () => {
    const formData = new FormData();
    formData.append("firmaAdi", firmaAdi);
    formData.append("adres", adres);
    formData.append("folder", folder);
    formData.append("image", selectedLogo);
    try {
      await uploadImagesBySabitId(formData).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (upPicInfo && upPicInfo.message) {
      toast(upPicInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [upPicInfo, refetch]);

  const setupResimsizKaydet = async () => {
    let data = {
      firmaAdi,
      adres,
      telefon,
      ceptel,
      email,
      calismaSaatleri,
      facebookUrl,
      instagramUrl,
      youtubeUrl,
      twitterUrl,
      linkedinUrl,
      yikamaAdres,
      coordinate,
      yikamaTelefon,
    };
    try {
      await updateSabit(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [upInfo, refetch]);

  return (
    <section className="setup">
      {(isLoading || upPicLoading || upLoading) && <Spinner />}
      <h2 className="mb-4">Ayarlar Yönetimi</h2>
      <div className="blog-detail gap-1">
        <div className="col-lg-6 col-md-6 col-11">
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Firma Adı</label>
            <input
              type="text"
              className="form-control"
              name="firmaAdi"
              value={firmaAdi}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Adres</label>
            <input
              type="text"
              className="form-control"
              name="adres"
              value={adres}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Telefon</label>
            <input
              type="text"
              className="form-control"
              name="telefon"
              value={telefon}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Cep Telefonu</label>
            <input
              type="text"
              className="form-control"
              name="ceptel"
              value={ceptel}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">E-posta</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Çalışma Saatleri</label>
            <input
              type="text"
              className="form-control"
              name="calismaSaatleri"
              value={calismaSaatleri}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Yıkama Adresi</label>
            <input
              type="text"
              className="form-control"
              name="yikamaAdres"
              value={yikamaAdres}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Koordinatlar</label>
            <input
              type="text"
              className="form-control"
              name="coordinate"
              value={coordinate}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Yıkama Telefon</label>
            <input
              type="text"
              className="form-control"
              name="yikamaTelefon"
              value={yikamaTelefon}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-11">
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Facebook URL</label>
            <input
              type="text"
              className="form-control"
              name="facebookUrl"
              value={facebookUrl}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Instagram URL</label>
            <input
              type="text"
              className="form-control"
              name="instagramUrl"
              value={instagramUrl}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">YouTube URL</label>
            <input
              type="text"
              className="form-control"
              name="youtubeUrl"
              value={youtubeUrl}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Twitter URL</label>
            <input
              type="text"
              className="form-control"
              name="twitterUrl"
              value={twitterUrl}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">LinkedIn URL</label>
            <input
              type="text"
              className="form-control"
              name="linkedinUrl"
              value={linkedinUrl}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-3 col-4">Logo URL</label>
            <input
              type="file"
              className="form-control"
              name="logoUrl"
              onChange={handleLogoChange}
            />
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <span className="col-2"></span>
            <div className="img-info">
              {logoPreview && logoUrl ? (
                <div
                  className="mb-1"
                  style={{ border: "1px solid #000", borderRadius: "10px" }}
                >
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    style={{
                      padding: "3px",
                      maxWidth: "200px",
                      maxHeight: "200px",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="mb-1"
                  style={{ border: "1px solid #000", borderRadius: "10px" }}
                >
                  <img
                    src={`${SERVERPICT_URL}${logoUrl}`}
                    alt="Logo Preview"
                    style={{
                      padding: "3px",
                      maxWidth: "200px",
                      maxHeight: "200px",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!logoPreview && logoUrl ? (
        <button
          type="submit"
          className="btn btn-primary mt-2 w-100"
          onClick={setupResimsizKaydet}
        >
          Logo Yenilemeden Kaydet
        </button>
      ) : (
        <button
          type="submit"
          className="btn btn-primary mt-2 w-100"
          onClick={setupKaydet}
        >
          Tüm Bilgileri Kaydet
        </button>
      )}
    </section>
  );
};

export default SetupScreen;
