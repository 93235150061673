import React, { useState } from "react";
import OutletHeader from "./OutletHeader";
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetBlogByIdQuery,
  useGetBlogDetailsQuery,
  useAddBlogDetailMutation,
  useGetAllBlogsQuery,
} from "../slice/redux/blogSlice";
import { SERVERPICT_URL } from "../slice/constants";
import Spinner from "./Spinner";
import { truncateWord } from "../app/Utility";

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: blogTable, isLoading } = useGetAllBlogsQuery();
  const { data: editBlog, isLoading: isLoadingBlog } = useGetBlogByIdQuery(id);
  const {
    data: blogDetails,
    isLoading: isLoadingDetails,
    refetch,
  } = useGetBlogDetailsQuery(id);
  const [addBlogDetail] = useAddBlogDetailMutation();

  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");

  const handleAddComment = async (e) => {
    await addBlogDetail({
      blogID: id,
      commit: comment,
      email,
      tarih: new Date().toISOString().split("T")[0],
    });
    setComment("");
    setEmail("");
    refetch();
  };

  return (
    <>
      <TopBar />
      <Header />
      <OutletHeader baslik={"Blog Sayfası ve Yorumları"} />
      {(isLoading || isLoadingBlog || isLoadingDetails) && <Spinner />}
      <div className="container">
        <div className="blog-detail gap-3">
          <div className="blog-side">
            <div className="blog-content">
              {editBlog ? (
                <div className="blog-detail">
                  <img
                    src={`${SERVERPICT_URL}${editBlog.pictureUrl}`}
                    alt={editBlog.pictureUrl}
                    className="blog-image"
                  />
                  <div className="blog-text">
                    <h2>{editBlog.blogBaslik}</h2>
                    <p>{editBlog.aciklama}</p>
                    <p>
                      <i className="fa fa-user"></i> {editBlog.email}
                    </p>
                    <p>
                      <i className="fa fa-calendar"></i> {editBlog.tarih}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="blog-comments">
              <h3>Ziyaretçi Yorumları</h3>
              <hr className="my-2" />
              {blogDetails &&
                blogDetails.map((detail) => (
                  <div key={detail.detailID} className="comment">
                    <p>{detail.commit}</p>
                    <div className="d-flex flex-row gap-2">
                      <p>
                        <i className="fa fa-user"></i> {detail.email}
                      </p>
                      <p>
                        <i className="fa fa-calendar"></i> {detail.tarih}
                      </p>
                    </div>
                    <hr className="my-2" />
                  </div>
                ))}
            </div>
            <div className="add-comment">
              <h4 className="text-center">Yorum Ekle</h4>
              <div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Yorum</label>
                  <textarea
                    className="form-control"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    required
                  />
                </div>
                <button
                  className="btn btn-primary mt-2"
                  onClick={handleAddComment}
                >
                  Gönder
                </button>
              </div>
            </div>
          </div>
          <div className="blog-write">
            <h3 className="text-center">Tüm Blog Yazıları</h3>
            <hr className="my-1" />
            <div className="tumblogmap">
              {blogTable && blogTable.length > 0 && (
                <>
                  {blogTable.map((blog) => (
                    <div
                      key={blog.blogID}
                      className="blog-box"
                      onClick={() => navigate(`/blog/${blog.blogID}`)}
                    >
                      <div className="d-flex flex-row gap-1">
                        <img
                          src={`${SERVERPICT_URL}${blog.pictureUrl}`}
                          alt=""
                          style={{ height: "40px", width: "40px" }}
                        />
                         {" "}
                        <span className="mt-2">
                          <strong>{truncateWord(blog.blogBaslik, 30)}</strong>
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;
