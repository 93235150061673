import React, { useEffect, useState } from "react";
import { FaBackspace, FaPlus, FaSyncAlt } from "react-icons/fa";
import { Paginator } from "primereact/paginator";
import Spinner from "../../components/Spinner";
import { SERVERPICT_URL } from "../../slice/constants";
import {
  useAddMusteriMutation,
  useDeleteMusteriByIdMutation,
  useGetMusterisQuery,
  useUpdateMusteriMutation,
  useUploadImagesByMusteriIdMutation,
} from "../../slice/redux/musteriSlice";
import { toast } from "react-toastify";

const MusteriScreen = () => {
  const { data: musteriTable, isLoading, refetch } = useGetMusterisQuery();
  const [addMusteri, { data: addInfo, isLoading: addLoading }] =
    useAddMusteriMutation();
  const [
    uploadImagesByMusteriId,
    { data: upPicInfo, isLoading: upPicLoading },
  ] = useUploadImagesByMusteriIdMutation();
  const [updateMusteri, { data: upInfo, isLoading: upLoading }] =
    useUpdateMusteriMutation();
  const [deleteMusteriById, { data: delInfo, isLoading: delLoading }] =
    useDeleteMusteriByIdMutation();
    const [aramaText, setAramaText] = useState("");

  const folder = "musteri";
  const [tusAdi, setTusAdi] = useState("Ekle");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [sakinca, setSakinca] = useState("");
  const [aktif, setAktif] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [formData, setFormData] = useState({
    musteriID: null,
    adiSoyadi: "",
    tcKimlik: "",
    telefon: "",
    adres: "",
    vergiDairesi: "",
    email: "",
    sakincaliFlag: "",
    aktifFlag: "",
    fotografUrl: "",
  });

  const {
    musteriID,
    adiSoyadi,
    tcKimlik,
    telefon,
    adres,
    vergiDairesi,
    email,
    fotografUrl,
  } = formData;

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "fotografUrl" && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setFormData({ ...formData, [name]: file });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!(fotografUrl instanceof File) && tusAdi === "Güncelle") {
        let data = {
          musteriID,
          adiSoyadi,
          tcKimlik,
          telefon,
          adres,
          vergiDairesi,
          email,
          sakincaliFlag: sakinca,
          aktifFlag: aktif,
        };
        await updateMusteri(data).unwrap();
      } else if (fotografUrl instanceof File && tusAdi === "Güncelle") {
        const formData = new FormData();
        formData.append("musteriID", musteriID);
        formData.append("adiSoyadi", adiSoyadi);
        formData.append("tcKimlik", tcKimlik);
        formData.append("telefon", telefon);
        formData.append("adres", adres);
        formData.append("vergiDairesi", vergiDairesi);
        formData.append("email", email);
        formData.append("sakincaliFlag", sakinca);
        formData.append("aktifFlag", aktif);
        formData.append("folder", folder);
        formData.append("image", fotografUrl);
        await uploadImagesByMusteriId({ formData, musteriID }).unwrap();
      } else if (fotografUrl instanceof File && tusAdi === "Ekle") {
        const formData = new FormData();
        formData.append("adiSoyadi", adiSoyadi);
        formData.append("tcKimlik", tcKimlik);
        formData.append("telefon", telefon);
        formData.append("adres", adres);
        formData.append("vergiDairesi", vergiDairesi);
        formData.append("email", email);
        formData.append("sakincaliFlag", sakinca);
        formData.append("aktifFlag", aktif);
        formData.append("folder", folder);
        formData.append("image", fotografUrl);
        await addMusteri(formData).unwrap();
      } else {
        toast.error("Lütfen Gerekli Bilgileri Eksiksiz Doldurunuz.");
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [upInfo, refetch]);

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [addInfo, refetch]);

  useEffect(() => {
    if (upPicInfo && upPicInfo.message) {
      toast(upPicInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [upPicInfo, refetch]);

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [delInfo, refetch]);

  const handleEdit = (customer) => {
    setFormData((prevState) => ({
      ...prevState,
      musteriID: customer.id,
      adiSoyadi: customer.adiSoyadi,
      tcKimlik: customer.tcKimlik,
      telefon: customer.telefon,
      adres: customer.adres,
      vergiDairesi: customer.vergiDairesi,
      email: customer.email,
      sakincaliFlag: customer.sakincaliFlag,
      aktifFlag: customer.aktifFlag,
      fotografUrl: customer.fotografUrl,
    }));
    setSakinca(customer.sakincaliFlag);
    setAktif(customer.aktifFlag);
    setPreviewUrl(SERVERPICT_URL + customer.fotografUrl);
    setTusAdi("Güncelle");
  };

  const handleDelete = async (musteriID) => {
    try {
      await deleteMusteriById(musteriID).unwrap();
      toast.success("Müşteri başarıyla silindi.");
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const resetForm = () => {
    setFormData({
      musteriID: null,
      adiSoyadi: "",
      tcKimlik: "",
      telefon: "",
      adres: "",
      vergiDairesi: "",
      email: "",
      sakincaliFlag: "",
      aktifFlag: "",
      fotografUrl: "",
    });
    setSakinca("");
    setAktif("");
    setPreviewUrl("");
    setTusAdi("Ekle");
  };

  return (
    <section className="musteri">
      {(isLoading || addLoading || upLoading || upPicLoading || delLoading) && (
        <Spinner />
      )}
      <div className="d-flex flex-row justify-content-between">
      <div className="col-3 d-flex flex-row">
          <label className="col-2 mt-1" htmlFor="arama">
            Arama:
          </label>
          <input
            className="form-control"
            type="search"
            name="aramaText"
            value={aramaText}
            placeholder="Arama Yapınız.."
            onChange={(e) => setAramaText(e.target.value)}
          />
        </div>
        <div className="col-9">
          <h2 className="mb-2 text-center">Müşteri Bilgileri</h2>
        </div>
      </div>

      <hr className="my-2" />
      <form className="blog-detail gap-1">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-3">Adı Soyadı</label>
            <div className="col-9">
              <input
                type="text"
                className="form-control"
                name="adiSoyadi"
                value={adiSoyadi}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-3">TC Kimlik</label>
            <div className="col-9">
              <input
                type="text"
                className="form-control"
                name="tcKimlik"
                value={tcKimlik}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-3">Telefon</label>
            <div className="col-9">
              <input
                type="text"
                className="form-control"
                name="telefon"
                value={telefon}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-3">Adres</label>
            <div className="col-9">
              <input
                type="text"
                className="form-control"
                name="adres"
                value={adres}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-3">Vergi D.</label>
            <div className="col-9">
              <input
                type="text"
                className="form-control"
                name="vergiDairesi"
                value={vergiDairesi}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-3 col-md-3 col-4">
              E-Posta
            </label>
            <div className="col-lg-9 col-md-9 col-8">
              <input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-3">Sakıncalı / Aktif</label>
            <div className="col-lg-9 col-md-9 col-8 d-flex flex-row gap-1">
              <select
                id="sakinca"
                value={sakinca}
                onChange={(e) => setSakinca(e.target.value)}
                className="form-select"
              >
                <option value="">Seçiniz</option>
                <option value="0">Sorunsuz Müşteri</option>
                <option value="1">Sakıncalı Müşteri</option>
              </select>
              <select
                id="aktif"
                value={aktif}
                onChange={(e) => setAktif(e.target.value)}
                className="form-select"
              >
                <option value="">Seçiniz</option>
                <option value="1">Aktif Müşteri</option>
                <option value="0">Pasif/Ölü Müşteri</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-3" style={{ marginLeft: "10px" }}>
              Fotoğraf
            </label>
            <div className="col-lg-9 col-md-9 col-8">
              <input
                type="file"
                className="form-control"
                name="fotografUrl"
                onChange={handleInputChange}
                style={{ marginLeft: "-20px" }}
              />
            </div>
          </div>
          <div
            className="d-flex flex-column"
            style={{
              marginLeft: "10px",
              border: "1px solid #000",
              borderRadius: "10px",
            }}
          >
            {previewUrl && (
              <img
                src={previewUrl}
                alt="Preview"
                style={{
                  maxHeight: "240px",
                  maxWidth: "25%",
                  padding: "5px",
                  borderRadius: "10px",
                }}
              />
            )}
          </div>
        </div>
      </form>
      <div className="mb-1 d-flex flex-row gap-1">
        <span className="col-1 mobil_hidden"></span>
        <div className="col-lg-3 col-md-3 col-11 d-flex flex-row mt-2">
          <button
            type="button"
            className="btn btn-primary col-12"
            onClick={handleSubmit}
          >
            {musteriID ? <FaSyncAlt /> : <FaPlus />} {tusAdi}
          </button>
          {musteriID && (
            <button
              type="button"
              className="btn btn-secondary col-12"
              onClick={() => resetForm()}
            >
              <FaBackspace /> Vazgeç
            </button>
          )}
        </div>
      </div>
      <hr className="my-1" />
      <h3 className="mt-1 text-center">Müşteriler Tablosu</h3>
      <div className="responsive-table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style={{ width: "5%" }}>
                No
              </th>
              <th scope="col" style={{ width: "10%" }}>
                İşlemler
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Adı Soyadı
              </th>
              <th scope="col" style={{ width: "10%" }}>
                Telefon
              </th>
              <th scope="col" style={{ width: "35%" }}>
                Adres
              </th>
              <th scope="col" style={{ width: "20%" }}>
                E-Posta Adresi
              </th>
            </tr>
          </thead>
          {musteriTable && musteriTable.length > 0 && (
            <tbody>
              {musteriTable
              .filter((customer) => customer.adiSoyadi.toLocaleLowerCase().includes(aramaText.toLocaleLowerCase()))
              .slice(first, first + rows)
              .map((customer) => (
                <tr key={customer.id}>
                  <td data-label="ID">{customer.id}</td>
                  <td
                    data-label="İşlemler"
                    className="d-flex flex-row gap-2 mobil_right"
                  >
                    <button
                      className="btn btn-warning me-2"
                      onClick={() => handleEdit(customer)}
                    >
                      Düzenle
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(customer.id)}
                    >
                      Sil
                    </button>
                  </td>
                  <td data-label="Adı Soyadı">{customer.adiSoyadi}</td>
                  <td data-label="Telefon">{customer.telefon}</td>
                  <td data-label="Adres">{customer.adres}</td>
                  <td data-label="E-Posta Adresi">{customer.email}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <Paginator
        first={first}
        rows={rows}
        totalRecords={musteriTable ? musteriTable.length : 0}
        onPageChange={onPageChange}
      />
    </section>
  );
};

export default MusteriScreen;
