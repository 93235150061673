import { ISLEM_URL, UPLOAD_URL } from "../constants";
import apiSlice from "../apiSlice";

export const islemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIslemFilterById: builder.query({
      query: (musteriID) => ({
        url: `${ISLEM_URL}/filter/${musteriID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblislem"],
    }),
    addIslem: builder.mutation({
      query: (data) => ({
        url: ISLEM_URL,
        method: "POST",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblislem"],
    }),
    updateIslem: builder.mutation({
      query: (data) => ({
        url: `${ISLEM_URL}/${data.islemID}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblislem"],
    }),
    deleteIslemById: builder.mutation({
      query: (islemID) => ({
        url: `${ISLEM_URL}/${islemID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblislem"],
    }),
    getIslemById: builder.query({
      query: (islemID) => ({
        url: `${ISLEM_URL}/${islemID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblislem"],
    }),
    uploadImagesByIslemId: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetIslemFilterByIdQuery,
  useAddIslemMutation,
  useUpdateIslemMutation,
  useDeleteIslemByIdMutation,
  useGetIslemByIdQuery,
  useUploadImagesByIslemIdMutation,
} = islemApiSlice;
