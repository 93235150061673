import React from "react";
import OutletHeader from "./OutletHeader";
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import logo from "../assets/aabim_logo.png"
import singlePict from "../assets/single.jpg";
import { useSelector } from "react-redux";

const DetailClean = () => {
  const {webData} = useSelector(state => state.clnAuth)
  let footerBilgi= webData && JSON.parse(webData.sabit);

  return (
    <>
      <TopBar />
      <Header />
      <OutletHeader baslik={"Detaylı Araç Yıkama"}/>
      <div className="single">
        <div className="container">
          <div className="blog-detail gap-1">
            <div className="detail-clean">
              <div className="single-content" style={{ width: "95%"}}>
                <img
                  src={singlePict} alt="aabim oto-halı yıkama osmaniye kadirli"
                  style={{ height: "500px", width: "auto" }}
                />
                <h2>Profesyonel Araç Bakımında Lider</h2>
                <p>
                  Yılların deneyimi ve uzman ekibimizle, araç yıkama ve
                  bakımında öncü konumdayız. Her aracı özenle temizlemek ve
                  parlaklık kazandırmak için son teknoloji ekipmanlarımızı
                  kullanıyoruz. İşimizi ciddiyetle yapıyor, müşteri
                  memnuniyetini her şeyin önünde tutuyoruz. Sıradan bir
                  yıkamadan daha fazlasını sunan hizmetimizle aracınızın her
                  detayını özenle temizliyoruz.
                </p>
                <h3>Çevre Dostu Yaklaşım</h3>
                <p>
                  Araç yıkama işlemimizde çevreyi korumaya özen gösteriyoruz.
                  Özel formüle edilmiş, çevre dostu temizlik malzemeleri
                  kullanarak su tüketimini minimumda tutuyoruz. Atık suyun
                  doğaya zarar vermemesi için gerekli filtrasyon sistemlerini
                  kullanarak çevreye duyarlı bir yaklaşım sergiliyoruz. Bu
                  sayede müşterilerimiz sadece araçlarını temizlerken değil,
                  aynı zamanda çevreye de katkıda bulunmuş oluyorlar.
                </p>
                <h4>Geniş Hizmet Yelpazesi</h4>
                <p>
                  Müşteri memnuniyetini ön planda tutarak, geniş bir hizmet
                  yelpazesi sunuyoruz. Sadece dış temizlik değil, iç temizlik,
                  detaylı temizlik, cila ve parlatma gibi farklı seçeneklerle
                  müşterilerimizin ihtiyaçlarına cevap veriyoruz. Her aracın
                  ihtiyacına göre özel çözümler üreterek, müşterilerimizin
                  araçlarını uzun ömürlü ve bakımlı tutmalarını sağlıyoruz.
                </p>
                <h4>Güvenilir ve Hızlı Hizmet</h4>
                <p>
                  Müşterilerimizin zamanını önemsediğimiz için hızlı ve
                  güvenilir bir hizmet sunuyoruz. Randevu sistemimizle
                  müşterilerimiz için uygun zaman dilimlerinde hizmet sunarak,
                  bekleme sürelerini minimumda tutuyoruz. Aynı zamanda işimizi
                  titizlikle yaparak, müşterilerimizin araçlarını güvenle bize
                  teslim edebilecekleri bir ortam sunuyoruz. Kalite ve hızı bir
                  arada sunarak müşteri memnuniyetini en üst seviyede tutmayı
                  hedefliyoruz.
                </p>
              </div>
            </div>

            <div className="detail-side">
              <div className="rightbar">
                <div className="sidebar-widget">
                  <div className="single-bio">
                    <div className="single-bio-img">
                      <img src={logo} alt="aabim oto-halı yıkama osmaniye kadirli"/>
                    </div>
                    <div className="single-bio-text">
                      <h3>{footerBilgi && footerBilgi.firmaAdi && footerBilgi.firmaAdi}</h3>
                      <p>
                      {footerBilgi && footerBilgi.adres && footerBilgi.adres}
                      </p>
                    </div>
                    <div className="single-bio-social">
                      <a className="btn" href="/www.google.com.tr">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="btn" href="/www.google.com.tr">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="btn" href="/www.google.com.tr">
                        <i className="fab fa-youtube"></i>
                      </a>
                      <a className="btn" href="/www.google.com.tr">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a className="btn" href="/www.google.com.tr">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="tag-widget row" style={{ marginLeft: "-5px", width: "100%"}}>
                    <a href="/detail">Detaylı Araç Yıkama</a>
                    <a href="/www.google.com.tr">Buharlı Koltuk Yıkama</a>
                    <a href="/www.google.com.tr">Detaylı Halı-Kilim Yıkama</a>
                    <a href="/www.google.com.tr">Yorgan-Battaniye Yıkama</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DetailClean;
