import React, { useState } from "react";
import BekleyenTable from "./BekleyenTable";
import TeslimEdilenTable from "./TeslimEdilenTable";

const SiparisScreen = () => {
  const [bekleyenTable, setBekleyenTable] = useState(true)
  const [teslimTable, setTeslimTable] = useState(false)

  const durumChange = () => {
    setBekleyenTable(true)
    setTeslimTable(false)
  }

  const teslimChange = () => {
    setBekleyenTable(false)
    setTeslimTable(true)
  }

  return (
    <section>
      <h3 className="text-center">Sipariş İşlemleri</h3>
      <hr className="my-2"/>
      <div className="d-flex flex-row gap-1 mb-2 col-11">
        <button className="btn btn-outline-primary w-100" onClick={() => durumChange()}>Bekleyenler</button>
        <button className="btn btn-outline-success w-100" onClick={() => teslimChange()}>Teslim Edilenler</button>
      </div>
      {bekleyenTable && <BekleyenTable />}
      {teslimTable && <TeslimEdilenTable />}
    </section>
  );
};

export default SiparisScreen;
