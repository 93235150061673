import { ORDER_URL } from "../constants";
import apiSlice from "../apiSlice";

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => ({
        url: `${ORDER_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblorder"],
    }),
    getOrderById: builder.query({
        query: (orderID) => ({
          url: `${ORDER_URL}/${orderID}`,
        }),
        keepUnusedDataFor: 5,
        providesTags: ["tblorder"],
      }),
    addOrder: builder.mutation({
      query: (formData) => ({
        url: `${ORDER_URL}`,
        method: 'POST',
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblorder"],
    }),
    updateOrderById: builder.mutation({
      query: (id) => ({
        url: `${ORDER_URL}/${id}`,
        method: "PUT",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblorder"],
    }),
    deleteOrderById: builder.mutation({
      query: (silID) => ({
        url: `${ORDER_URL}/${silID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblorder"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useAddOrderMutation,
  useUpdateOrderByIdMutation,
  useDeleteOrderByIdMutation,
  useGetOrderByIdQuery,
} = orderApiSlice;

