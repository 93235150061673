import React, { useEffect, useState } from "react";
import IslemKayit from "./IslemKayit";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteIslemByIdMutation, useGetIslemFilterByIdQuery } from "../../slice/redux/islemSlice";
import Spinner from "../../components/Spinner";
import { setAllReloadPage, setReloadIslemPage } from "../../slice/auth/authSlice";
import { Paginator } from 'primereact/paginator';
import { FaBackspace, FaTrashAlt } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { primeReactStyle } from "../PrimeReactUtil";
import { toast } from "react-toastify";

const IslemTable = () => {
  const dispatch = useDispatch();
  const [islemList, setIslemList] = useState([]);
  const { selectedMusteri, reloadIslemPage } = useSelector((state) => state.clnAuth);
  const musteriID = selectedMusteri.id;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(8);

  const onPageChange = (event) => {
      setFirst(event.first);
      setRows(event.rows);
  };

  const { data: islemTable, isLoading, refetch } = useGetIslemFilterByIdQuery(musteriID);
  const [deleteIslemById, {data: delInfo, isLoading: delLoading}] = useDeleteIslemByIdMutation()
  const [silID, setSilID] = useState("");
  const [visible, setVisible] = useState(null);

  useEffect(() => {
    if (islemTable || !islemTable) {
      setIslemList(islemTable);
    }
  }, [islemTable]);

  useEffect(() => {
    refetch();
  }, [musteriID, refetch]);

  useEffect(() => {
    if (selectedMusteri.id !== musteriID) {
      dispatch(setReloadIslemPage(true));
    }
  }, [selectedMusteri, musteriID, dispatch]);

  useEffect(() => {
    if (reloadIslemPage) {
      dispatch(setReloadIslemPage(false));
      refetch();
    }
  }, [reloadIslemPage, refetch, dispatch]);

  const silSorModul = (id) => {
    setSilID(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteIslemById(silID).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
      setVisible(false)
      dispatch(setAllReloadPage(true))
    }
  }, [delInfo, refetch, dispatch]);

  return (
    <section className="islem-kayit-info">
      {(isLoading || delLoading) && <Spinner />}
      <div className="d-flex flex-column gap-2">
        <IslemKayit />
        <div className="col-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>İşlem</th>
                <th style={{ width: "12%" }}>Tarih</th>
                <th style={{ width: "52%" }}>Açıklama</th>
                <th className="text_right" style={{ width: "10%" }}>
                  Adet
                </th>
                <th className="text_right" style={{ width: "10%" }}>
                  Birim Fiyat
                </th>
                <th className="text_right" style={{ width: "13%" }}>
                  Genel Toplam
                </th>
              </tr>
            </thead>
            {islemList && islemList.length > 0 && (
              <tbody>
                {islemList.slice(first, first + rows)
                .map((islem, index) => (
                  <tr key={index}>
                    <td>
                    <button
                          className="btn btn-outline-danger"
                          onClick={() => silSorModul(islem.id)}
                        >
                          <FaTrashAlt />
                        </button>
                    </td>
                    <td>{islem.tarih}</td>
                    <td>{islem.aciklama}</td>
                    <td className="text_right">{islem.adet}</td>
                    <td className="text_right">
                      {Number(islem.birimFiyat).toLocaleString("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                      })}
                    </td>
                    <td className="text_right">
                      {Number(islem.genelToplam).toLocaleString("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          <Paginator first={first} rows={rows} totalRecords={islemList && islemList.length} onPageChange={onPageChange} />
        </div>
      </div>
      <Dialog
        header="Kayıt Silme Onayı"
        visible={visible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu <strong>İşlem Kaydı</strong>{" "}
            Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDelete}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </section>
  );
};

export default IslemTable;
