import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetBakiyeByMusteriIdQuery } from "../../slice/redux/musteriSlice";
import Spinner from "../../components/Spinner";
import { setReloadBorcAlacakPage } from "../../slice/auth/authSlice";

const ToplamBorcTahsilat = ({ customerChange }) => {
  const dispatch = useDispatch();
  const { selectedMusteri, reloadBorcAlacakPage } = useSelector((state) => state.clnAuth);
  let musteriID = selectedMusteri.id;
  const {
    data: borcTable,
    isLoading,
    refetch,
  } = useGetBakiyeByMusteriIdQuery(musteriID);

  const [borc, setBorc] = useState({
    toplamIslem: "",
    toplamTahsilat: "",
    bakiye: "",
  });
  const { toplamIslem, toplamTahsilat, bakiye } = borc;

  useEffect(() => {
    if (borcTable) {
      setBorc({
        toplamIslem: borcTable.toplamIslem,
        toplamTahsilat: borcTable.toplamTahsilat,
        bakiye: borcTable.bakiye,
      });
    }
  }, [borcTable]);

  useEffect(() => {
    if (reloadBorcAlacakPage || customerChange) {
      refetch();
      dispatch(setReloadBorcAlacakPage(false));
    }
  }, [reloadBorcAlacakPage, refetch, dispatch, customerChange]);

  useEffect(() => {
    if (!customerChange) {
      setBorc({
        toplamIslem: "",
        toplamTahsilat: "",
        bakiye: "",
      });
    }
  }, [customerChange]);

  return (
    <>
      {isLoading && <Spinner />}
      {/* {customerChange ? "TRUE" : "FALSE"} */}
      <div className="d-flex flex-row gap-2">
        <div className="col-lg-4 col-md-4 col-0"></div>
        <div className="col-lg-8 col-md-8 col-10 blog-detail gap-2 justify-content-end">
          <div className="col-auto blog-detail gap-1">
            <label htmlFor="" className="col-5 mt-1">
              Borç Toplam
            </label>
            <input
              type="text"
              readOnly
              className="form-control text_right bg-dark text-white"
              value={Number(toplamIslem).toLocaleString("tr-TR", {
                style: "currency",
                currency: "TRY",
              })}
            />
          </div>
          <div className="col-auto blog-detail gap-1">
            <label htmlFor="" className="col-5 mt-1">
              Tahsilat Toplam
            </label>
            <input
              type="text"
              readOnly
              className="form-control text_right bg-dark-subtle"
              value={Number(toplamTahsilat).toLocaleString("tr-TR", {
                style: "currency",
                currency: "TRY",
              })}
            />
          </div>
          <div className="col-auto blog-detail gap-1">
            <label htmlFor="" className="col-5 mt-1">
              Bakiye
            </label>
            <input
              type="text"
              readOnly
              className="form-control text_right bg-success-subtle"
              value={Number(bakiye).toLocaleString("tr-TR", {
                style: "currency",
                currency: "TRY",
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ToplamBorcTahsilat;
