import React from "react";
import { useSelector } from "react-redux";
import LoginPage from "./LoginPage";
import AdminPage from "./admin/AdminPage";
import UserPage from "./user/UserPage";

const AppPage = () => {
  const { user } = useSelector((state) => state.clnAuth);

  return (
    <>
      {user && user.token && user.userMod && user.userMod === 1 ? (
        <AdminPage />
      ) : user && user.userMod && user.userMod === 2 ? (
        <UserPage />
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default AppPage;
