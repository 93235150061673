import React from "react";
import OutletHeader from "./OutletHeader";
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import singlePict from "../assets/makineileyikanirmi.jpg";

const MakineYikama = () => {
  return (
    <>
      <TopBar />
      <Header />
      <OutletHeader baslik={"Her Halı Makine İle Yıkanır mı?"} />
      <div className="container d-flex flex-row gap-1">
        <div className="col-8">
          <h4 className="mt-1">
            Hertürlü halı yıkanır. Evet yanlış duymadınız. Cinsi ne olursa olsun
            her türlü halı temizlenir. Makinalar işimizi kolaylaştırır. Sektörde
            tecrübesi olmayan birçok paralı yatırımcı bunu ağır kayıplarla
            anlayabilmiştir. Halı temizliği ustalık isteyen bir iştir. Çırağı
            olunmayan işin ustası da olunamaz. Yoğun tecrübe isteyen bir iştir.
          </h4>
          <h4 className="mt-4">
            Yukarıda da bahsettiğimiz gibi her halı fabrikada yıkanabilmektedir.
            Ancak her fabrika her halıyı yıkayamaz. AABİM Oto-Halı Yıkama olarak
            2020'lerden günümüze gelen bilgi kaynağımız ile fabrikamızda tüm
            halıları yıkayabiliriz. Bunun için tek yapmanız gereken bizimle
            iletişime geçmenizdir.
          </h4>
          <h4 className="mt-4">
            Sizde Fiyat İste ya da İletişim kısmından da detaylı bilgiye
            ulaşabilirsiniz.
          </h4>
        </div>
        <div className="col-4">
          <img
            src={singlePict}
            alt={singlePict}
            style={{
              height: "350px",
              borderRadius: "10px",
              boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MakineYikama;
