import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SERVERPICT_URL } from "../../slice/constants";
import { useChangePassByUserMutation, useProfileQuery } from "../../slice/auth/userApiSlice";
import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";

const AdminProfilScreen = () => {
  const { user } = useSelector((state) => state.clnAuth);
  const userID = user.userID;
  const { data: userTable, isLoading } = useProfileQuery(userID);
  const [changePassByUser, {data: upInfo, isLoading: upLoading}] = useChangePassByUserMutation()
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordChangeMessage, setPasswordChangeMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [profileUpdateMessage, setProfileUpdateMessage] = useState("");

  const handlePasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordChangeMessage("Yeni şifreler eşleşmiyor.");
      return;
    } 
    let data = {userID, newPassword}
    try {
      await changePassByUser(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      setNewPassword("")
      setCurrentPassword("")
      setConfirmNewPassword("")
    }
  }, [upInfo]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview("");
    }
  };

  const handleProfileUpdate = () => {
    if (!selectedFile) {
      setProfileUpdateMessage("Lütfen bir dosya seçin.");
      return;
    }
    setProfileUpdateMessage("Profil fotoğrafı başarıyla güncellendi.");
  };

  return (
    <>
    {(isLoading || upLoading) && <Spinner />}
      <section className="profile-info">
        {userTable && userTable.id && (
          <div className="profil-info">
            <div className="card-header">
              <h3 className="card-title text-center">
                Kullanıcı Profil Sayfam
              </h3>
              <hr className="my-2" />
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5 col-md-7 text-center">
                  <div className="col-10">
                    {preview ? (
                      <img
                        src={`${preview}`}
                        alt="Profile"
                        className="img-fluid rounded-circle mb-3"
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img
                        src={`${SERVERPICT_URL}${userTable.photoUrl}`}
                        alt="Profile"
                        className="img-fluid rounded-circle mb-3"
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                      />
                    )}

                    <input
                      type="file"
                      className="form-control mb-2"
                      onChange={handleFileChange}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={handleProfileUpdate}
                    >
                      Değiştir ve Güncelle
                    </button>
                  </div>
                  {profileUpdateMessage && (
                    <p className="mt-2">{profileUpdateMessage}</p>
                  )}
                </div>
                <div className="col-lg-7 col-md-7 col-11 mt-2">
                  <h4>{userTable.name}</h4>
                  <p>
                    <strong>Email:</strong> {userTable.email}
                  </p>
                  <p>
                    <strong>Kullanıcı Durumu:</strong>{" "}
                    {userTable.userState === 1 ? "Aktif" : "Pasif"}
                  </p>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-row gap-1">
                <div className="col-lg-4 col-md-6 col-10">
                  <h4 className="text-center">Şifre Değiştirme</h4>
                  <hr className="my-3" />
                  <section>
                    <div className="mb-1 d-flex flex-row gap-1">
                      <div className="col-5">
                        <label htmlFor="currentPassword" className="form-label">
                          Mevcut Şifre
                        </label>
                      </div>
                      <div className="col-7">
                        <input
                          type="password"
                          className="form-control"
                          id="currentPassword"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-1 d-flex flex-row gap-1">
                      <div className="col-5">
                        <label htmlFor="newPassword" className="form-label">
                          Yeni Şifre
                        </label>
                      </div>
                      <div className="col-7">
                        <input
                          type="password"
                          className="form-control"
                          id="newPassword"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-1 d-flex flex-row gap-1">
                      <div className="col-5">
                        <label
                          htmlFor="confirmNewPassword"
                          className="form-label"
                        >
                          Yeni Şifreyi Onaylayın
                        </label>
                      </div>
                      <div className="col-7">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmNewPassword"
                          value={confirmNewPassword}
                          onChange={(e) =>
                            setConfirmNewPassword(e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-1 d-flex flex-row gap-1">
                      <div className="col-5"></div>
                      <div className="col-7">
                        <button
                          className="btn btn-success w-100"
                          onClick={handlePasswordChange}
                        >
                          Şifreyi Değiştir
                        </button>
                        {passwordChangeMessage && (
                          <p className="mt-3">{passwordChangeMessage}</p>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-6"></div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default AdminProfilScreen;
