import React, { useEffect } from "react";
import AdminSideBar from "./AdminSideBar";
 import AdminHeader from "./AdminHeader";
import { Outlet, useLocation } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import { useDispatch } from "react-redux";
//import { sideBarClose } from "../../redux/auth/authSlice";

function AdminPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDefaultComponentNeeded = location.pathname === "/app" && !location.state;
 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
      //  dispatch(sideBarClose(true));
      } else {
      //  dispatch(sideBarClose(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
      <div className="webbody">
         <AdminSideBar />
        <div className="content">
          <main>
            <AdminHeader />
            {isDefaultComponentNeeded ? <AdminDashboard /> : <Outlet />}
          </main>
        </div> 
      </div>
  );
}

export default AdminPage;
