import React from "react";
import DuyuruAddEdit from "./DuyuruAddEdit";
import DuyuruTable from "./DuyuruTable";

const DuyuruPanel = () => {
  return (
    <>
    <hr className="my-2"/>
      <div className="d-flex flex-column gap-1 col-12">
        <div className="d-flex col-12 row">
          <DuyuruAddEdit />
        </div>
        <hr className="my-2 kalin_cizgi"/>
        <div className="d-flex col-12 row">
          <DuyuruTable />
        </div>
      </div>
    </>
  );
};

export default DuyuruPanel;
 