import React from "react";
import { useSelector } from "react-redux"

const TopBar = () => {
  const { webData } = useSelector((state) => state.clnAuth);
  let footerBilgi = webData && JSON.parse(webData.sabit);
  return (
    <div className="top-bar" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="logo">
              <a href="/">
                <h1>
                  AABiM<span>HalıYıkama</span>
                </h1>
              </a>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 d-none d-lg-block">
            <div className="row">
              <div className="col-4">
                <div className="top-bar-item">
                  <div className="top-bar-icon">
                    <i className="far fa-clock"></i>
                  </div>
                  <div className="top-bar-text">
                    <h3>Çalışma Saatleri</h3>
                    <p>{footerBilgi && footerBilgi.calismaSaatleri && footerBilgi.calismaSaatleri}</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="top-bar-item">
                  <div className="top-bar-icon">
                    <i className="fa fa-phone-alt"></i>
                  </div>
                  <div className="top-bar-text">
                    <h3>Bizi Arayın</h3>
                    <p>{footerBilgi && footerBilgi.ceptel && footerBilgi.ceptel}</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="top-bar-item">
                  <div className="top-bar-icon">
                    <i className="far fa-envelope"></i>
                  </div>
                  <div className="top-bar-text">
                    <h3>İletişim</h3>
                    <p>{footerBilgi && footerBilgi.email && footerBilgi.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
