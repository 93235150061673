import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "./App";
import NotFound from "./components/NotFound";
import Main from "./components/Main";
import ResetPassword from "./app/ResetPassword";
import LoginPage from "./app/LoginPage";
import Register from "./app/Register";
import AppPage from "./app/AppPage";
import AdminDashboard from "./app/admin/AdminDashboard";
import MakineYikama from "./components/MakineYikama";
import NeZamanYikanir from "./components/NeZamanYikanir";
import YikamaPeriyot from "./components/YikamaPeriyot";
import KVKKPage from "./components/KVKKPage";
import DetailClean from "./components/DetailClean";
import SepetPage from "./components/SepetPage";
import YikamaScreen from "./app/admin/YikamaScreen";
import MusteriScreen from "./app/admin/MusteriScreen";
import BlogScreen from "./app/admin/BlogScreen";
import SetupScreen from "./app/admin/SetupScreen";
import AdminProfilScreen from "./app/admin/AdminProfilScreen";
import AdminProductsScreen from "./app/admin/AdminProductsScreen";
import IslemScreen from "./app/admin/IslemScreen";
import MusteriYikamaScreen from "./app/user/MusteriYikamaScreen";
import MusteriDashboard from "./app/user/MusteriDashboard";
import MusteriProfilPage from "./app/user/MusteriProfilPage";
import SliderScreen from "./app/admin/SliderScreen";
import BlogDetail from "./components/BlogDetail";
import HizmetScreen from "./app/admin/HizmetScreen";
import SiparisScreen from "./app/admin/SiparisScreen";
import DuyuruPanel from "./app/admin/DuyuruPanel";
import DukkanPage from "./components/DukkanPage";
import RezervasyonPage from "./components/RezervasyonPage";
import RezerveTemizlikPage from "./app/admin/RezerveTemizlikPage";
import TemizlikOrderPage from "./app/admin/TemizlikOrderPage";
import KampanyaAddPage from "./app/admin/KampanyaAddPage";
import SiteMapPage from "./components/SiteMapPage";

export const webRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<Main />} />
      <Route path="blog/:id" element={<BlogDetail />} />
      <Route path="temizlikrezervasyon/:id" element={<RezervasyonPage />} />
      <Route path="detail" element={<DetailClean />} />
      <Route path="nezamanyikanir" element={<NeZamanYikanir />} />
      <Route path="halimakineyikama" element={<MakineYikama />} />
      <Route path="haliyikamaperiyot" element={<YikamaPeriyot />} />
      <Route path="kvkk" element={<KVKKPage />} />
      <Route path="reset" element={<ResetPassword />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<Register />} />
      <Route path="dukkan" element={<DukkanPage />} />
      <Route path="sepet" element={<SepetPage />} />
      <Route path="sitemap" element={<SiteMapPage />} />
      <Route path="/app" element={<AppPage />}>
        <Route path="adash" element={<AdminDashboard />} />
        <Route path="products" element={<AdminProductsScreen />} />
        <Route path="hizmetler" element={<HizmetScreen />} />
        <Route path="yikama" element={<YikamaScreen />} />
        <Route path="siparis" element={<SiparisScreen />} />
        <Route path="duyuru" element={<DuyuruPanel />} />
        <Route path="cari" element={<IslemScreen />} />
        <Route path="musteri" element={<MusteriScreen />} />
        <Route path="blog" element={<BlogScreen />} />
        <Route path="slider" element={<SliderScreen />} />
        <Route path="setup" element={<SetupScreen />} />
        <Route path="aprofil" element={<AdminProfilScreen />} />
        <Route path="mdash" element={<MusteriDashboard />} />
        <Route path="musteriyikama" element={<MusteriYikamaScreen />} />
        <Route path="mprofil/:id" element={<MusteriProfilPage />} />
        <Route path="rezerve" element={<RezerveTemizlikPage />} />
        <Route path="temizlikistekleri" element={<TemizlikOrderPage />} />
        <Route path="kampanya" element={<KampanyaAddPage />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
