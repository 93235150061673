import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/aabimlogo.png";
import { useLoginMutation } from "../slice/auth/userApiSlice";
import { setCredentials } from "../slice/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.clnAuth);
  const [login, { isLoading }] = useLoginMutation();
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const handleLogin = async () => {
    try {
      let data = { email, password, remember };
      const res = await login(data).unwrap();
      dispatch(setCredentials({ ...res, remember }));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    else if (name === "password") setPassword(value);
  };

  if (user && user.token) {
    navigate("/app");
  }

  return (
    <section className="form-signin text-center">
      <form className="form_login">
        <img className="mb-4" src={logo} alt="" width="250" />
        <div className="d-flex flex-row justify-content-between">
        <h1 className="h3 mt-1 mb-2 fw-normal">Giriş Yapınız</h1>
        <button
            className="mb-3 btn btn-outline-success mt-2"
            style={{ height: "38px" }}
            onClick={() => navigate("/")}
          >
            Web Sitesine Dön
          </button>
        </div>
        <div className="form-floating mb-2">
          <input
            type="email"
            className="form-control"
            name="email"
            value={email}
            placeholder="name@example.com"
            onChange={handleChange}
          />
          <label htmlFor="floatingInput">Eposta Adresiniz</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            name="password"
            value={password}
            placeholder="Password"
            onChange={handleChange}
          />
          <label htmlFor="floatingPassword">Şifreniz</label>
        </div>

        <div className="d-flex flex-row gap-1">
          <div className="form-check text-start my-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="remember"
              value={remember}
              id="flexCheckDefault"
              onChange={(e) => setRemember(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Beni Hatırla
            </label>
          </div>
        </div>
        <button
          className="btn btn-primary w-100 py-2"
          type="button"
          onClick={handleLogin}
          disabled={isLoading}
        >
          Giriş
        </button>
        <p className="mt-5 mb-3 text-body-secondary">
          <a
            href="https://www.linkedin.com/in/szaferulgur/"
            rel="noreferrer"
            target="_blank"
          >
            tukanSoft
          </a>{" "}
          &copy; 1996–{currentYear}
        </p>
      </form>
    </section>
  );
};

export default LoginPage;
