import React, { useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import { FaBackspace, FaRegCalendarAlt, FaTrashAlt } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setReloadPage } from "../../slice/auth/authSlice";
import { Calendar } from "primereact/calendar";
import { truncateWord } from "../Utility";
import { primeReactStyle } from "../PrimeReactUtil";
import { useDeleteDuyuruByIdMutation, useGetAllDuyuruQuery, useUpdateDuyuruByIdMutation } from "../../slice/redux/sabitSlice";
import moment from "moment";
import {toast} from "react-toastify"
import Spinner from "../../components/Spinner";

const DuyuruTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const { reloadPage } = useSelector((state) => state.clnAuth);
  const {data: duyuruTable, isLoading, refetch} = useGetAllDuyuruQuery()
  const [deleteDuyuruById, {data: delInfo, isLoading: delLoading}] = useDeleteDuyuruByIdMutation()
  const [updateDuyuruById, {data: upInfo, isLoading: upLoading}] = useUpdateDuyuruByIdMutation()
  const [visible, setVisible] = useState(false);
  const [visibleTarih, setVisibleTarih] = useState(false);
  const [silID, setSilID] = useState("");
  const [dates, setDates] = useState(null);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const silSorModul = (id) => {
    setSilID(id);
    setVisible(true);
  };

  const editModul = (id) => {
    setSilID(id);
    setVisibleTarih(true);
  };

  const kayitSil = async () => {
    try {
      await deleteDuyuruById(silID).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if ((delInfo && delInfo.isDeleted) || reloadPage) {
      toast(delInfo && delInfo.message, { autoClose: 5000 });
      refetch();
      setVisible(false);
      dispatch(setReloadPage(false));
    }
  }, [delInfo, refetch, dispatch, reloadPage]);

  const kayitGuncelle = async () => {
    let basTarih = moment(dates[0]).format("YYYY.MM.DD");
    let bitTarih = moment(dates[1]).format("YYYY.MM.DD");
    let data = { basTarih, bitTarih, silID };
    try {
      await updateDuyuruById(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if ((upInfo && upInfo.isUpdate) || reloadPage) {
      toast(upInfo && upInfo.message, { autoClose: 5000 });
      refetch();
      setVisibleTarih(false);
      dispatch(setReloadPage(false));
    }
  }, [upInfo, refetch, dispatch, reloadPage]);

  useEffect(() => {
    if(reloadPage) {
      refetch()
      dispatch(setReloadPage(false))
    }
  },[reloadPage, refetch, dispatch])

  return (
    <>
    {(isLoading || delLoading || upLoading) && <Spinner />}
      <section className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>İşlem</th>
              <th>Baş.Tarihi</th>
              <th>Bit.Tarihi</th>
              <th>HTML Metin</th>
            </tr>
          </thead>
          {duyuruTable && duyuruTable.length > 0 && (
            <tbody>
              {duyuruTable.slice(first, first + rows).map((duyuru, index) => (
                <tr key={index}>
                  <td>{duyuru.id}</td>
                  <td className="d-flex flex-row gap-2">
                    <button
                      className="btn btn-outline-danger btn-table"
                      onClick={() => silSorModul(duyuru.id)}
                    >
                      <sup>
                        <FaTrashAlt />
                      </sup>
                    </button>
                    <button
                      className="btn btn-outline-primary btn-table"
                      onClick={() => editModul(duyuru.id)}
                    >
                      <sup>
                        <FaRegCalendarAlt />
                      </sup>
                    </button>
                  </td>
                  <td>{moment(duyuru.baslangicTarihi).format("YYYY.MM.DD")}</td>
                  <td>{moment(duyuru.bitisTarihi).format("YYYY.MM.DD")}</td>
                  <td>{truncateWord(duyuru.icerik, 60)}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className="card mt-2">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={duyuruTable && duyuruTable.length}
            onPageChange={onPageChange}
          />
        </div>
      </section>
      <Dialog
        header={t("tablo.kayitSilmeOnayi")}
        visible={visible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu Duyuru Kaydı Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={() => kayitSil()}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
      <Dialog
        header={t("tablo.tarih") + " " + t("tablo.duzenle")}
        visible={visibleTarih}
        headerStyle={primeReactStyle.editHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setVisibleTarih(false)}
      >
        <p className="m-0 text-center justify-content-center">
          <div className="d-flex flex-column gap-2 mb-2">
            <div>
              <strong>{silID}</strong> Nolu Duyuru Kaydı Güncellenecektir. Onaylıyor
              musunuz?
            </div>
            <div>
              <label htmlFor="tarih">Güncelleme Tarih Aralığı : </label>
              <Calendar
                className="col-4"
                value={dates}
                onChange={(e) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
              />
            </div>
          </div>
          <div className="d-flex flex-row gap-1 mt-3">
            <button
              className="btn btn-primary w-100"
              onClick={() => kayitGuncelle()}
            >
              <FaTrashAlt /> Güncelle
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setVisibleTarih(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </>
  );
};

export default DuyuruTable;
