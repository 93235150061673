import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaSignInAlt } from "react-icons/fa";
import logo from "../assets/aabim_logo.png";
import { BsCart4 } from "react-icons/bs";
const Header = () => {
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const { clnSepet } = useSelector((state) => state.clnAuth);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".nav-bar");
      const pageHeader = document.querySelector(".page-header");

      if (window.scrollY > 90) {
        navbar.classList.add("nav-sticky");
        if (pageHeader) pageHeader.style.marginTop = "73px";
      } else {
        navbar.classList.remove("nav-sticky");
        if (pageHeader) pageHeader.style.marginTop = "0";
      }
    };

    const handleResize = () => {
      const dropdowns = document.querySelectorAll(".navbar .dropdown");

      if (window.innerWidth > 992) {
        dropdowns.forEach((dropdown) => {
          dropdown.addEventListener("mouseover", handleMouseOver);
          dropdown.addEventListener("mouseout", handleMouseOut);
        });
      } else {
        dropdowns.forEach((dropdown) => {
          dropdown.removeEventListener("mouseover", handleMouseOver);
          dropdown.removeEventListener("mouseout", handleMouseOut);
        });
      }
    };

    const handleMouseOver = (event) => {
      const dropdownToggle =
        event.currentTarget.querySelector(".dropdown-toggle");
      if (dropdownToggle) dropdownToggle.click();
    };

    const handleMouseOut = (event) => {
      const dropdownToggle =
        event.currentTarget.querySelector(".dropdown-toggle");
      if (dropdownToggle) {
        dropdownToggle.click();
        dropdownToggle.blur();
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize(); // Call initially to set up event listeners based on initial window size

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const rotaKontrol = () => {
    if (pathName !== "/") {
      navigate("/");
    }
    if (miniShow === "show") {
      setMiniShow("");
    }
  };

  const [miniShow, setMiniShow] = useState("");

  const miniShowToggle = () => {
    if (miniShow === "") {
      setMiniShow("show");
    } else {
      setMiniShow("");
    }
  };

  return (
    <div className="nav-bar">
      <div className="container">
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
          <Link to="/">
            <img src={logo} alt={logo} style={{ height: "60px" }} />
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            onClick={miniShowToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse justify-content-between ${miniShow}`}
            id="navbarCollapse"
          >
            <div className="navbar-nav mr-auto">
              <a
                href="#home"
                className="nav-item nav-link active"
                onClick={() => rotaKontrol()}
              >
                Ana Sayfa
              </a>
              <a
                href="#team"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                Hakkımızda
              </a>
              <a
                href="#service"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                Servis
              </a>
              <a
                href="#price"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                Ücretler
              </a>
              <a
                href="#location"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                Hizmet Noktaları
              </a>
              <a
                href="/detail"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                Detaylı Yıkama
              </a>
              <Link
                to="/dukkan"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                Ürünler
              </Link>
              <a
                href="#orderpage"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                Ön Sipariş
              </a>
              <a
                href="#footer"
                className="nav-item nav-link"
                onClick={() => rotaKontrol()}
              >
                İletişim
              </a>
            </div>
            <div className="d-flex flex-row gap-1 ml-auto">
              <a
                href="/sepet"
                className={`mt-1 cursor-pointer ${
                  clnSepet && clnSepet.length > 0 ? "cart-icon" : ""
                }`}
                onClick={() => rotaKontrol()}
              >
                <BsCart4  size="38px" />
              </a>
              <Link className="btn btn-custom" to="/login">
                <FaSignInAlt />
                  Üye Giriş
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
