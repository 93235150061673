import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "./constants";

import { logout } from "./auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER_URL,
});

async function baseQueryWithAuth(args, api, extra) {
  const { getState } = api;
  const state = getState();
  if (state.clnAuth && state.clnAuth.user && state.clnAuth.user.token) {
    args.headers = {
      ...args.headers,
      "token": state.clnAuth.user.token,
    };
  }
  const result = await baseQuery(args, api, extra);
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
}

const apiSlice = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    "tblusers",
    "tblmusteri",
    "tblblog",
    "tblblogdetail",
    "tblcarousel",
    "tblhaber",
    "tblislem",
    "tblprice",
    "tblsabit",
    "tblstok",
    "tbltahsilat",
    "tblusergroup",
    "tblyikamaadres",
    "tblsiparis",
    "tblduyuru",
    "tblmusteritemizlik"
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
