import React from "react";
import OutletHeader from "./OutletHeader";
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";

const KVKKPage = () => {
  return (
    <>
      <TopBar />
      <Header />
      <OutletHeader baslik={"KVKK - Gizlilik Bildirimi"} />
      <div className="container d-flex flex-row gap-1">
        <div className="col-12">
          <h1>Web Sitesi Gizlilik Sözleşmesi</h1>
          <p>
            Bu internet sitesini ziyaret etmeniz ve bu site aracılığıyla sunulan
            bilgi / hizmetlerden yararlanmanız sırasında, size ve talep
            ettiğiniz bilgi / hizmetlere ilişkin elde ettiğimiz bilgilerin ne
            şekilde kullanılacağı ve korunacağı, işbu "Gizlilik Politikası"nda
            belirtilen şartlara tabidir. Bu internet sitesini ziyaret etmekle ve
            bu internet sitesi aracılığıyla sunduğumuz hizmetlerden yararlanmayı
            talep etmekle işbu "Gizlilik Politikası"nda belirtilen şartları
            kabul etmektesiniz.
          </p>
          <h4>
            I. Kişisel verilerin korunması ve işlenmesi politikasının amacı
          </h4>
          <p>
            Bugüne kadar AABİM Oto-Halı Yıkama Fabrikası olarak verdiğimiz bilgi
            ve hizmetlerin hassasiyeti gereğince müşterilerimizden ya da müşteri
            adaylarımızdan gelen veriler gizli tutulmuş ve üçüncü kişilerle
            paylaşılmamıştır. Kişisel verilerin korunması, şirketimizin temel
            politikasıdır. Herhangi bir yasal düzenleme olmadan önce şirket ve
            iştiraklerimiz, kişisel verilerin gizliliğine önem vermiş ve bunu
            bir çalışma ilkesi olarak benimsemiştir, ayrıca çalışanların da bu
            ilke doğrultusunda çalışma talimatları bulunmaktadır. Kişisel
            Verilerin Korunması Kanunu’nun getirdiği bütün sorumluluklara uymayı
            da AABİM Oto-Halı Yıkama Fabrikası olarak taahhüt ederiz. AABİM
            Oto-Halı Yıkama Fabrikası kişisel verilerin korunmasına ilişkin
            prensipleri, iştiraklerimizi de kapsamaktadır.
          </p>
          <h4>
            II. Kişisel verilerin korunması ve işlenmesi politikasının kapsamı
            ve değiştirilmesi
          </h4>
          <p>
            AABİM Oto-Halı Yıkama Fabrikası tarafından hazırlanan bu politika,
            6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“KVKK”) uygun
            olarak hazırlanmıştır. Sizlerden rızanızla ya da Kanunda sayılan
            diğer hukuka uygunluk gereği elde edilmiş veriler, sunmuş olduğumuz
            hizmetlerin daha kaliteli hale getirilmesi, sizlere sunulan
            hizmetlerin ve kalite politikamızın iyileştirilmesi amacıyla
            kullanılacaktır. Yine elimizdeki bazı veriler ise, kişisel olmaktan
            çıkarılmakta ve anonimleştirilmektedir. Bu veriler, istatistiki
            amaçlarla kullanılan verilerdir ve Kanun uygulamasına ve
            Politikamıza tabi değildir. AABİM Oto-Halı Yıkama Fabrikası Kişisel
            Verilerin Korunması ve İşlenmesi Politikası”, müşterilerimizin,
            müşteri adaylarımızın, çalışanlarımız ile bizimle çözüm ortaklığı
            içinde çalışan şirketlerin müşterileri ve çalışanlarının ya da diğer
            kişilerin otomatik olarak elde edilen verilerinin korunmasını
            amaçlar ve bunlara ilişkin düzenlemeleri içerir. AABİM Oto-Halı
            Yıkama Fabrikası politikamızı ve Yönetmeliğimizi, Kanuna uygun olmak
            ve kişisel verilerin daha iyi korunması şartı ile- değiştirme
            hakkına sahiptir.
          </p>
          <h4>III. Kişisel verilerin işlenmesi ile ilgili temel kurallar</h4>
          <p>
            a) Hukuka ve dürüstlük kurallarına uygun olma: “AABİM Oto-Halı
            Yıkama Fabrikası”, topladığı ya da kendisine diğer şirketlerden
            gelen verilerin kaynağını sorgular ve bunların hukuka uygun ve
            dürüstlük kuralları çerçevesinde elde edilmesine önem verir. Bu
            çerçevede “AABİM Oto-Halı Yıkama Fabrikası” in sunduğu hizmetleri
            satan üçüncü taraflara verilerin korunması amacıyla gerekli uyarı ve
            bildirimleri yapar.
          </p>
          <p>
            b) Doğru ve gerektiğinde güncel olma: “AABİM Oto-Halı Yıkama
            Fabrikası”, bünyesinde bulunan bütün verilerin doğru bilgi olmasına,
            yanlış bilgi içermemesine ve nihayet kişisel verilerde değişiklik
            olduğu takdirde bunları kendisine iletildiği takdirde güncellemeye
            önem verir.
          </p>
          <p>
            c) Belirli, açık ve meşru amaçlar için işlenme: “AABİM Oto-Halı
            Yıkama Fabrikası”, ancak sunduğu ve hizmet sırasında kişilerden
            onayını aldığı verileri işler. İş amacı dışında verileri işlemez,
            kullanmaz ve kullandırmaz.
          </p>
          <p>
            d) İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma: “AABİM
            Oto-Halı Yıkama Fabrikası”, sadece verileri işlendikleri amaçla
            sınırlı ve hizmetin gerektirdiği ölçüde kullanır.
          </p>
          <p>
            e) İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli
            olan süre kadar muhafaza edilme: “AABİM Oto-Halı Yıkama Fabrikası”,
            sözleşmeler kaynaklı verileri Kanunun ihtilaf çıkma süreleri,
            ticaret ve vergi hukukunun gereklilikleri kadar bünyesinde muhafaza
            eder. Buna karşın bu amaçlar ortadan kalktığında veriyi siler ya da
            anonimleştirir.
          </p>
          <h4>Azami Tasarruf İlkesi/Cimrilik İlkesi</h4>
          <p>
            Azami tasarruf ilkesi ya da cimrilik ilkesi adı verilen bu ilkemize
            göre “AABİM Oto-Halı Yıkama Fabrikası” e ulaşan veriler, ancak
            gerekli olduğu kadar sisteme işlenir. Bu nedenle hangi verileri
            toplayacağımız amaca göre belirlenir. Gerekli olmayan veriler
            toplanmaz. AABİM Oto-Halı Yıkama Fabrikası intikal eden diğer
            veriler de aynı şekilde AABİM Oto-Halı Yıkama Fabrikası bilişim
            sistemlerine aktarılır.
          </p>
          <h4>Kişisel verilerin silinmesi</h4>
          <p>
            Kanunen saklanması gereken sürelerin dolması, yargı süreçlerinin
            tamamlanması ya da diğer gereklilikler ortadan kalktığında
            şirketimiz tarafından bu veriler kendiliğinden ya da ilgili kişinin
            talebi üzerine kişisel veriler silinir, yok edilir ya da anonim hale
            getirilir.
          </p>
          <h4>Doğruluk ve veri güncelliği</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası” bünyesinde bulunan veriler, kural
            olarak ilgili kişilerin beyanı üzerine beyan ettiği şekilde işlenir.
            “AABİM Oto-Halı Yıkama Fabrikası”, müşteriler ya da “AABİM Oto-Halı
            Yıkama Fabrikası” ile temas kuran kişilerin beyan ettiği verilerin
            doğruluğunu araştırmak zorunda olmadığı gibi bu hukuken ve çalışma
            ilkelerimiz nedeniyle de yapılmaz. Beyan edilen veriler, doğru kabul
            edilir. Kişisel verilerin doğruluğu ve güncelliği ilkesi “AABİM
            Oto-Halı Yıkama Fabrikası” tarafından da benimsenmiştir.
            Şirketimizin kendisine ulaşan resmî belgelerden veya ilgilisinin
            talebi üzerine işlemiş olduğu kişisel verileri günceller. Bunun için
            gerekli önlemleri alır.
          </p>
          <h4>Gizlilik ve veri güvenliği</h4>
          <p>
            Kişisel veriler gizlidir ve “AABİM Oto-Halı Yıkama Fabrikası” de bu
            gizliliğe riayet etmektedir. Kişisel verilere şirket içinde ancak
            yetki verilmiş kişiler ulaşabilir. “AABİM Oto-Halı Yıkama Fabrikası”
            tarafından toplanan kişisel verilerin korunması ve yetkisiz
            kişilerin eline geçmemesi ve müşterilerimizin ve müşteri
            adaylarımızın mağdur olmaması için gerekli teknik ve idari bütün
            tedbirler alınmaktadır. Bu çerçevede yazılımların standartlara uygun
            olması, üçüncü partilerin özenle seçilmesi ve şirket içinde de veri
            koruma politikasına riayet edilmesi sağlanmaktadır.
          </p>
          <h4>IV. Veri işleme amaçlar</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası” in kişisel verileri toplaması ve
            işlemesi aydınlatma metninde belirtilen amaçlar doğrultusunda icra
            edilecektir. Veriler, sözleşmenin kurulması ve müşterilere daha iyi
            hizmet sağlanması amacıyla toplanmakta ve işlenmektedir.
          </p>
          <h4>V. Müşteri, muhtemel müşteri ve iş ve çözüm ortakları veris</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası” olarak kişisel verilerinizi, 6698
            sayılı Kişisel Verilerin Korunması Kanunu ve ilgili sair mevzuat
            kapsamında, veri sorumlusu sıfatı ile işlemekteyiz. Bu kapsamda
            işlenecek kişisel veri kategori ve açıklamaları aşağıdaki gibidir:
            Adı Soyadı, Adres, Telefon Numarası, E-Posta Adresi.
            <p>
              - Müşteri yorum, geri bildirim ve şikâyet verileri: Yorum, görüş
              ve şikayetler.
            </p>
            <p>
              - Diğer: Yarışma, çekiliş veya pazarlama programlarına katılım,
              internet sitesi üyelik bilgileri.
            </p>
          </p>
          <h4>Sözleşme ilişkisi için verinin toplanması ve işlenmesi</h4>
          <p>
            Müşterilerimiz ve muhtemel müşterilerimizle bir sözleşme ilişkisi
            kurulmuş ise, toplanmış olan kişisel veriler, müşterinin onayı
            alınmaksızın kullanılabilir. Ancak bu kullanım, sözleşme amacı
            doğrultusunda gerçekleşir. Sözleşmenin daha iyi icrası ve hizmetin
            gereklilikleri ölçüsünde veriler kullanılır ve gerektiğinde
            müşterilerle irtibata geçilerek güncellenir. Buna karşın müşteri
            adaylarımızın (muhtemel müşteri) bize kendileri tarafından bırakmış
            olduğu veriler, onlara sonrasında daha kolay ve kaliteli hizmet
            sunmak için işlenir. Bu veriler talepleri halinde bir sözleşme
            ilişkisine dönüşmemişse silinir.
          </p>
          <h4>İş ve Çözüm Ortakları Verileri</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası”, gerek iş gerekse çözüm ortakları
            ile veri paylaşımı yaparken hukuka uygun davranmayı ilke edinir. İş
            ve çözüm ortakları ile veri gizliliği taahhüdü ile ve ancak hizmetin
            gerektirdiği kadar veri paylaşılmakta ve bu taraflardan mutlaka veri
            güvenliğinin alınmasına ilişkin tedbirleri almaya zorlanmaktadır.
          </p>
          <h4>Reklam amaçlı veri işleme</h4>
          <p>
            E-Ticaretin Düzenlenmesi Hk. Kanun ile Ticari İletişim ve Ticari
            Elektronik İletiler Hk. Yönetmeliğe uygun olarak ancak önceden onay
            alınan kişilere reklam amaçlı elektronik ileti gönderilebilir.
            Reklamın gönderileceği kişinin onayının açık bir şekilde mevcudiyeti
            şarttır. Yine aynı mevzuat uyarınca belirlenen “onay”ın detaylarına
            “AABİM Oto-Halı Yıkama Fabrikası”,” riayet etmektedir. Alınacak
            onay, şirketinizin mal ve hizmetlerini tanıtmak, pazarlamak,
            işletmesini tanıtmak ya da kutlama ve temenni gibi içeriklerle
            tanınırlığını artırmak amacıyla alıcıların elektronik iletişim
            adreslerine gönderdiği tüm ticari elektronik iletileri kapsamalıdır.
            Bu onay, yazılı olarak fiziki ortamda veya her türlü elektronik
            iletişim aracıyla alınabilir. Önemli olan, alıcının ticari
            elektronik ileti gönderilmesini kabul ettiğine dair olumlu irade
            beyanı, adı ve soyadı ile elektronik iletişim adresinin
            bulunmasıdır.
          </p>
          <p>
            Şirketin hukuki yükümlülüğü veya kanunda açıkça öngörülmesi
            sebebiyle yapılan veri işlemleri Kişisel veriler, işlemenin ilgili
            mevzuatta açıkça belirtilmesi veya mevzuatla belirlenen bir hukuki
            yükümlülüğün yerine getirilmesi amacıyla ayrıca onay alınmadan
            işlenebilir. Veri işlemlerinin tür ve kapsamı, yasal olarak izin
            verilen veri işleme faaliyeti için gerekli olmalı ve ilgili yasal
            hükümlere uygun olmalıdır.
          </p>
          <h4>Şirketin veri işlemesi</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası sunduğu bilgi / hizmet ve meşru
            amaçları doğrultusunda kişisel veriler işlenebilir. Ancak veriler
            hiçbir şekilde hukuka aykırı hizmetler için kullanılamaz.
          </p>
          <h4>Özel nitelikli verilerin işlenmesi</h4>
          <p>
            Kanun’a göre Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi
            inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti,
            dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza
            mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik
            ve genetik verileri özel nitelikli kişisel veridir. “AABİM Oto-Halı
            Yıkama Fabrikası” ayrıca, özel nitelikli kişisel verilerin
            işlenmesinde, ayrıca Kurul tarafından belirlenen yeterli önlemleri
            alır. “AABİM Oto-Halı Yıkama Fabrikası”, hizmetlerin daha iyi
            verilebilmesi için kişilerin onayı ile özel nitelikli verileri ancak
            toplandıkları amaç için işleyebilir.
          </p>
          <h4>Otomatik sistemlerle işlenen veriler</h4>
          <p>
            Otomatik sistemler aracılığı ile işlenen veriler konusunda ““AABİM
            Oto-Halı Yıkama Fabrikası”, Kanuna uygun davranır. Kişilerin açık
            rızası olmaksızın bu verilerden elde edilen bilgiler kişi aleyhine
            kullanılamaz. Ancak ““AABİM Oto-Halı Yıkama Fabrikası”, kendi
            sistemindeki verileri kullanarak işlem yapacağı kişilerle ilgili
            kararlar alabilir.
          </p>
          <h4>Kullanıcı bilgileri ve internet</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası” e ait web siteleri ve diğer
            sistemlerde veya uygulamalarda kişisel verilerin toplanması,
            işlenmesi ve kullanılması durumunda ilgili kişiler gizlilik
            bildirimi ile ve gerekirse çerezler hakkında bilgilendirilir.
            Kişiler, web sayfalarındaki uygulamalarımız konusunda
            bilgilendirilir. Kişisel veriler, hukuka uygun olarak işlenecektir.
            Web sayfamızı ziyaret ettiğinizde sayfamızda
            kullandığımız/kullanacağımız çerezlerle ile ilgili olarak aşağıdaki
            bilgileri dikkatinize sunmaktayız.
          </p>
          <p>
            Otomatik sistemlerle işlenen veriler Çalışanlarla ilgili olarak
            otomatik sistemlerle ilgili olarak işlenen veriler, şirket içi
            terfilerde ve performans değerlendirmelerinde kullanılabilir.
            Çalışanlarımız aleyhine çıkan sonuca itiraz etme hakkına sahiptir ve
            bunu şirket içi prosedürlere uyarak gerçekleştirirler. Çalışanların
            itirazları da yine şirket içinde değerlendirilir.
          </p>
          <h4>Google (Analytics, Doubleclick)</h4>
          <p>
            Çerezleri Kullanım Amacı: Site istatistiklerini tutma, ölçümleme
            reklam site içi iyileştirme Çerez Türü: İşlevsel ve analitik
            çerezler ticari çerezler.
          </p>
          <h4>Site</h4>
          <p>
            Çerezleri Kullanım Amacı: Geri Bildirim, iletişim formları, müşteri
            görüşme takibi. Çerez Türü: İşlevsel ve analitik çerezler.
          </p>
          <h4>İşlevsel ve Analitik</h4>
          <p>
            Çerezler tercihlerinizi hatırlamak, internet sitesinin etkin şekilde
            kullanılması, sitenin kullanıcı isteklerine cevap verecek şekilde
            optimize edilmesi ve ziyaretçilerin siteyi nasıl kullandığı hakkında
            verileri içerir. Niteliği gereği bu türdeki çerezler kullanıcı adı
            vb. kişisel bilgilerinizi içerebilir.
          </p>
          <h4>Üçüncü Taraf Çerezler</h4>
          <p>
            AABİM Oto-Halı Yıkama Fabrikası internet siteleri/mobil
            uygulamaları/mobil internet siteleri üçüncü parti güvenilir,
            tanınmış reklam sağlayıcılarıyla çalışmaktadır. Üçüncü parti hizmet
            sağlayıcılar size özel reklamları sunabilmek için kendi çerezlerini
            yerleştirmektedirler. Üçüncü parti tarafından yerleştirilen çerezler
            internet sitelerinde ziyaretçilerin gezinme bilgilerini toplar,
            işler ve nasıl kullandıklarını analiz eder.
          </p>
          <h4>Ticari çerezler</h4>
          <p>
            İlgi alanlarınız ve seçimleriniz doğrultusunda hedeflediğiniz
            ürün/içeriğe benzer nitelikli olanların sunulması ve daha gelişmiş,
            kişiselleştirilmiş bir reklam portföyü sunarak kullanım deneyiminizi
            artırmaya yarar. Yukarıda ifade edilen oturum, kalıcı, işlevsel ve
            analitik ve ticari çerezlerin arka planda tutulma süresi yaklaşık 2
            (iki) ay olup şahıs internet tarayıcısı ayarlarından bunda gerekli
            ayarlamaları yapılabilir. İşbu ayarlardan kaldırılma işlemi internet
            tarayıcısı bazlı değişebilmektedir.
          </p>
          <h4>Çerezleri nasıl silebilirim?</h4>
          <p>
            Pek çok internet tarayıcısı, bilgisayarınıza ilk kurulumundan
            itibaren çerezleri otomatik olarak kabul etmeye ve kullanmaya
            ayarlıdır. İnternet tarayıcınızın yardım ya da ayarlar menülerini
            kullanarak, çerezlerin engellenmesini veya cihazınıza çerez
            gönderildiğinde uyarı verilmesini sağlayabilirsiniz. Çerezleri
            yönetmenin farklı yollarını öğrenmek ve kullandığınız tarayıcının
            ayarlarını nasıl düzenleyeceğiniz hakkında ayrıntılı bilgi almak
            için tarayıcınızın talimat veya yardım seçenekleri ekranından
            yararlanabilirsiniz.
          </p>
          <h4>VII. Kişisel verilerin yurt içi ve dışına aktarılması</h4>
          <p>
            Kişisel veriler, “AABİM Oto-Halı Yıkama Fabrikası” tarafından
            hizmetin görülebilmesi amacıyla iş ve çözüm ortakları ile
            paylaşılabilir.
          </p>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası”, kişisel verileri aşağıda
            belirtilen kişi ve kurumlara belirli amaçlarla aktarabilecektir; »
            İş ortaklığının kurulma amaçlarının yerine getirilmesini temin etmek
            amacıyla sınırlı olarak “AABİM Oto-Halı Yıkama Fabrikası” in iş
            ortaklarına, » Şirketimizin tedarikçiden dış kaynaklı olarak temin
            ettiği ve AABİM Oto-Halı Yıkama Fabrikası ticari faaliyetlerini
            yerine getirmek için gerekli hizmetlerin Şirketimize sunulmasını
            sağlamak amacıyla sınırlı olarak “AABİM Oto-Halı Yıkama Fabrikası”
            in tedarikçilerine, » Şirketimizin iştiraklerin de katılımını
            gerektiren ticari faaliyetlerinin yürütülmesini temin etmekle
            sınırlı olarak “AABİM Oto-Halı Yıkama Fabrikası” in çözüm
            ortaklarına, “AABİM Oto-Halı Yıkama Fabrikası”, kanunda Kurul
            tarafından belirlenen şartlar dahilinde kişisel verileri Kanundaki
            diğer şartlara uygun olarak ve kişinin onayına bağlı olarak yurt içi
            ve yurt dışına aktarma yetkisine sahiptir.
          </p>
          <h4>VIII. İlgili kişinin hakları</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası”, Kanun kapsamında ilgili kişinin
            veri işlenmeden önce onayını alma hakkının olduğunu, verinin
            işlenmesinden sonra ise verisinin kaderini tayin etme hakkına sahip
            olduğunu kabul etmektedir.
          </p>
          <h4>
            “AABİM Oto-Halı Yıkama Fabrikası” tarafından internet sitemizde
            duyurulan ilgilimize başvurarak kişisel verilerle ilgili olarak;
          </h4>
          <p>a) Kişisel verilerinin işlenip işlenmediğini öğrenme,</p>
          <p>b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
          <p>
            c) Kişisel verilerin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme,
          </p>
          <p>
            ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
            üçüncü kişileri bilme,
          </p>
          <p>
            d) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
            bunların düzeltilmesini isteme,
          </p>
          <p>
            e) 7 nci maddede öngörülen şartlar çerçevesinde kişisel verilerin
            silinmesini veya yok edilmesini isteme,
          </p>
          <p>
            f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel
            verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
          </p>
          <p>
            g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
            analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
            ortaya çıkmasına itiraz etme,
          </p>
          <p>
            ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
            uğraması hâlinde zararın giderilmesini talep etme, hakkına sahiptir.
          </p>
          <p>
            Buna karşın, Şirket içinde anonimleştirilmiş verilerle ilgili olarak
            kişilerin bir hakkı bulunmamaktadır. “AABİM Oto-Halı Yıkama
            Fabrikası”, kişisel verileri, iş ve sözleşme ilişkisi gereğince, bir
            yargısal görevin ya da devlet otoritesinin Kanuni yetkilerini
            kullanması amacıyla ilgili kurum ve kuruluşlarca paylaşabilir.
          </p>
          <p>
            Kişisel veri sahipleri yukarıda belirtilen haklarına ilişkin
            taleplerini AABİM Oto-Halı Yıkama Fabrikası resmi internet adresinde
            bulunan iletişim formunu eksiksiz doldurarak ve ıslak imza ile
            imzalayarak aşağıda belirtilmiş iletişim adresine, iadeli taahhütlü
            mektupla ve kimlik fotokopileriyle (nüfus cüzdanı için sadece ön yüz
            fotokopisi olacak şekilde) göndererek iletebileceklerdir.
            Başvurularınız, başvurunuzun içeriğine göre en kısa sürede ya da
            şirketimize ulaşmasından sonra en geç 30 gün içinde cevaplanacaktır.
            Başvurularınızı, iadeli taahhütlü mektupla yapmanız gerekmektedir.
            Ayrıca başvurularınızın sadece sizlerle ilgili kısmı cevaplanacak
            olup, eşiniz, yakınınız ya da arkadaşınız hakkında yapılan bir
            başvuru kabul edilmeyecektir. “AABİM Oto-Halı Yıkama Fabrikası”,
            başvuru sahiplerinden başkaca ilgili bilgi ve belge talep edebilir.
          </p>
          <h4>IX. Gizlilik İlkesi</h4>
          <p>
            İster çalışanlar isterse diğer kişilerin “AABİM Oto-Halı Yıkama
            Fabrikası” deki verileri gizlidir. Hiç kimse sözleşme ya da kanuna
            uygunluk olmaksızın başkaca hiçbir amaç için bu verileri kullanamaz,
            kopyalayamaz, çoğaltamaz, başkalarına aktaramaz, iş amaçları dışında
            kullanamaz.
          </p>
          <h4>X. İşlem güvenliği</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası” tarafından toplanan kişisel
            verilerin korunması ve yetkisiz kişilerin eline geçmemesi ve
            müşterilerimizin ve müşteri adaylarımızın mağdur olmaması için
            gerekli teknik ve idari bütün tedbirler alınmaktadır. Bu çerçevede
            yazılımların standartlara uygun olması, üçüncü partilerin özenle
            seçilmesi ve şirket içinde de veri koruma politikasına riayet
            edilmesi sağlanmaktadır. Güvenliğe ilişkin önlemler, sürekli olarak
            yenilenmekte ve geliştirilmektedir.
          </p>
          <h4>XI. Denetim</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası”, kişisel verilerin korunması
            konusunda gerekli iç ve dış denetimleri yaptırır.
          </p>
          <h4>XII. İhlallerin Bildirimi</h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası”,”, kişisel verilerle ilgili
            herhangi bir ihlal olduğu kendisine bildirildiğinde söz konusu
            ihlali gidermek için derhal harekete geçer. İlgilinin zararını en
            aza indirir ve zararı telafi eder. Kişisel verilerin dışarıdan
            yetkisiz kimselerce ele geçirildiğinde durumu derhal Kişisel
            Verileri Koruma Kurulu’na bildirir.
          </p>
          <p>
            İhlallerin bildirimi ile ilgili AABİM Oto-Halı Yıkama Fabrikası”
            internet sitesindeki iletişim bölümünden belirtilen usullere göre de
            başvurular yapılabilir.
          </p>
          <h4>
            Kişisel Verilerin Korunması Kanunu uyarınca yapılan taleplere
            ilişkin
          </h4>
          <p>
            Tüm başvurular Gizlilik ve KVKK sayfalarında da duyurulduğu üzere,
            olası şikayetleri, kimlik fotokopisi ile birlikte iadeli taahhütlü
            mektup ile iletişim sayfasında yer alan adrese gönderilmesi halinde
            işleme alınacaktır. Kişisel verilere ilişkin haklar yalnızca
            kişilerin kendilerine ait veriler hakkında kullanılabilecektir.
            Olası şikayetler ve ekinde kimlik fotokopisi bulunan kişinin kendisi
            dışındaki kişilerin verilerine ilişkin talepler dikkate
            alınmayacaktır. Kimlik fotokopisi eklenmeyen formlar dikkate
            alınmayacaktır. Veri silme talepleri yerine getirildiğinde dahi
            resmi makamlar tarafından talep edildiği takdirde verileri resmi
            makamlarla paylaşmakla yükümlü olduğumuzu bilgilerinize sunarız.
          </p>
          <h4>
            Kişisel Verilerin Korunması Ve Gizlilik Politikası’nda Yapılabilecek
            Değişiklikler:
          </h4>
          <p>
            “AABİM Oto-Halı Yıkama Fabrikası” buradaki beyanlar üzerinde
            değişiklik yapma hakkı saklıdır. Beyanda önemli bir değişiklik
            yapıldığı takdirde, web sitesinin ana sayfasına güncel beyana
            ulaşılmasını sağlayan bir bağlantı eklenir. Ürün veya
            hizmetlerimizden herhangi biri için kayıt yaptıran misafirler, bu
            konuyla ilgili olarak tesislere verdiği iletişim kanalı üzerinden
            bilgilendirilebilir. Beyanın en son ne zaman güncellendiği ve
            güncelleme numarası bu metnin sonunda belirtilmektedir.
          </p>
          <p>
            Beyanda yapılan her değişiklik, değiştirilmiş beyanın sitede
            yayınlanmasıyla birlikte geçerli olur. Bu tür değişiklikleri
            müteakiben siteyi, ürün ve hizmetlerimizden herhangi birini
            kullanmak o dönemde geçerli olan değiştirilmiş beyanı kabul
            ettiğinizi gösterir.
          </p>
          <h4>Politika Güncelleme: 01.01.2020.KVKK Beyan-1</h4>
          <h4>İletişim</h4>
          <p>
            Gizlilik sözleşmesiyle ilgili sorularınız için iletişim sayfasında
            yer alan bilgilerini kullanarak bize ulaşabilirsiniz.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KVKKPage;
