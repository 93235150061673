import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useAddOrderMutation } from "../slice/redux/orderSlice";
import Spinner from "./Spinner";
import {toast} from "react-toastify"

const OrderPage = () => {
  const [ addOrder , {data: addInfo, isLoading, isError, message}] = useAddOrderMutation()
  const [formData, setFormData] = useState({
    adiSoyadi: "",
    adres: "",
    telefon: "",
    onemliNot: "",
  });
  const {adiSoyadi, adres, telefon, onemliNot} = formData

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const handleSubmit = async () => {
    try {
      await addOrder(formData).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      setFormData((prevState) => ({
        ...prevState,
        adiSoyadi: "",
        adres: "",
        telefon: "",
        onemliNot: "",
      }))
    }
  }, [addInfo]);

  return (
    <div className="about orderpage" id="orderpage">
      {isLoading && <Spinner />}
      <div className="container mt-5">
        <h3 className="text-center">
          Ön Sipariş Formu (Halı-Oto Yıkama İstekleriniz)
        </h3>
        <section className="mb-5">
          <div className="mb-2 d-flex flex-row justify-content-between">
            <div className="col-lg-2 col-md-3 col-5">
              <label htmlFor="adiSoyadi" className="form-label mt-1">
                Adınız Soyadınız
              </label>
            </div>
            <div className="col-lg-10 col-md-9 col-7">
              <input
                type="text"
                className="form-control"
                id="adiSoyadi"
                name="adiSoyadi"
                value={adiSoyadi}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="mb-2 d-flex flex-row justify-content-between">
            <div className="col-lg-2 col-md-3 col-5">
              <label htmlFor="adres" className="form-label mt-1">
              Adres
              </label>
            </div>
            <div className="col-lg-10 col-md-9 col-7">
            <input
              type="text"
              className="form-control"
              id="adres"
              name="adres"
              value={adres}
              onChange={handleChange}
              required
            />
            </div>
          </div>
          <div className="mb-2 d-flex flex-row justify-content-between">
            <div className="col-lg-2 col-md-3 col-5">
              <label htmlFor="telefon" className="form-label mt-1">
              Telefon Numaranız
              </label>
            </div>
            <div className="col-lg-10 col-md-9 col-7">
            <input
               type="tel"
               className="form-control"
               id="telefon"
               name="telefon"
               value={telefon}
               onChange={handleChange}
               required
            />
            </div>
          </div>
          <div className="mb-2 d-flex flex-row justify-content-between">
            <div className="col-lg-2 col-md-3 col-5">
              <label htmlFor="onemliNot" className="form-label mt-1">
              Önemli Notunuz..
              </label>
            </div>
            <div className="col-lg-10 col-md-9 col-7">
            <textarea
              className="form-control"
              id="onemliNot"
              name="onemliNot"
              value={onemliNot}
              onChange={handleChange}
              rows="3"
            ></textarea>
            </div>
          </div>
          {isError && <p className="text-danger">{message}</p>}
          <div className="mb-2 d-flex flex-row justify-content-between">
          <div className="col-lg-2 col-md-3 col-5"> </div>
          <div className="col-lg-10 col-md-9 col-7">
          <button className="btn btn-warning w-100 mb-5" onClick={handleSubmit}>
            <FaSave /> Kaydet
          </button>
          </div>
         </div>
        </section>
      </div>
    </div>
  );
};

export default OrderPage;
