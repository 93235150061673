import React from "react";
import { useGetBlogsQuery } from "../slice/redux/blogSlice";
import { SERVERPICT_URL } from "../slice/constants";
import { truncateWord } from "../app/Utility";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();
  const { data: blogTable, isLoading } = useGetBlogsQuery();

  const getTurkishMonthName = (monthIndex) => {
    const ayAdlari = [
      "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
      "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ];
    
    return ayAdlari[monthIndex];
  };

  return (
    <div className="blog">
      <div className="container">
        <div className="section-header text-center">
          <p>Bizden Haberler</p>
          <h3>Yenilikçi Çözümler İle Her Geçen Gün Daha İleri..</h3>
        </div>
        <div className="row">
          {isLoading ? (
            <p>Yükleniyor...</p>
          ) : (
            <>
              {blogTable && blogTable.length > 0 && (
                blogTable.map((blog, index) => (
                  <div
                    key={index}
                    className="col-lg-4 cursor-pointer"
                    onClick={() => navigate(`/blog/${blog.blogID}`)}
                  >
                    <div className="blog-item">
                      <div className="blog-img">
                        <img
                          src={`${SERVERPICT_URL}${blog.pictureUrl}`}
                          alt={blog.pictureUrl}
                        />
                        <div className="meta-date">
                          <span>{new Date(blog.tarih).getDate()}</span>
                          <strong>{getTurkishMonthName(new Date(blog.tarih).getMonth())}</strong>
                          <span>{new Date(blog.tarih).getFullYear()}</span>
                        </div>
                      </div>
                      <div className="blog-text">
                        <h3>{blog.blogBaslik}</h3>
                        <p>{truncateWord(blog.aciklama, 290)}</p>
                      </div>
                      <div className="blog-meta">
                        <p>
                          <i className="fa fa-user"></i>
                          {blog.email}
                        </p>
                        <p>
                          <i className="fa fa-comments"></i>{blog.commits} Yorum
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
