import React from "react";
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const Prices = () => {
  const { webData } = useSelector((state) => state.clnAuth);
  let priceBilgi = webData && JSON.parse(webData.prices);

  return (
    <div className="price" id="price">
      <div className="container">
        <div className="section-header text-center">
          <p>Yıkama Planı</p>
          <h2>Planınızı Seçin</h2>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="price-item">
              <div className="price-header">
                <h3>{priceBilgi && priceBilgi[0].baslik}</h3>
                <h2>
                  <strong>
                    {priceBilgi && priceBilgi[0].fiyat.toString().split(".")[0]}
                  </strong>
                  <span>
                    .
                    {priceBilgi && priceBilgi[0].fiyat.toString().split(".")[1]}
                  </span>
                  <span> ₺</span>
                </h2>
              </div>
              <div className="price-body">
                <ul>
                  <li>
                    {priceBilgi && priceBilgi[0].birFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[0].detayBir}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[0].ikiFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[0].detayIki}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[0].ucFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[0].detayUc}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[0].dortFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[0].detayDort}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[0].besFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[0].detayBes}
                  </li>
                </ul>
              </div>
              <div className="price-footer">
              <Link to={`/temizlikrezervasyon/${priceBilgi[0].priceID}`}>
                <span className="btn btn-custom">
                  Şimdi Rezervasyon Yapın
                </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="price-item featured-item">
              <div className="price-header">
                <h3>{priceBilgi && priceBilgi[1].baslik}</h3>
                <h2>
                  <strong>
                    {priceBilgi && priceBilgi[1].fiyat.toString().split(".")[0]}
                  </strong>
                  <span>
                    .
                    {priceBilgi && priceBilgi[1].fiyat.toString().split(".")[1]}
                  </span>
                  <span> ₺</span>
                </h2>
              </div>
              <div className="price-body">
                <ul>
                  <li>
                    {priceBilgi && priceBilgi[1].birFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[1].detayBir}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[1].ikiFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[1].detayIki}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[1].ucFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[1].detayUc}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[1].dortFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[1].detayDort}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[1].besFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[1].detayBes}
                  </li>
                </ul>
              </div>
              <div className="price-footer">
              <Link to={`/temizlikrezervasyon/${priceBilgi[1].priceID}`}>
                <span className="btn btn-custom">
                  Şimdi Rezervasyon Yapın
                </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="price-item">
              <div className="price-header">
                <h3>{priceBilgi && priceBilgi[2].baslik}</h3>
                <h2>
                  <strong>
                    {priceBilgi && priceBilgi[2].fiyat.toString().split(".")[0]}
                  </strong>
                  <span>
                    .
                    {priceBilgi && priceBilgi[2].fiyat.toString().split(".")[1]}
                  </span>
                  <span> ₺</span>
                </h2>
              </div>
              <div className="price-body">
                <ul>
                  <li>
                    {priceBilgi && priceBilgi[2].birFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[2].detayBir}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[2].ikiFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[2].detayIki}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[2].ucFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[2].detayUc}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[2].dortFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[2].detayDort}
                  </li>
                  <li>
                    {priceBilgi && priceBilgi[2].besFlag === "1" ? (
                      <FaCheckCircle color="green" />
                    ) : (
                      <FaTimesCircle color="gray" />
                    )}
                     {priceBilgi && priceBilgi[2].detayBes}
                  </li>
                </ul>
              </div>
              <div className="price-footer">
                <Link to={`/temizlikrezervasyon/${priceBilgi[2].priceID}`}>
                <span className="btn btn-custom">
                  Şimdi Rezervasyon Yapın
                </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prices;
