import React, { useEffect, useState } from "react";
import GrafikliIslemTahsilat from "./GrafikliIslemTahsilat";
import { useGetAdminDashboardQuery } from "../../slice/redux/sabitSlice";
import Spinner from "../../components/Spinner";

const AdminDashboard = () => {
  const {data: dashTable, isLoading} = useGetAdminDashboardQuery()
  const [customerCount, setCustomerCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [totalIslem, setTotalIslem] = useState(0);
  const [totalAlacak, setTotalAlacak] = useState(0);
  const [totalTahsilat, setTotalTahsilat] = useState(0);
  const [blogCount, setBlogCount] = useState(0);

  useEffect(() => {
    if(dashTable) {
      setCustomerCount(dashTable.musteriSayisi)
      setProductCount(dashTable.urunSayisi)
      setTotalIslem(dashTable.islemSayisi)
      setTotalAlacak(dashTable.toplamAlacak)
      setTotalTahsilat(dashTable.toplamTahsilat)
      setBlogCount(dashTable.blogSayisi)
    }
  }, [dashTable])

  return (
    <section className="dashboard">
      {isLoading && <Spinner />}
      <h2 className="mb-4">Admin Dashboard</h2>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-11">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Müşteri Sayısı</h5>
              <p className="card-text">{customerCount}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-11">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Ürün Sayısı</h5>
              <p className="card-text">{productCount}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-11">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Toplam İşlem Sayısı</h5>
              <p className="card-text">{totalIslem}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-4 col-md-4 col-11">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Toplam Alacak Miktarı</h5>
              <p className="card-text">{totalAlacak}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-11">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Toplam Tahsilat Miktarı</h5>
              <p className="card-text">{totalTahsilat}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-11">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Blog Sayısı</h5>
              <p className="card-text">{blogCount}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <GrafikliIslemTahsilat />
      </div>
    </section>
  );
};

export default AdminDashboard;
