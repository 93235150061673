import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./slice/apiSlice";
import authReducer from "./slice/auth/authSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    clnAuth: authReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
