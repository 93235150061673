import React from "react";
import { useSelector } from "react-redux";
import toucanLogo from "../assets/web_logo_toucan.png";
import aabimLogo from "../assets/aabimlogo.png";

const Footer = () => {

  const currentYear = new Date().getFullYear();
  const { webData } = useSelector((state) => state.clnAuth);
  let footerBilgi= webData && JSON.parse(webData.sabit);
  
  return (
    <div className="footer" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            {footerBilgi && (
              <div className="footer-contact">
                <h2>Buradayız</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>
                  {footerBilgi && footerBilgi.firmaAdi && footerBilgi.firmaAdi}
                </p>
                <p>
                  <i className="fa fa-phone-alt"></i>
                  {footerBilgi && footerBilgi.ceptel && footerBilgi.ceptel}
                </p>
                <p>
                  <i className="fa fa-phone-alt"></i>
                  {footerBilgi && footerBilgi.telefon && footerBilgi.telefon}
                </p>
                <p>
                  <i className="fa fa-envelope"></i>
                  {footerBilgi && footerBilgi.email && footerBilgi.email}
                </p>
                <div className="footer-social">
                  {footerBilgi && footerBilgi.twitterUrl ? (
                    <a className="btn" href="https://www.google.com" rel="noreferrer" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  ) : null}
                  {footerBilgi && footerBilgi.facebookUrl ? (
                    <a
                      className="btn"
                      href="https://www.facebook.com/aabimmm?mibextid=ZbWKwL"
                      target="_blank" rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  ) : null}
                  {footerBilgi && footerBilgi.youtubeUrl ? (
                    <a className="btn" href="https://www.google.com" rel="noreferrer" target="_blank">
                      <i className="fab fa-youtube"></i>
                    </a>
                  ) : null}
                  {footerBilgi && footerBilgi.instagramUrl ? (
                    <a
                      className="btn"
                      href="https://www.instagram.com/aabimotohaliyikama/?igsh=MTM4emx2Z2xrM2NrYQ%3D%3D"
                      target="_blank" rel="noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  ) : null}
                  {footerBilgi && footerBilgi.linkedinUrl ? (
                    <a className="btn" href="https://www.google.com#" rel="noreferrer" target="_blank">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  ) : null}
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-link">
              <h2>Linkler</h2>
              <a href="#team">Hakkımızda</a>
              <a href="#footer">İletişime Geçin</a>
              <a href="#service">Servislerimiz</a>
              <a href="#location">Hizmet Noktaları</a>
              <a href="#price">Ücretlerimiz</a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-link">
              <h2>Faydalı Bilgiler</h2>
              <a href="/nezamanyikanir">Halı Ne Zaman Yıkanır?</a>
              <a href="/halimakineyikama">Her Halı Makina İle Yıkanır mı?</a>
              <a href="/haliyikamaperiyot">
                Halı Hangi Aralıklarla Yıkanmalıdır?
              </a>
              <a href="/kvkk">KVKK - Gizlilik Bildirimi</a>
            </div>
          </div> 
          <div className="col-lg-3 col-md-6">
            <div className="footer-newsletter">
              <h2 className="hidden">Bizden Haberdar Olmak İçin</h2>
              <form>
                <input className="form-control hidden" placeholder="Adınız Soyadınız" />
                <input className="form-control hidden" placeholder="E-posta Adresiniz" />
                <button className="btn btn-custom hidden">Haber Sistemine Kaydet</button>
                <div className="text-center">
                <img
                  className="mt-3"
                  src={aabimLogo}
                  alt={aabimLogo}
                  style={{ height: "150px" }}
                />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container copyright">
        <p>
          <span>
            Created by{" "}
            <a
              href="https://www.linkedin.com/in/szaferulgur/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fenerbahce">SZÜ</span>
            </a>
              
            <img
              className="cursor-pointer"
              src={toucanLogo}
              alt="Serhat Zafer Ülgür React JS Node JS Web Uygulaması Türkiye Ankara Osmaniye Software Developer"
              style={{ width: "25px" }}
              onClick={() =>
                window.open("https://github.com/SZaferUlgur", "_blank")
              }
            />
              
            {currentYear}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
