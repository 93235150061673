import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import { useAddTemizlikMutation } from "../slice/redux/temizlikSlice";
import Spinner from "./Spinner";
import { toast } from "react-toastify";

const RezervasyonPage = () => {
  const params = useParams();
  const navigate = useNavigate()
  const islemID = params.id;
  const [addTemizlik, {data: addInfo, isLoading, message}] = useAddTemizlikMutation()
  const { webData } = useSelector((state) => state.clnAuth);
  const priceBilgi = webData ? JSON.parse(webData.prices) : [];
  const selectedPrice = priceBilgi.find((price) => price.priceID === parseInt(islemID));

  const [customerName, setCustomerName] = useState("");
  const [customerSurname, setCustomerSurname] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [note, setNote] = useState("");

  const handleReservation = async () => {
    let data = {customerName, customerSurname, customerPhone, customerAddress, note, islemID}
    try {
      await addTemizlik(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      navigate(-1)
    }
  }, [addInfo, navigate]);

  return (
    <>
    {isLoading && <Spinner />}
      <TopBar />
      <Header />
      <div className="single">
        <div className="container">
          <div className="reservation-form">
            <div className="container">
              <div className="section-header text-center">
                <h2>Rezervasyon Yap</h2>
              </div>
             
              <div className="islem_aciklama gap-1">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="price-details">
                    <h3>{selectedPrice && selectedPrice.baslik}</h3>
                    <h2>
                      <strong>
                        {selectedPrice &&
                          selectedPrice.fiyat.toString().split(".")[0]}
                      </strong>
                      <span>
                        .
                        {selectedPrice &&
                          selectedPrice.fiyat.toString().split(".")[1]}
                      </span>
                      <span> ₺</span>
                    </h2>
                    <ul>
                      {selectedPrice &&
                        [
                          {
                            flag: selectedPrice.birFlag,
                            text: selectedPrice.detayBir,
                          },
                          {
                            flag: selectedPrice.ikiFlag,
                            text: selectedPrice.detayIki,
                          },
                          {
                            flag: selectedPrice.ucFlag,
                            text: selectedPrice.detayUc,
                          },
                          {
                            flag: selectedPrice.dortFlag,
                            text: selectedPrice.detayDort,
                          },
                          {
                            flag: selectedPrice.besFlag,
                            text: selectedPrice.detayBes,
                          },
                        ].map((detay, index) => (
                          <li key={index}>
                            {detay.flag === "1" ? (
                              <FaCheckCircle color="green" />
                            ) : (
                              <FaTimesCircle color="gray" />
                            )}
                            {detay.text}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-12">
                  <seciton>
                    <div className="form-group">
                      <label>Ad:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Soyad:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerSurname}
                        onChange={(e) => setCustomerSurname(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Telefon:</label>
                      <input
                        type="tel"
                        className="form-control"
                        value={customerPhone}
                        onChange={(e) => setCustomerPhone(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Açık Adres:</label>
                      <textarea
                        className="form-control"
                        value={customerAddress}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        required
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label>Önemli Not:</label>
                      <textarea
                        className="form-control"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      ></textarea>
                    </div>
                    <button
                      className="btn btn-primary mt-2"
                      onClick={() => handleReservation()}
                    >
                      Rezervasyon Yap
                    </button>
                  </seciton>
                </div>
              </div>
            </div>
          </div>
        </div>
        {message && <p className="text-danger">{message}</p>}
      </div>
      <Footer />
    </>
  );
};

export default RezervasyonPage;
