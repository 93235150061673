import React from "react";
import { Link } from "react-router-dom"
import './NotFound.css'; // CSS dosyasını ekliyoruz

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="moon"></div>
      <div className="message">
        <h1 style={{ fontSize: "5em", color: "#333"}}>404</h1>
        <p style={{ fontSize: "1.5em", color: "#666"}}>Hayrola.. Yolunuzu mu kaybettiniz ?</p>
        <p>Ama istediğiniz yere ulaşılamadı.</p>
        <h2 className="text-center"><Link to="/">Ana Sayfa</Link></h2>
      </div>
    </div>
  );
}

export default NotFound;
