import React from "react";
import carusel0 from "../assets/carousel-1.jpg";
import carusel1 from "../assets/carousel-2.jpg";
import carusel2 from "../assets/carousel-3.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";

const Carouseel = () => {
  const { webData } = useSelector((state) => state.clnAuth);
  let carousels = webData && JSON.parse(webData.carousels);

  const carouselImages = [carusel0, carusel1, carusel2];

  return (
    <>
      {carousels && carousels.length > 0 && (
        <>
          <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay
            interval={7500}
            infiniteLoop
          >
            {carousels.map((carousel, index) => (
              <div key={index} className="carousel-item">
                <div className="carousel-img">
                  <img src={carouselImages[index]} alt={carouselImages[index]} />
                </div>
                <div className="carousel-text">
                  <h3>Yıkama ve Detaylandırma</h3>
                  <h1>{carousel.baslik}</h1>
                  <p>"{carousel.aciklama}!"</p>
                  <a className="btn btn-custom" href="/detail">
                    Daha Fazlasını Keşfedin
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        </>
      )}
    </>
  );
};

export default Carouseel;
