import React, { useState, useCallback, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { Paginator } from "primereact/paginator";
import { FaBackspace, FaSave, FaTrashAlt } from "react-icons/fa";
import {
  useDeleteKampanyaByIdMutation,
  useGetKampanyasQuery,
  useUpdateKampanyaByIdMutation,
} from "../../slice/redux/kampanyaSlice";
import Spinner from "../../components/Spinner";
import moment from "moment";
import { truncateWord } from "../Utility";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { SERVERPICT_URL } from "../../slice/constants";
import { useAddStokMutation, useUpdateStokofPictMutation } from "../../slice/redux/stokSlice";
import { primeReactStyle } from "../PrimeReactUtil";

const KampanyaAddPage = () => {
  const {
    data: kampanyaTable,
    isLoading,
    isError,
    message,
    refetch,
  } = useGetKampanyasQuery();
  const [deleteKampanyaById, { data: delInfo, isLoading: delLoading }] =
    useDeleteKampanyaByIdMutation();
  const [updateKampanyaById, { data: upInfo, isLoading: updateLoading }] =
    useUpdateKampanyaByIdMutation();
  // Kampanya ekleme ve resimli güncelleme için..
  const [updateStokofPict] = useUpdateStokofPictMutation();
  const [addStok] = useAddStokMutation();
  const folder = "kampanya";

  const [baslangicTarihi, setBaslangicTarihi] = useState(null);
  const [bitisTarihi, setBitisTarihi] = useState(null);
  const [metin, setMetin] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [baslik, setBaslik] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [silVisible, setSilVisible] = useState(false);
  const [silID, setSilID] = useState("");
  const [editID, setEditID] = useState(null);

  const handlePageChange = useCallback((event) => {
    setFirst(event.first);
    setRows(event.rows);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPictureUrl(file);
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    const basTarih = moment(baslangicTarihi).format("YYYY.MM.DD")
    const bitTarih = moment(bitisTarihi).format("YYYY.MM.DD")
    const data = {
      baslangicTarihi: basTarih,
      bitisTarihi: bitTarih,
      metin,
      pictureUrl,
      baslik,
      id: editID,
    };

    console.log(previewUrl)

    try {
      if (editID && !pictureUrl) {
        await updateKampanyaById(data).unwrap();
      } else if (editID && pictureUrl) {
        const formData = new FormData();
        formData.append("baslangicTarihi", basTarih);
        formData.append("bitisTarihi", bitTarih);
        formData.append("metin", metin);
        formData.append("baslik", baslik);
        formData.append("folder", folder);
        formData.append("image", pictureUrl);
        formData.append("id", editID);
        try {
          await updateStokofPict(formData).unwrap();
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      } else {
        const formData = new FormData();
        formData.append("baslangicTarihi", basTarih);
        formData.append("bitisTarihi", bitTarih);
        formData.append("metin", metin);
        formData.append("baslik", baslik);
        formData.append("folder", folder);
        formData.append("image", pictureUrl);
        try {
          await addStok(formData).unwrap();
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      }
      // Formu temizle
      setBaslangicTarihi(null);
      setBitisTarihi(null);
      setMetin("");
      setPictureUrl("");
      setPreviewUrl(null);
      setBaslik("");
      setEditID(null);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleEdit = (kampanya) => {
    setEditID(kampanya.kampanyaID);
    setBaslangicTarihi(moment(kampanya.startDate).toDate());
    setBitisTarihi(moment(kampanya.endDate).toDate());
    setMetin(kampanya.textBilgi);
    setBaslik(kampanya.title);
    setPreviewUrl(kampanya.resimUrl);
  };

  const handleDeleteSor = (id) => {
    setSilID(id);
    setSilVisible(true);
  };

  const handleCancel = () => {
    setBaslangicTarihi(null);
    setBitisTarihi(null);
    setMetin("");
    setPictureUrl("");
    setPreviewUrl(null);
    setBaslik("");
    setEditID(null);
    refetch();
  };

  const handleDelete = async () => {
    try {
      await deleteKampanyaById(silID).unwrap();
      setSilVisible(false);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [delInfo, refetch]);

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [upInfo, refetch]);

  return (
    <div className="mx-2">
      {(isLoading || delLoading || updateLoading) && <Spinner />}
      <h2>Kampanya Ekle / Düzelt</h2>
      <section className="mb-4">
        <div className="d-flex flex-row gap-2">
          <div className="mb-2 d-flex flex-column">
            <label htmlFor="baslangicTarihi" className="form-label">
              Başlangıç Tarihi
            </label>
            <Calendar
              id="baslangicTarihi"
              value={baslangicTarihi}
              onChange={(e) => setBaslangicTarihi(e.value)}
              dateFormat="yy-mm-dd"
            />
          </div>
          <div className="mb-2 d-flex flex-column">
            <label htmlFor="bitisTarihi" className="form-label">
              Bitiş Tarihi
            </label>
            <Calendar
              id="bitisTarihi"
              value={bitisTarihi}
              onChange={(e) => setBitisTarihi(e.value)}
              dateFormat="yy-mm-dd"
            />
          </div>
          <div className="mb-2 d-flex flex-column">
            <label htmlFor="pictureUrl" className="form-label">
              Resim Yükle
            </label>
            <input
              type="file"
              id="pictureUrl"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          <div className="mb-2 d-flex flex-column">
            {!editID && previewUrl && (
              <img
                src={previewUrl}
                alt="Resim Önizleme"
                style={{
                  height: "180px",
                  marginTop: "10px",
                  border: "1px solid lightgray",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              />
            )}
            {editID && (
              <>
                {previewUrl && (
                  <img
                    src={`${SERVERPICT_URL}${previewUrl}`}
                    alt="Resim Önizleme"
                    style={{
                      height: "180px",
                      marginTop: "10px",
                      border: "1px solid lightgray",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="mb-2 d-flex flex-column col-5"
          style={{ marginTop: `${previewUrl ? "-115px" : ""}` }}
        >
          <label htmlFor="baslik" className="form-label">
            Kampanya Başlığı
          </label>
          <input
            type="text"
            id="baslik"
            className="form-control"
            value={baslik}
            onChange={(e) => setBaslik(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="metin" className="form-label">
            Metin
          </label>
          <textarea
            rows={5}
            type="text"
            id="metin"
            className="form-control"
            value={metin}
            onChange={(e) => setMetin(e.target.value)}
          />
        </div>
        <div className="d-flex gap-2 justify-content-center">
          <button
            className="btn btn-primary col-lg-4 col-md-4 col-8"
            onClick={handleSubmit}
          >
            <FaSave /> {editID ? "Kampanya Güncelle" : "Kampanya Kaydet"}
          </button>
          <button
            style={{ display: `${editID ? "block" : "none"}` }}
            className="btn btn-secondary col-lg-2 col-md-2 col-4"
            onClick={handleCancel}
          >
            <FaBackspace /> Vazgeç
          </button>
        </div>
      </section>

      <h2>Mevcut Kampanyalar</h2>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>İşlem</th>
              <th>Başlangıç Tarihi</th>
              <th>Bitiş Tarihi</th>
              <th>Kampanya Başlığı</th>
              <th>Metin</th>
              <th>Resim URL</th>
            </tr>
          </thead>
          {kampanyaTable && kampanyaTable.length > 0 && (
            <tbody>
              {kampanyaTable.slice(first, first + rows).map((kampanya) => (
                <tr key={kampanya.kampanyaID}>
                  <td>{kampanya.kampanyaID}</td>
                  <td className="d-flex flex-row gap-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEdit(kampanya)}
                    >
                      Seç
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDeleteSor(kampanya.kampanyaID)}
                    >
                      Sil
                    </button>
                  </td>
                  <td>{moment(kampanya.startDate).format("YYYY.MM.DD")}</td>
                  <td>{moment(kampanya.endDate).format("YYYY.MM.DD")}</td>
                  <td>{kampanya.title}</td>
                  <td>{truncateWord(kampanya.textBilgi, 20)}</td>
                  <td>{kampanya.resimUrl}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <Paginator
            first={first}
            rows={rows}
            totalRecords={kampanyaTable && kampanyaTable.length}
            onPageChange={handlePageChange}
          />
      </div>
      <Dialog
        header="Kayıt Silme Onayı"
        visible={silVisible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setSilVisible(false)}
       >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu <strong>Kampanya Kaydı</strong>{" "}
            Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDelete}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setSilVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>

      {isError && <p className="text-danger">{message}</p>}
    </div>
  );
};

export default KampanyaAddPage;
