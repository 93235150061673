import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { primeReactStyle } from "../PrimeReactUtil";
import moment from "moment";
import { useGetHizmetsQuery } from "../../slice/redux/hizmetSlice";
import Spinner from "../../components/Spinner";
import { FaSave } from "react-icons/fa";
import { useAddIslemMutation } from "../../slice/redux/islemSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAllReloadPage } from "../../slice/auth/authSlice";

const IslemKayit = () => {
  const dispatch = useDispatch()
  const {selectedMusteri} = useSelector(state => state.clnAuth)
  const { data: hizmetTable, isLoading } = useGetHizmetsQuery();
  const [addIslem, { data: addInfo, isLoading: addLoading }] =
    useAddIslemMutation();
  const [date, setDate] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(true);

  const [newIslem, setNewIslem] = useState({
    musteriID: "",
    tarih: "",
    aciklama: "",
    miktar: "",
    birimFiyat: "",
    genelToplam: "",
    kapatFlag: "",
  });
  const { tarih, miktar, birimFiyat, genelToplam } = newIslem;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Virgül karakterini nokta karakteri ile değiştir
    const cleanedValue = value.replace(',', '.');
    setNewIslem({ ...newIslem, [name]: cleanedValue });
  };

  const hizmetKaydet = async () => {
    let data = {
      musteriID: selectedMusteri.id,
      tarih,
      aciklama: selectedHizmet.hizmetAdi,
      miktar,
      birimFiyat,
      genelToplam,
    };
    try {
      await addIslem(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      dispatch(setAllReloadPage(true))
    }
  }, [addInfo, dispatch]);

  useEffect(() => {
    if (date) {
      if (tarih === "" || date !== tarih) {
        setNewIslem((prevState) => ({
          ...prevState,
          tarih: moment(date).format("YYYY.MM.DD"),
        }));
      }
    }
  }, [date, tarih]);

  const [combo, setCombo] = useState([]);

  useEffect(() => {
    if (hizmetTable && hizmetTable.length > 0) {
      const updatedCombo = hizmetTable.map((item) => ({
        hizmetID: item.hizmetID,
        hizmetAdi: item.hizmetAdi,
        olcuBirimi: item.olcuBirimi,
        birimFiyat: item.birimFiyat,
      }));
      setCombo(updatedCombo);
    }
  }, [hizmetTable]);

  const [hizmets, setHizmets] = useState([]);
  const [selectedHizmet, setSelectedHizmet] = useState(null);
  const [filteredHizmets, setFilteredHizmets] = useState(null);

  useEffect(() => {
    if (combo) {
      setHizmets(combo);
    }
  }, [combo]);

  const searchHizmet = (event) => {
    setTimeout(() => {
      let _filterHizmet;
      if (!event.query.trim().length) {
        _filterHizmet = [...hizmets];
      } else {
        _filterHizmet = hizmets.filter((hizmet) => {
          return hizmet.hizmetAdi
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }
      setFilteredHizmets(_filterHizmet);
    }, 250);
  };

  useEffect(() => {
    if (selectedHizmet && selectedHizmet.birimFiyat) {
      if (
        birimFiyat === "" ||
        !birimFiyat ||
        birimFiyat !== selectedHizmet.birimFiyat
      ) {
        setNewIslem((prevState) => ({
          ...prevState,
          birimFiyat: selectedHizmet.birimFiyat,
        }));
      }
    }
  }, [birimFiyat, selectedHizmet]);

  useEffect(() => {
    if (miktar && birimFiyat) {
      // Virgülle ayrılmış değerleri ondalık işareti ile çarpabiliriz
      const total = parseFloat(miktar) * parseFloat(birimFiyat);
      // Sonucu genelToplam alanına atayabiliriz
      setNewIslem((prevState) => ({
        ...prevState,
        genelToplam: total.toFixed(2), // İki ondalık hane olarak sabitle
      }));
    }
  }, [miktar, birimFiyat]);
  useEffect(() => {
    if (
      selectedHizmet &&
      selectedHizmet.hizmetAdi &&
      selectedMusteri &&
      selectedMusteri.id &&
      birimFiyat &&
      genelToplam
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedHizmet, selectedMusteri, tarih, miktar, birimFiyat, genelToplam]);
  
  return (
    <div className="musteri-kayit-info">
      {(isLoading || addLoading) && <Spinner />}
      <div>
        <div className="row">
          <div className="col-lg-2 col-md-2 col-11 d-flex flex-column">
            <label htmlFor="tarih" className="form-label">
              Tarih
            </label>
            <Calendar
              style={primeReactStyle.calenderStyle}
              inputStyle={primeReactStyle.calenderInputStyle}
              value={date}
              onChange={(e) => setDate(e.value)}
              dateFormat="yy/mm/dd"
            />
          </div>
          <div className="col-lg-4 col-md-4 col-11 d-flex flex-column">
            <label htmlFor="aciklama" className="form-label">
              Açıklama
            </label>
            <AutoComplete
              field="hizmetAdi"
              className="w-100"
              value={selectedHizmet}
              suggestions={filteredHizmets}
              completeMethod={searchHizmet}
              onChange={(e) => {
                setSelectedHizmet(e.value);
              }}
              dropdown
              inputStyle={{
                background: "white",
                color: "black",
                padding: "5px",
              }}
              style={{ height: "36px" }}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-11 d-flex flex-column">
            <label htmlFor="miktar" className="form-label">
              Adet / Metre Kare
            </label>
            <input
              type="text"
              className="form-control"
              name="miktar"
              value={miktar}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-lg-2 col-md-2 col-11 d-flex flex-column">
            <label htmlFor="birimFiyat" className="form-label">
              Birim Fiyat
            </label>
            <input
              type="text"
              className="form-control"
              name="birimFiyat"
              value={birimFiyat}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-lg-2 col-md-2 col-11 d-flex flex-column">
            <label htmlFor="genelToplam" className="form-label">
              Genel Toplam
            </label>
            <input
              type="text"
              className="form-control"
              name="genelToplam"
              value={genelToplam}
              required
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-11 mt-1">
            <button
              className="btn btn-primary w-100"
              onClick={hizmetKaydet}
              disabled={isDisabled}
            >
              <FaSave /> İşlem Ekle
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IslemKayit;
