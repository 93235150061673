import { PRICE_URL } from "../constants";
import apiSlice from "../apiSlice";

export const priceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrices: builder.query({
      query: () => ({
        url: `${PRICE_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblprice"],
    }),
    updatePrice: builder.mutation({
      query: (formData) => ({
        url: `${PRICE_URL}`,
        method: "POST",
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblprice"],
    }),
  }),
});

export const {
  useGetPricesQuery,
  useUpdatePriceMutation,
} = priceApiSlice;
