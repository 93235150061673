import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ProductPage from './ProductPage'

const DukkanPage = () => {
  return (
    <>
    <Header />
    <ProductPage />
    <Footer />
    </>
  )
}

export default DukkanPage