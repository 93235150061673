import React, { useState, useEffect } from "react";
import {
  useAddStokMutation,
  useGetStoksQuery,
  useUpdateStokMutation,
  useDeleteStokByIdMutation,
} from "../../slice/redux/stokSlice";
import { toast } from "react-toastify";
import { SERVERPICT_URL } from "../../slice/constants";
import Spinner from "../../components/Spinner";
import { Paginator } from "primereact/paginator";
import { FaBackspace } from "react-icons/fa";

const AdminProductsScreen = () => {
  const { data: stokTable, isLoading, refetch } = useGetStoksQuery();
  const [aramaText, setAramaText] = useState("");
  const [aciklama, setAciklama] = useState("");
  const [fiyat, setFiyat] = useState("");
  const [pictureUrl, setPictureUrl] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const folder = "urun";
  const [addStok, { data: addInfo, isLoading: addLoading }] =
    useAddStokMutation();
  const [updateStok, { data: upInfo, isLoading: upLoading }] =
    useUpdateStokMutation();
  const [updateStokofPict, { data: upPicInfo, isLoading: upPicLoading }] =
    useUpdateStokMutation();
  const [deleteStokById] = useDeleteStokByIdMutation();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(7);

  const handleSubmit = async (e) => {
    if (!pictureUrl && selectedProductId) {
      try {
        let data = { id: selectedProductId, aciklama, fiyat };
        await updateStok(data).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else if (pictureUrl && selectedProductId) {
      const formData = new FormData();
      formData.append("aciklama", aciklama);
      formData.append("fiyat", fiyat);
      formData.append("folder", folder);
      formData.append("image", pictureUrl);
      try {
        await updateStokofPict(formData).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else if (pictureUrl && !selectedProductId) {
      const formData = new FormData();
      formData.append("aciklama", aciklama);
      formData.append("fiyat", fiyat);
      formData.append("folder", folder);
      formData.append("image", pictureUrl);
      try {
        await addStok(formData).unwrap();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [upInfo, refetch]);

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [addInfo, refetch]);

  useEffect(() => {
    if (upPicInfo && upPicInfo.message) {
      toast(upPicInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [upPicInfo, refetch]);

  const resetForm = () => {
    setAciklama("");
    setFiyat("");
    setPictureUrl(null);
    setSelectedProductId(null);
    setImagePreview(null);
  };

  const handleEdit = (product) => {
    setSelectedProductId(product.id);
    setAciklama(product.aciklama);
    setFiyat(product.fiyat);
    setPictureUrl(null); // Eski resmi kaldırmak için null atayınca preview de güncellenecektir.
    setImagePreview(`${SERVERPICT_URL}${product.pictureUrl}`);
  };

  const handleDelete = async (productId) => {
    try {
      await deleteStokById(productId).unwrap();
      toast.success("Ürün başarıyla silindi.");
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setPictureUrl(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <section className="product">
      {(isLoading || addLoading || upLoading || upPicLoading) && <Spinner />}
      <div className="d-flex flex-row justify-content-between">
        <div className="col-3 d-flex flex-row">
          <label className="col-2 mt-1" htmlFor="arama">
            Arama:
          </label>
          <input
            className="form-control"
            type="search"
            name="aramaText"
            value={aramaText}
            placeholder="Arama Yapınız.."
            onChange={(e) => setAramaText(e.target.value)}
          />
        </div>
        <div className="col-9">
          <h2 className="mb-2 text-center">Ürün Bilgileri</h2>
        </div>
      </div>
      <hr className="my-2" />
      <div className="blog-detail gap-3">
        <div className="col-lg-7 col-md-7 col-12">
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-2 col-4">
              Ürün Açıklaması
            </label>
            <div className="col-lg-10 col-md-10 col-7">
              <input
                type="text"
                className="form-control"
                name="aciklama"
                value={aciklama}
                onChange={(e) => setAciklama(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-2 col-4">Fiyat</label>
            <div className="col-lg-2 col-md-2 col-7">
              <input
                type="number"
                className="form-control"
                name="fiyat"
                value={fiyat}
                onChange={(e) => setFiyat(e.target.value)}
                step="0.01"
                required
              />
            </div>
          </div>
          <div className="mb-1 d-flex flex-row gap-1">
            <label className="form-label col-lg-2 col-md-2 col-4">
              Resim Seç
            </label>
            <div className="col-lg-10 col-md-10 col-7">
              <input
                type="file"
                className="form-control"
                name="pictureUrl"
                onChange={handleImageChange}
              />
            </div>
          </div>
        </div>
        <div
          className="col-lg-1 col-md-1 col-2"
          style={{
            border: "1px solid #000",
            borderRadius: "10px",
            height: "119px",
            width: "119px",
          }}
        >
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Ürün Resmi"
              className="img-thumbnail"
              style={{ borderRadius: "8px", height: "117px" }}
            />
          )}
        </div>
      </div>
      <div className="d-flex flex-row gap-1 col-lg-7 col-md-7 col-12 mt-2">
        <span className="col-2 mobil_hidden"></span>
        <div className="col-lg-4 col-md-4 col-12 d-flex flex-row gap-1">
          <button
            className="btn btn-primary col-lg-6 col-md-6 col-11"
            onClick={handleSubmit}
          >
            {selectedProductId ? "Güncelle" : "Ekle"}
          </button>
          {selectedProductId && (
            <button
              className="btn btn-secondary col-lg-6 col-md-6 col-11"
              onClick={() => resetForm()}
            >
              <FaBackspace /> Vazgeç
            </button>
          )}
        </div>
      </div>
      <hr className="my-2" />
      <h3 className="mt-2 text-center">Ürünler Tablosu</h3>
      <div className="responsive-table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style={{ width: "5%" }}>
                Ürün ID
              </th>
              <th scope="col" style={{ width: "10%" }}>
                İşlemler
              </th>
              <th scope="col" style={{ width: "35%" }}>
                Ürün Açıklaması
              </th>
              <th scope="col" style={{ width: "10%" }}>
                Fiyat
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Resim URL
              </th>
            </tr>
          </thead>
          {stokTable && stokTable.length > 0 && (
            <tbody>
              {stokTable
              .filter((product) => product.aciklama.toLocaleLowerCase().includes(aramaText.toLocaleLowerCase()))
              .slice(first, first + rows)
              .map((product) => (
                <tr key={product.id}>
                  <td data-label="Ürün ID">{product.id}</td>
                  <td
                    data-label="İşlemler"
                    className="d-flex flex-row gap-1 mobil_right"
                  >
                    <button
                      className="btn btn-warning"
                      onClick={() => handleEdit(product)}
                    >
                      Düzenle
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(product.id)}
                    >
                      Sil
                    </button>
                  </td>
                  <td data-label="Ürün Açıklaması">{product.aciklama}</td>
                  <td data-label="Fiyat">{product.fiyat}</td>
                  <td data-label="Resim URL" className="d-flex flex-row gap-3">
                    <img
                      src={`${SERVERPICT_URL}${product.pictureUrl}`}
                      alt="Ürün Resmi"
                      className="img-thumbnail"
                      style={{ width: "20px" }}
                    />
                    <span>{product.pictureUrl}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <Paginator
        first={first}
        rows={rows}
        totalRecords={stokTable && stokTable.length}
        onPageChange={onPageChange}
      />
    </section>
  );
};

export default AdminProductsScreen;
