import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import moment from "moment";
import { toast } from "react-toastify";
import { FaSave } from "react-icons/fa";
import { Calendar } from "primereact/calendar";
import { useDispatch } from "react-redux";
import { setAllReloadPage } from "../../slice/auth/authSlice";
import Spinner from "../../components/Spinner";
import "react-quill/dist/quill.snow.css";
import {
  useAddDuyuruMutation,
} from "../../slice/redux/sabitSlice";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    ["link", "image", "video"],
    [{ color: [] }],
  ],
};

const DuyuruAddEdit = () => {
  const dispatch = useDispatch();
  const [addDuyuru, { data: addInfo, isLoading: addLoading }] = useAddDuyuruMutation();
  const [value, setValue] = useState("");
  const [dates, setDates] = useState(null);
  const [resimUrl, setResimUrl] = useState("");
  const folder = "duyuru";

  const formatedTarih = (value) => {
    return moment(value).format("YYYY.MM.DD");
  };

  const duyuruKaydet = async () => {
    const formData = new FormData();
    formData.append("htmlMetin", value);
    formData.append("baslangicTarihi", formatedTarih(dates[0]));
    formData.append("bitisTarihi", formatedTarih(dates[1]));
    formData.append("folder", folder);
    formData.append("image", resimUrl);
    try {
      await addDuyuru(formData);
    } catch (error) {
      toast.error("Duyuru kaydedilirken bir hata oluştu.");
      console.error("Duyuru kaydetme hatası:", error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      dispatch(setAllReloadPage(true))
    }
  }, [addInfo, dispatch]);

  const handleResimSec = (e) => {
    const file = e.target.files[0];
    setResimUrl(file);
  };

  return (
    <>
    {addLoading && <Spinner />}
      <section className="d-flex flex-row mt-2 gap-4">
        <div className="editor col-6">
          <div className="text-center">
            <strong className="mt-1">HTML Editor</strong>
          </div>
          <ReactQuill
            className="editor-input"
            theme="snow"
            value={value}
            onChange={setValue}
            modules={modules}
            style={{ background: "#F4EEED" }}
            placeholder="HTML destekli duyurunuzu yazınız ve biçimlendiriniz.."
          />
          <div className="mt-1">
            <input
              type="file"
              name="resimUrl"
              onChange={handleResimSec}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-5">
          <div className="text-center">
            <strong className="mt-1">HTML Önizleme</strong>
          </div>
          <div style={{ border: "1px solid lightgray", borderRadius: "10px" }}>
            <div
              className="preview mt-2"
              style={{ padding: "10px" }}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          </div>
        </div>
      </section>

      <section className="mt-3 d-flex flex-row gap-2">
        <label className="mt-1" htmlFor="isletmeFlag">
          Tarih Aralığı Seçiniz:  
        </label>
        <Calendar
          value={dates}
          onChange={(e) => setDates(e.value)}
          selectionMode="range"
          readOnlyInput
        />
        <button
          className="btn btn-success"
          style={{ width: "20%" }}
          onClick={duyuruKaydet}
          disabled={!value || !dates}
        >
          <FaSave />
           Duyuru Kaydet
        </button>
      </section>
    </>
  );
};

export default DuyuruAddEdit;
