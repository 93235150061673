import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBars } from "react-icons/fa"
import { sideBarClose } from "../../slice/auth/authSlice";

const AdminHeader = () => {
  const dispatch = useDispatch()
  const { user, barClose } = useSelector((state) => state.clnAuth);

  const barClosedOrOpen = () => {
    if(barClose) {
      dispatch(sideBarClose(false))
    } else {
      dispatch(sideBarClose(true))
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <FaBars size="28px" style={{ marginLeft: "10px"}} onClick={() => barClosedOrOpen()} className="cursor-pointer"/>
      <div className="container-fluid">
          <ul className="navbar-nav" style={{ justifyContent: "end"}}>
            {user && user.userCredential && (
              <li className="nav-item">
                <span className="nav-link" >{user.userCredential}</span>
              </li>
            )}
          </ul>
      </div>
    </nav>
  );
};

export default AdminHeader;
