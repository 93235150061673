import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import firmaLogo from "../../assets/aabim_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import {
  logout,
  reset,
  sideBarClose,
} from "../../slice/auth/authSlice";
import { MdAdminPanelSettings, MdDashboard } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";

const UserSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, barClose } = useSelector((state) => state.clnAuth);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(sideBarClose(true));
      } else {
        dispatch(sideBarClose(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const cikisYap = () => {
    dispatch(reset());
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className={`sidebar ${barClose ? "close" : ""}`}>
      <Link to="/app">
        <div className="logo mt-1">
          <img className="firmaLogo" src={firmaLogo} alt="" style={{ height: "40px"}}/>
        </div>
      </Link>
      <ul className="side-menu">
        <li
          className={selectedMenuItem === 0 ? "limenu active" : "limenu"}
        >
          <Link to="mdash" onClick={() => handleMenuItemClick(0)}>
          <div className="d-flex flex-row gap-3">
              <i>
                <MdDashboard size="30px" />
              </i>
              <span>Durum</span>
            </div>
          </Link>
        </li>
        <li
          className={selectedMenuItem === 1 ? "limenu active" : "limenu"}
        >
          <Link to="musteriyikama" onClick={() => handleMenuItemClick(1)}>
          <div className="d-flex flex-row gap-3">
              <i>
                <FaListCheck size="30px" />
              </i>
              <span>İşlemlerim</span>
            </div>
          </Link>
        </li>
        <li
          className={selectedMenuItem === 12 ? "limenu active" : "limenu"}
        >
          <Link to={`mprofil/${user.userID}`} onClick={() => handleMenuItemClick(12)}>
          <div className="d-flex flex-row gap-3">
              <i>
                <MdAdminPanelSettings size="30px" />
              </i>
              <span>Profil</span>
            </div>
          </Link>
        </li>
      </ul>
      <ul className="side-menu">
        <li>
          <button
            className="btn btn-outline-danger w-100"
            onClick={() => cikisYap()}
            style={{ minWidth: "40px"}}
          >
            <FaSignOutAlt /> {barClose ? "" : "Çıkış"}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserSideBar;
