import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { webDataSet } from "./slice/auth/authSlice";
import { useGetWebDataQuery } from "./slice/redux/sabitSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "./components/Spinner";

const App = () => {
  const dispatch = useDispatch();
  const { data: webTable, isLoading } = useGetWebDataQuery();
  const {webData} = useSelector(state => state.clnAuth)

  useEffect(() => {
    if (webTable) {
      dispatch(webDataSet(webTable));
    }
  }, [webTable, dispatch]);

  // Verinin yüklenmesini bekliyoruz
  if (isLoading || !webTable || (webData && !webData.sabit)) {
    return <Spinner />;
  }

  return <Outlet />;
};

export default App;
