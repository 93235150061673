import { SABIT_URL, SIPARIS_URL, UPLOAD_URL, DUYURU_URL, SITEMAP_URL } from "../constants";
import apiSlice from "../apiSlice";

export const sabitApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSabits: builder.query({
      query: () => ({
        url: `${SABIT_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsabit"],
    }),
    updateSabit: builder.mutation({
      query: (data) => ({
        url: `${SABIT_URL}`,
        method: "POST",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsabit"],
    }),
    getWebData: builder.query({
      query: () => ({
        url: `${SABIT_URL}/webdata`,
      }),
      keepUnusedDataFor: 5,
    }),
    uploadImagesBySabitId: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: formData,
      }),
    }),
    getAllSepet: builder.query({
      query: () => ({
        url: `${SIPARIS_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsiparis"],
    }),
    getAllSepetTeslim: builder.query({
      query: () => ({
        url: `${SIPARIS_URL}/teslimedilen`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsiparis"],
    }),
    addSepet: builder.mutation({
      query: (data) => ({
        url: `${SIPARIS_URL}`,
        method: "POST",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsiparis"],
    }),
    updateTeslimEden: builder.mutation({
      query: (data) => ({
        url: `${SIPARIS_URL}/${data.siparisID}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsiparis"],
    }),
    updateTeslimIptal: builder.mutation({
      query: (siparisID) => ({
        url: `${SIPARIS_URL}/teslimiptal/${siparisID}`,
        method: "PUT",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblsiparis"],
    }),
    getAllDuyuru: builder.query({
      query: () => ({
        url: `${DUYURU_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblduyuru"],
    }),
    addDuyuru: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: formData
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblduyuru"],
    }),
    updateDuyuruById: builder.mutation({
      query: (data) => ({
        url: `${DUYURU_URL}/${data.silID}`,
        method: "PUT",
        body: data
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblduyuru"],
    }),
    deleteDuyuruById: builder.mutation({
      query: (silID) => ({
        url: `${DUYURU_URL}/${silID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblduyuru"],
    }),
    getDuyuruVarmi: builder.query({
      query: () => ({
        url: `${DUYURU_URL}/gunluk`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblduyuru"],
    }),
    getAdminDashboard: builder.query({
      query: () => ({
        url: `${SABIT_URL}/adashboard`,
      }),
      keepUnusedDataFor: 5,
    }),
    getAdminDashGraph: builder.query({
      query: () => ({
        url: `${SABIT_URL}/adashgraph`,
      }),
      keepUnusedDataFor: 5,
    }),
    getSiteMap: builder.query({
      query: () => ({
        url: `${SITEMAP_URL}`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetSabitsQuery,
  useUpdateSabitMutation,
  useGetWebDataQuery,
  useUploadImagesBySabitIdMutation,
  useGetAllSepetQuery,
  useGetAllSepetTeslimQuery,
  useAddSepetMutation,
  useUpdateTeslimEdenMutation,
  useUpdateTeslimIptalMutation,
  useGetAllDuyuruQuery,
  useAddDuyuruMutation,
  useUpdateDuyuruByIdMutation,
  useDeleteDuyuruByIdMutation,
  useGetDuyuruVarmiQuery,
  useGetAdminDashboardQuery,
  useGetAdminDashGraphQuery,
  useGetSiteMapQuery
} = sabitApiSlice;
