import { STOK_URL, UPLOAD_URL } from "../constants";
import apiSlice from "../apiSlice";

export const stokApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStoks: builder.query({
      query: () => ({
        url: `${STOK_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblstok"],
    }),
    addStok: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}`,
        method: 'POST',
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblstok"],
    }),
    updateStokofPict: builder.mutation({
      query: (formData) => ({
        url: `${UPLOAD_URL}/${formData.id}`,
        method: "PUT",
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblstok"],
    }),
    updateStok: builder.mutation({
      query: (data) => ({
        url: `${STOK_URL}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblstok"],
    }),
    deleteStokById: builder.mutation({
      query: (stokID) => ({
        url: `${STOK_URL}/${stokID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblstok"],
    }),
    getStokById: builder.query({
      query: (stokID) => ({
        url: `${STOK_URL}/${stokID}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblstok"],
    }),
   
  }),
});

export const {
  useGetStoksQuery,
  useAddStokMutation,
  useUpdateStokofPictMutation,
  useUpdateStokMutation,
  useDeleteStokByIdMutation,
  useGetStokByIdQuery,
} = stokApiSlice;

