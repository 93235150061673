import { HIZMET_URL } from "../constants";
import apiSlice from "../apiSlice";

export const hizmetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHizmets: builder.query({
      query: () => ({
        url: `${HIZMET_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblhizmet"],
    }),
    getHizmetById: builder.query({
        query: (hizmetID) => ({
          url: `${HIZMET_URL}/${hizmetID}`,
        }),
        keepUnusedDataFor: 5,
        providesTags: ["tblhizmet"],
      }),
    addHizmet: builder.mutation({
      query: (formData) => ({
        url: `${HIZMET_URL}`,
        method: 'POST',
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblhizmet"],
    }),
    updateHizmetById: builder.mutation({
      query: (data) => ({
        url: `${HIZMET_URL}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblhizmet"],
    }),
    deleteHizmetById: builder.mutation({
      query: (hizmetID) => ({
        url: `${HIZMET_URL}/${hizmetID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblhizmet"],
    }),
  }),
});

export const {
  useGetHizmetsQuery,
  useAddHizmetMutation,
  useUpdateHizmetByIdMutation,
  useDeleteHizmetByIdMutation,
  useGetHizmetByIdQuery,
} = hizmetApiSlice;

