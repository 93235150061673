import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import {
  useGetAllSepetQuery,
  useUpdateTeslimEdenMutation,
} from "../../slice/redux/sabitSlice";
import { SERVERPICT_URL } from "../../slice/constants";
import Spinner from "../../components/Spinner";

const BekleyenTable = () => {
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [deliveryPerson, setDeliveryPerson] = useState("");
  const { data: sepetTable, isLoading, refetch } = useGetAllSepetQuery();

  const [updateTeslimEden, { data: upInfo, isLoading: upLoading }] =
    useUpdateTeslimEdenMutation();

  const [visible, setVisible] = useState(false);

  const handleDeliverClick = (id) => {
    setSelectedOrderId(id);
    setVisible(true);
  };

  const handleConfirmClick = async () => {
    if (selectedOrderId && deliveryPerson) {
      let data = { siparisID: selectedOrderId, teslimEden: deliveryPerson };
      await updateTeslimEden(data);
      setDeliveryPerson("");
      setSelectedOrderId(null);
      setVisible(false);
    }
  };

  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
    }
  }, [upInfo, refetch]);

  const renderSepet = (sepet) => {
    try {
      const items = JSON.parse(sepet);
      return (
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              style={{ listStyle: "none", borderBottom: "1px solid lightgray" }}
              className="mb-1"
            >
              <img
                src={`${SERVERPICT_URL}${item.pictureUrl}`}
                alt={item.aciklama}
                width="50"
              />
              {item.aciklama} - {item.quantity} adet - {item.fiyat} ₺
            </li>
          ))}
        </ul>
      );
    } catch (error) {
      return <span>Geçersiz sepet verisi</span>;
    }
  };

  return (
    <>
      {(isLoading || upLoading) && <Spinner />}
      <div className="responsive-table">
        <table>
          <caption>Bekleyenler</caption>
          <thead>
            <tr>
              <th scope="col" style={{ width: "7%" }}>
                Tarih
              </th>
              <th scope="col" style={{ width: "13%" }}>
                Adı Soyadı
              </th>
              <th scope="col" style={{ width: "5%" }}>
                Telefon
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Adres
              </th>
              <th scope="col" style={{ width: "5%" }}>
                Tutar
              </th>
              <th scope="col" style={{ width: "35%" }}>
                Sepet
              </th>
              <th scope="col" style={{ width: "10%" }}>
                İşlemler
              </th>
            </tr>
          </thead>
          {sepetTable && sepetTable.length > 0 && (
            <tbody>
              {sepetTable.map((order) => (
                <tr key={order.siparisID}>
                  <td data-label="Tarih">{order.tarih}</td>
                  <td data-label="Adı Soyadı">{order.adiSoyadi}</td>
                  <td data-label="Telefon">{order.telefon}</td>
                  <td data-label="Adres">{order.adres}</td>
                  <td data-label="Tutar">{order.tutar}</td>
                  <td data-label="Sepet">{renderSepet(order.sepet)}</td>
                  <td
                    data-label="İşlemler"
                    className="d-flex flex-row gap-2 mobil_right"
                  >
                    <button className="btn btn-danger btn-sm">Sil</button>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleDeliverClick(order.siparisID)}
                    >
                      Teslim İşlemi
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Dialog
        header="Teslim Eden Personel Bilgisi"
        visible={visible}
        style={{ width: `${window.innerWidth < 756 ? "75vw" : "50vw"}` }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">
          {selectedOrderId && (
            <div className="blog-detail mt-3 gap-2">
              <label htmlFor="teslimEden" className="mt-1 col-lg-3 col-md-3 col-11">
                Personelin Adı Soyadı ?
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Teslim Eden"
                value={deliveryPerson}
                onChange={(e) => setDeliveryPerson(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleConfirmClick}>
                Onayla
              </button>
            </div>
          )}
        </p>
      </Dialog>
    </>
  );
};

export default BekleyenTable;
