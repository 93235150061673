import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import firmaLogo from "../../assets/aabim_logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  FaSignOutAlt,
  FaBlog,
  FaStore,
  FaCogs,
} from "react-icons/fa";
import { logout, reset, sideBarClose } from "../../slice/auth/authSlice";
import {
  MdAdminPanelSettings,
  MdBorderColor,
  MdCleaningServices,
  MdDashboard,
  MdLocalOffer,
} from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { FaListCheck, FaPeopleGroup, FaSliders } from "react-icons/fa6";
import { GrAnnounce } from "react-icons/gr";
import { IoIosPricetags } from "react-icons/io";
import {toast} from "react-toastify"
import { SiCcleaner } from "react-icons/si"

const AdminSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { barClose } = useSelector((state) => state.clnAuth);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const handleMenuItemClick = (index) => {
    if(index === 13 && window.innerWidth < 768) {
      toast.error("Bu Menü Mobil Cihazlarda Kullanılamaz.")
    } else {
      setSelectedMenuItem(index);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(sideBarClose(true));
      } else {
        dispatch(sideBarClose(false));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const cikisYap = () => {
    dispatch(reset());
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className={`sidebar ${barClose ? "close" : ""}`}>
      <Link to="/app">
        <div className="logo mt-1">
          <img
            className="firmaLogo"
            src={firmaLogo}
            alt=""
            style={{ height: "40px" }}
          />
        </div>
      </Link>
      <ul className="side-menu">
        <li className={selectedMenuItem === 0 ? "limenu active" : "limenu"}>
          <Link to="adash" onClick={() => handleMenuItemClick(0)} title="Durum">
            <div className="d-flex flex-row gap-3">
              <i>
                <MdDashboard size="30px" />
              </i>
              <span>Durum</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 1 ? "limenu active" : "limenu"}>
          <Link to="cari" onClick={() => handleMenuItemClick(1)} title="Cari İşlemler">
            <div className="d-flex flex-row gap-3">
              <i>
                <GiMoneyStack size="30px" />
              </i>
              <span>Cari İşlemler</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 12 ? "limenu active" : "limenu"}>
          <Link to="siparis" onClick={() => handleMenuItemClick(12)} title="Siparişler">
            <div className="d-flex flex-row gap-3">
              <i>
                <MdBorderColor size="30px" />
              </i>
              <span>Siparişler</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 15 ? "limenu active" : "limenu"}>
          <Link to="temizlikistekleri" onClick={() => handleMenuItemClick(15)} title="Siparişler">
            <div className="d-flex flex-row gap-3">
              <i>
                <SiCcleaner size="30px" />
              </i>
              <span>Temizlik İsteği</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 14 ? "limenu active" : "limenu"}>
          <Link to="rezerve" onClick={() => handleMenuItemClick(14)} title="Temizlik Rezervasyon">
            <div className="d-flex flex-row gap-3">
              <i>
                <FaListCheck size="30px" />
              </i>
              <span>Rezervasyon</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 16 ? "limenu active" : "limenu"}>
          <Link to="kampanya" onClick={() => handleMenuItemClick(16)} title="Temizlik Rezervasyon">
            <div className="d-flex flex-row gap-3">
              <i>
                <MdLocalOffer size="30px" />
              </i>
              <span>Kampanyalar</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 13 ? "limenu active" : "limenu"} onClick={() => handleMenuItemClick(13)}>
          {window.innerWidth < 768 ? (
            <div className="d-flex flex-row gap-3">
            <i>
              <GrAnnounce size="30px" />
            </i>
            <span>Duyurular (Popup)</span>
          </div>
          ) : (
             <Link to="duyuru" title="Duyurular (Popup)">
            <div className="d-flex flex-row gap-3">
              <i>
                <GrAnnounce size="30px" />
              </i>
              <span>Duyurular (Popup)</span>
            </div>
          </Link>
          )}
        </li>
        <li className={selectedMenuItem === 5 ? "limenu active" : "limenu"}>
          <Link to="blog" onClick={() => handleMenuItemClick(5)} title="Blog Sayfası">
            <div className="d-flex flex-row gap-3">
              <i>
                <FaBlog size="30px" />
              </i>
              <span>Blog Sayfası</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 2 ? "limenu active" : "limenu"}>
          <Link to="musteri" onClick={() => handleMenuItemClick(2)} title="Müşteriler">
            <div className="d-flex flex-row gap-3">
              <i>
                <FaPeopleGroup size="30px" />
              </i>
              <span>Müşteriler</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 3 ? "limenu active" : "limenu"}>
          <Link to="products" onClick={() => handleMenuItemClick(3)} title="Ürünler">
            <div className="d-flex flex-row gap-3">
              <i>
                <FaStore size="30px" />
              </i>
              <span>Ürünler</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 11 ? "limenu active" : "limenu"}>
          <Link to="hizmetler" onClick={() => handleMenuItemClick(11)} title="Hizmetler">
            <div className="d-flex flex-row gap-3">
              <i>
                <MdCleaningServices size="30px" />
              </i>
              <span>Hizmetler</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 10 ? "limenu active" : "limenu"}>
          <Link to="slider" onClick={() => handleMenuItemClick(10)} title="Slayt Sayfası">
            <div className="d-flex flex-row gap-3">
              <i>
                <FaSliders size="30px" />
              </i>
              <span>Slayt Sayfası</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 4 ? "limenu active" : "limenu"}>
          <Link to="yikama" onClick={() => handleMenuItemClick(4)} title="Web Fiyatları">
            <div className="d-flex flex-row gap-3">
              <i>
                <IoIosPricetags size="30px" />
              </i>
              <span>Web Fiyatları</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 7 ? "limenu active" : "limenu"}>
          <Link to="setup" onClick={() => handleMenuItemClick(7)} title="Yönetim Paneli">
            <div className="d-flex flex-row gap-3">
              <i>
                <FaCogs size="30px" />
              </i>
              <span>Yönetim Paneli</span>
            </div>
          </Link>
        </li>
        <li className={selectedMenuItem === 9 ? "limenu active" : "limenu"}>
          <Link to="aprofil" onClick={() => handleMenuItemClick(9)} title="Profil">
            <div className="d-flex flex-row gap-3">
              <i>
                <MdAdminPanelSettings size="30px" />
              </i>
              <span>Profil</span>
            </div>
          </Link>
        </li>
      </ul>
      <ul className="side-menu">
        <li>
          <button
            className="btn btn-outline-danger w-100"
            onClick={() => cikisYap()}
          >
            <FaSignOutAlt /> {barClose ? "" : "Çıkış"}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AdminSideBar;
