import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const MusteriDashboard = () => {
  const [islemData, setIslemData] = useState([]);
  const [tahsilatData, setTahsilatData] = useState([]);
  const screenWidth = window.innerWidth;
  let toplamBorc = 12
  let toplamOdeme = 2

  // Verileri tarihe göre gruplama
  const groupedIslemData = groupDataByDate(islemData);
  const groupedTahsilatData = groupDataByDate(tahsilatData);

  return (
    <section className="dashboard">
      <h2 className="mb-4">Müşteri Genel Durum</h2>

      {/* Toplam Borç ve Tahsilat */}
      <div className="row mb-4 d-flex flex-row gap-2">
        <div
          className="col-md-6"
          style={{ width: `${screenWidth < 756 ? "370px" : ""}` }}
        >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Toplam Borç</h5>
              <p className="card-text">{toplamBorc.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-6"
          style={{ width: `${screenWidth < 756 ? "370px" : ""}` }}
        >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Toplam Ödeme</h5>
              <p className="card-text">{toplamOdeme.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Borç ve Tahsilat Grafiği */}
      <div className="row">
        <div
          className="col-md-6"
          style={{
            width: `${screenWidth < 756 ? "380px" : ""}`,
            marginLeft: `${screenWidth < 756 ? "-5px" : "0px"}`,
          }}
        >
          <h4>Borç Grafiği</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={groupedIslemData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="borc"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div
          className="col-md-6"
          style={{
            width: `${screenWidth < 756 ? "380px" : ""}`,
            marginLeft: `${screenWidth < 756 ? "-5px" : "0px"}`,
          }}
        >
          <h4>Ödeme Grafiği</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={groupedTahsilatData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="tahsilat"
                stroke="#82ca9d"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </section>
  );
};

// Verileri tarihe göre gruplama
const groupDataByDate = (data) => {
  const groupedData = {};
  data.forEach((item) => {
    const date = item.tarih;
    if (!groupedData[date]) {
      groupedData[date] = { date, borc: 0, tahsilat: 0 };
    }
    groupedData[date].borc += parseFloat(item.genelToplam);
  });
  return Object.values(groupedData);
};

export default MusteriDashboard;
