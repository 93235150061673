import { TEMIZLIK_URL } from "../constants";
import apiSlice from "../apiSlice";

export const temizlikApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTemizliks: builder.query({
      query: () => ({
        url: `${TEMIZLIK_URL}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteritemizlik"],
    }),
    getTemizlikById: builder.query({
        query: (temizlikID) => ({
          url: `${TEMIZLIK_URL}/${temizlikID}`,
        }),
        keepUnusedDataFor: 5,
        providesTags: ["tblmusteritemizlik"],
      }),
    addTemizlik: builder.mutation({
      query: (formData) => ({
        url: `${TEMIZLIK_URL}`,
        method: 'POST',
        body: formData,
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteritemizlik"],
    }),
    deleteTemizlikById: builder.mutation({
      query: (temizlikID) => ({
        url: `${TEMIZLIK_URL}/${temizlikID}`,
        method: "DELETE",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["tblmusteritemizlik"],
    }),
  }),
});

export const {
  useGetTemizliksQuery,
  useAddTemizlikMutation,
  useDeleteTemizlikByIdMutation,
  useGetTemizlikByIdQuery,
} = temizlikApiSlice;

