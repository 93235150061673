import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sepetGuncelle } from "../slice/auth/authSlice";
import { useGetStoksQuery } from "../slice/redux/stokSlice";
import Spinner from "./Spinner";
import { SERVERPICT_URL } from "../slice/constants";
import { truncateWord } from "../app/Utility";


const ProductPage = () => {
  const dispatch = useDispatch();
  const { data: productTable, isLoading } = useGetStoksQuery();
  const quantity = 1
  const [more, setMore] = useState(4)

  const handleAddToCart = (product) => {
    dispatch(sepetGuncelle(product));
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="service" id="products">
        <div className="section-header text-center">
          <p>Ürünlerimiz</p>
          <h2>Kapıda Ödemeli Sepetinizi Oluşturun</h2>
        </div>
        <div className="container">
          <div className="row">
            {productTable && productTable.length > 0 && (
              <>
                {productTable.slice(0, more)
                .map((product) => (
                  <div key={product.id} className="col-lg-3 col-md-4">
                    <div className="service-item">
                      <div className="card h-100 w-100">
                       <div className="text-center">
                       <img
                          src={`${SERVERPICT_URL}${product.pictureUrl}`}
                          className="card-img-top"
                          alt={product.aciklama}
                        />
                       </div>
                        <div className="card-body">
                          <div className="d-flex flex-row justify-content-between">
                            <h5 title={product.aciklama} className="card-title">{product.aciklama.length > 25 ? truncateWord(product.aciklama, 22): product.aciklama}</h5>
                            <p className="card-text">{product.fiyat} ₺</p>
                          </div>
                          <button 
                            className="btn btn-outline-warning w-100"
                            onClick={() => handleAddToCart({ quantity, ...product })}
                          >
                            Sepete At
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="load_more">
              <button className="btn btn-custom w-100" onClick={() => setMore((prevV) => prevV + 4)}>Daha Fazla Göster</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
