import React, { useState, useEffect, useCallback } from "react";
import {
  useAddHizmetMutation,
  useGetHizmetsQuery,
  useUpdateHizmetByIdMutation,
  useDeleteHizmetByIdMutation,
} from "../../slice/redux/hizmetSlice";
import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";
import { FaBackspace, FaSave, FaSyncAlt, FaTrashAlt } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { primeReactStyle } from "../PrimeReactUtil";
import { Paginator } from "primereact/paginator";

const HizmetScreen = () => {
  const { data: hizmetTable, isLoading, refetch } = useGetHizmetsQuery();
  const [addHizmet, { data: addInfo, isLoading: addLoading }] = useAddHizmetMutation();
  const [updateHizmetById, { data: upInfo, isLoading: upLoading }] = useUpdateHizmetByIdMutation();
  const [deleteHizmetById, { data: delInfo, isLoading: delLoading }] = useDeleteHizmetByIdMutation();
  const [formData, setFormData] = useState({
    hizmetAdi: "",
    olcuBirimi: "",
    birimFiyat: "",
  });
  const { hizmetAdi, olcuBirimi, birimFiyat } = formData;
  const [editingId, setEditingId] = useState(null);
  const [silVisible, setSilVisible] = useState(false);
  const [silID, setSilID] = useState("");

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const hizmetKaydet = async () => {
    let data = { hizmetAdi, olcuBirimi, birimFiyat };
    try {
      await addHizmet(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const hizmetGuncelle = async () => {
    let data = { hizmetID:editingId,  hizmetAdi, olcuBirimi, birimFiyat };
    try {
      await updateHizmetById(data).unwrap();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (addInfo && addInfo.message) {
      toast(addInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [addInfo, refetch]);

  
  useEffect(() => {
    if (upInfo && upInfo.message) {
      toast(upInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [upInfo, refetch]);

  useEffect(() => {
    if (delInfo && delInfo.message) {
      toast(delInfo.message, { autoClose: 5000 });
      refetch();
      resetForm();
    }
  }, [delInfo, refetch]);

  const handleEdit = (hizmet) => {
    setEditingId(hizmet.hizmetID);
    setFormData({
      hizmetAdi: hizmet.hizmetAdi,
      olcuBirimi: hizmet.olcuBirimi,
      birimFiyat: hizmet.birimFiyat,
    });
  };

  const resetForm = () => {
    setEditingId(null);
    setFormData({ hizmetAdi: "", olcuBirimi: "", birimFiyat: "" });
  };

  const handlePageChange = useCallback((event) => {
    setFirst(event.first);
    setRows(event.rows);
  }, []);

  const handleDelete = async () => {
    try {
        await deleteHizmetById(silID).unwrap();
        setSilVisible(false);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
      resetForm();
  };

  return (
    <section className="hizmetler-info">
      {(isLoading || addLoading || upLoading || delLoading) && <Spinner />}
      <h3 className="text-center">Hizmet Yönetimi</h3>
      <hr className="my-2" />
      <div className="mb-4">
        <div className="blog-detail mb-3">
          <div className="col-lg-5 col-md-5 col-11 d-flex flex-column">
            <label htmlFor="hizmetAdi" className="form-label">
              Hizmet Adı
            </label>
            <input
              type="text"
              className="form-control"
              name="hizmetAdi"
              value={formData.hizmetAdi}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-lg-2 col-md-2 col-11 d-flex flex-column">
            <label htmlFor="olcuBirimi" className="form-label">
              Ölçü Birimi
            </label>
            <input
              type="text"
              className="form-control"
              name="olcuBirimi"
              value={formData.olcuBirimi}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-lg-2 col-md-2 col-11 d-flex flex-column">
            <label htmlFor="birimFiyat" className="form-label">
              Birim Fiyat
            </label>
            <input
              type="number"
              className="form-control"
              name="birimFiyat"
              value={formData.birimFiyat}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-11">
          {editingId && (
            <div className="d-flex flex-row gap-1">
              <button className="btn btn-primary w-100" onClick={hizmetGuncelle}>
                <FaSyncAlt /> Güncelle
              </button>
              <button
                className="btn btn-secondary w-100"
                onClick={() => resetForm()}
              >
                <FaBackspace /> Vazgeç
              </button>
            </div>
          )}
          {!editingId && (
            <button
              className="btn btn-primary w-100"
              disabled={!hizmetAdi && !olcuBirimi && !birimFiyat}
              onClick={hizmetKaydet}
            >
              <FaSave /> Ekle
            </button>
          )}
        </div>
      </div>
          <div className="responsive-table">
             <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col" style={{ width: "5%" }}>ID</th>
            <th scope="col" style={{ width: "15%" }}>İşlemler</th>
            <th scope="col" style={{ width: "60%" }}>Hizmet Adı</th>
            <th scope="col" style={{ width: "10%" }}>Ölçü Birimi</th>
            <th scope="col" style={{ width: "10%" }}>Birim Fiyat</th>
          </tr>
        </thead>
        {hizmetTable && hizmetTable.length > 0 && (
          <tbody>
            {hizmetTable.slice(first, first + rows).map((hizmet) => (
              <tr key={hizmet.hizmetID}>
                <td data-label="Ürün ID">{hizmet.hizmetID}</td>
                <td data-label="İşlemler" className="d-flex flex-row gap-1 mobil_right">
                  <button
                    className="btn btn-warning btn-sm me-2"
                    onClick={() => handleEdit(hizmet)}
                  >
                    Düzenle
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(hizmet.hizmetID)}
                  >
                    Sil
                  </button>
                </td>
                <td data-label="Hizmet Adı">{hizmet.hizmetAdi}</td>
                <td data-label="Ölçü Birimi">{hizmet.olcuBirimi}</td>
                <td data-label="Birim Fiyat">{hizmet.birimFiyat}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
          </div>
     
      <Paginator
        first={first}
        rows={rows}
        totalRecords={hizmetTable && hizmetTable.length}
        onPageChange={handlePageChange}
      />
      <Dialog
        header="Kayıt Silme Onayı"
        visible={silVisible}
        headerStyle={primeReactStyle.deleteHeaderStyle}
        contentStyle={primeReactStyle.deleteContentStyle}
        style={{ width: "50vw" }}
        onHide={() => setSilVisible(false)}
      >
        <p className="m-0 text-center">
          <span>
            <strong>{silID}</strong> Nolu <strong>Blog Kaydı</strong>{" "}
            Silinecektir. Onaylıyor musunuz?
          </span>
          <div className="d-flex flex-row gap-1 mt-3">
            <button className="btn btn-danger w-100" onClick={handleDelete}>
              <FaTrashAlt /> Kaydı Sil
            </button>
            <button
              className="btn btn-secondary w-100"
              onClick={() => setSilVisible(false)}
            >
              <FaBackspace /> Vazgeç
            </button>
          </div>
        </p>
      </Dialog>
    </section>
  );
};

export default HizmetScreen;
